
import { Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';

import { AutoCompleteItem } from '@/core/models';
import { BaseInputComponent } from '.';

export default class BaseSelectableInputComponent extends BaseInputComponent {
  @Prop({ default: () => [] }) lookups: any[];
  @Prop({ default: 'id' }) lookupIdProp: string;
  @Prop() lookupTextProp: string;
  @Prop() getLookupText?: (lookup: any) => string;

  @Watch('lookups')
  onLookupsChanged(val: any, oldVal: any) {
    // this is needed to not conflict with InputAutocompleteCpt.lookups
  }

  /**
   * This allows custom lookup text method.
   */
  getLookupItemText(lookup: any) {
    // This check is to prevent lookups loaded from backend from showing in i18n.missing in main.ts file
    return this.isDevEnv ? this.getLookupItemTextDev(lookup) : this.getLookupItemTextProd(lookup);
  }

  getLookupItemTextDev(lookup: any) {
    if (this.getLookupText) {
      return this.getLookupText(lookup);
    }

    return lookup['text'] || lookup['name'] || lookup[this.lookupTextProp];
  }

  getLookupItemTextProd(lookup: any) {
    return this.$t(this.getLookupItemTextDev(lookup)) as string;
  }

  protected filterLookups(lookups: any[], query: string) {
    if (!query) {
      return lookups;
    }
    return _.filter(lookups, lookup => {
      const lookupText = _.toLower(this.getLookupItemText(lookup));
      return _.includes(lookupText, _.toLower(query));
    });
  }

  protected toAutocompleteFormat(lookups: any[]) {
    return _.map(lookups, lookup => {
      return {
        id: lookup.id,
        value: this.getLookupItemText(lookup),
      } as AutoCompleteItem;
    });
  }
}
