












import { Component, Vue } from 'vue-property-decorator';
import { HeaderCpt, SidebarCpt } from './components';

@Component({
  components: {
    HeaderCpt,
    SidebarCpt,
  },
})
export default class Default extends Vue {}
