export const reportsInjuriesRoutes = [
  // Elite
  {
    path: '/injuries',
    name: 'injuries',
    meta: { layout: 'default', title: 'route.injuries' },
    component: () => import(/* webpackChunkName: "injuries" */ './EliteInjuriesGridView.vue'),
  },
  {
    path: '/injuries/new/:playerId',
    name: 'injuryNew',
    meta: { layout: 'default', title: 'route.injuryNew' },
    component: () => import(/* webpackChunkName: "injuries" */ './EliteInjuryReporterFormView.vue'),
  },
  {
    path: '/injuries/new/:autoSaveId/draft',
    name: 'injuryNewDraft',
    meta: { layout: 'default', title: 'route.injuryNewDraft' },
    component: () => import(/* webpackChunkName: "injuries" */ './EliteInjuryReporterFormView.vue'),
  },
  {
    path: '/injuries/:injuryId',
    name: 'injuryDetails',
    meta: { layout: 'default', title: 'route.injuryDetails' },
    component: () => import(/* webpackChunkName: "injuries" */ './EliteInjuryDetailsView.vue'),
  },
  {
    path: '/injuries/:injuryId/edit',
    name: 'injuryEdit',
    meta: { layout: 'default', title: 'route.injuryEdit' },
    component: () => import(/* webpackChunkName: "injuries" */ './EliteInjuryReporterFormView.vue'),
  },
  {
    path: '/injuries/:injuryId/review',
    name: 'injuryReview',
    meta: { layout: 'default', title: 'route.injuryReview' },
    component: () => import(/* webpackChunkName: "injuries" */ './EliteInjuryAnalystFormView.vue'),
  },
  // Community
  {
    path: '/c/injuries',
    name: 'communityInjuries',
    meta: { layout: 'default', title: 'route.communityInjuries' },
    component: () => import(/* webpackChunkName: "injuries" */ './CommunityInjuriesGridView.vue'),
  },
  {
    path: '/c/injuries/new',
    name: 'communityInjuryNew',
    meta: { layout: 'default', title: 'route.communityInjuryNew' },
    component: () => import(/* webpackChunkName: "injuries" */ './CommunityInjuryFormView.vue'),
  },
  {
    path: '/c/injuries/new/:autoSaveId/draft',
    name: 'communityInjuryNewDraft',
    meta: { layout: 'default', title: 'route.communityInjuryNewDraft' },
    component: () => import(/* webpackChunkName: "injuries" */ './CommunityInjuryFormView.vue'),
  },
  {
    path: '/c/injuries/:injuryId',
    name: 'communityInjuryDetails',
    meta: { layout: 'default', title: 'route.communityInjuryDetails' },
    component: () => import(/* webpackChunkName: "injuries" */ './CommunityInjuryDetailsView.vue'),
  },
  {
    path: '/c/injuries/:injuryId/edit',
    name: 'communityInjuryEdit',
    meta: { layout: 'default', title: 'route.communityInjuryEdit' },
    component: () => import(/* webpackChunkName: "injuries" */ './CommunityInjuryFormView.vue'),
  },
  // Catastrophic
  {
    path: '/catastrophic-injuries',
    name: 'catastrophicInjuries',
    meta: { layout: 'default', title: 'route.catastrophicInjuries' },
    component: () => import(/* webpackChunkName: "injuries" */ './CatastrophicInjuriesGridView.vue'),
  },
  {
    path: '/catastrophic-injuries/new',
    name: 'catastrophicInjuryNew',
    meta: { layout: 'default', title: 'route.catastrophicInjuryNew' },
    component: () => import(/* webpackChunkName: "injuries" */ './CatastrophicInjuryFormView.vue'),
  },
  {
    path: '/catastrophic-injuries/new/:autoSaveId/draft',
    name: 'catastrophicNewDraft',
    meta: { layout: 'default', title: 'route.catastrophicNewDraft' },
    component: () => import(/* webpackChunkName: "injuries" */ './CatastrophicInjuryFormView.vue'),
  },
  {
    path: '/catastrophic-injuries/:injuryId/edit',
    name: 'catastrophicInjuryEdit',
    meta: { layout: 'default', title: 'route.catastrophicInjuryEdit' },
    component: () => import(/* webpackChunkName: "injuries" */ './CatastrophicInjuryFormView.vue'),
  },
  {
    path: '/catastrophic-injuries/:injuryId/follow-up',
    name: 'catastrophicFollowUp',
    meta: { layout: 'default', title: 'route.catastrophicFollowUp' },
    component: () => import(/* webpackChunkName: "injuries" */ './CatastrophicFollowUpFormView.vue'),
  },
];
