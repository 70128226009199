import { Vue } from 'vue-property-decorator';
import axios, { AxiosRequestConfig } from 'axios';

import router from '@/router';
import { store } from '@/core/store';

export const AxiosFactory = new Vue({
  methods: {
    create() {
      /* eslint-disable */
      const config: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_WEBAPI_URL,
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      };
      /* eslint-enable */

      const axiosInstance = axios.create(config);

      axiosInstance.interceptors.request.use(async requestConfig => {
        this.$Progress.start();

        if (!requestConfig.headers) {
          requestConfig.headers = {};
        }

        if (this.$msal.isAuthenticated()) {
          const token = await this.$msal.getAccessToken();
          requestConfig.headers.Authorization = `Bearer ${token}`;

          const roleId = store.state?.userContext?.contextRoleId;

          // The !requestConfig.headers['X-Context-Role-Id'] check is because
          // in src/core/store/index.ts sometimes we explicitly pass the header
          // and want to use that instead of fetching it from (perhaps stale)
          // state store.state?.userContext?.contextRoleId
          if (roleId && !requestConfig.headers['X-Context-Role-Id']) {
            requestConfig.headers['X-Context-Role-Id'] = roleId;
          }
        }

        return requestConfig;
      });

      axiosInstance.interceptors.response.use(
        response => {
          this.$Progress.finish();
          return response;
        },
        error => {
          this.$Progress.finish();
          return this.onError(error);
        },
      );

      return axiosInstance;
    },
    onError(error: any) {
      // 422 is model validation error and we don't want any extra handling in that case
      if (error && error.response && error.response.status && error.response.status !== 422) {
        let message = 'An error occurred.';

        switch (error.response.status) {
          case 401: {
            if (!this.$msal.isAuthenticated()) {
              message = 'Invalid session, please log in.';
              router.push('/login');
            } else {
              message = 'Unauthorized action.';
            }
            break;
          }
          case 403: {
            message = 'Insufficient permissions.';
            break;
          }
          case 500: {
            if (error.response.data && error.response.data.message) {
              message = error.response.data.message;
            }
            break;
          }
          default: {
            break;
          }
        }

        this.$notify({ title: 'Error', message, type: 'error' });
      }

      return Promise.reject(error);
    },
  },
});
