export const adminStudiesRoutes = [
  {
    path: '/admin/studies',
    name: 'studies',
    meta: { layout: 'default', title: 'route.studies' },
    component: () => import(/* webpackChunkName: "admin" */ './StudiesGridView.vue'),
  },
  {
    path: '/admin/studies/new',
    name: 'newStudy',
    meta: { layout: 'default', title: 'route.newStudy' },
    component: () => import(/* webpackChunkName: "admin" */ './StudyFormView.vue'),
  },
  {
    path: '/admin/studies/:studyId/edit',
    name: 'editStudy',
    meta: { layout: 'default', title: 'route.editStudy' },
    component: () => import(/* webpackChunkName: "admin" */ './StudyFormView.vue'),
  },
  {
    path: '/admin/studies/:studyId/guidance/:locale',
    name: 'studyGuidance',
    meta: { layout: 'default', title: 'route.studyGuidance' },
    component: () => import(/* webpackChunkName: "admin" */ './StudyManageGuidanceView.vue'),
  },
];
