import _ from 'lodash';
import { encode, decode } from 'js-base64';

import {
  AutoSaveModel,
  CatastrophicFollowUpModel,
  CatastrophicInjuryModel,
  CommunityInjuryModel,
  ExposureModel,
  IllnessModel,
  InjuryModel,
} from '@/core/webapi';
import { AutoSaveTypes } from '@/core/constants';
import { ApiService } from '@/core/services';
import { store } from '@/core/store';

type ReportModel =
  | InjuryModel
  | IllnessModel
  | ExposureModel
  | CommunityInjuryModel
  | CatastrophicInjuryModel
  | CatastrophicFollowUpModel;

export const AutoSaveService = {
  // GET
  getInjuryAutoSave(id: number) {
    return this.getAutoSave<InjuryModel>(id);
  },
  getCommunityInjuryAutoSave(id: number) {
    return this.getAutoSave<CommunityInjuryModel>(id);
  },
  getCatastrophicInjuryAutoSave(id: number) {
    return this.getAutoSave<CatastrophicInjuryModel>(id);
  },
  getCatastrophicFollowUpAutoSave(id: number) {
    return this.getAutoSave<CatastrophicFollowUpModel>(id);
  },
  getIllnessAutoSave(id: number) {
    return this.getAutoSave<IllnessModel>(id);
  },
  getExposureAutoSave(id: number) {
    return this.getAutoSave<ExposureModel>(id);
  },
  async getAutoSave<T extends ReportModel>(id: number) {
    const data = (await ApiService.autoSave().getAutoSave(id)).data;
    return this.parseAutoSave<T>(data);
  },

  // FIND
  findInjuryAutoSave(injuryId: number) {
    return this.findAutoSave<InjuryModel>(injuryId, AutoSaveTypes.InjuryReport);
  },
  findCommunityInjuryAutoSave(injuryId: number) {
    return this.findAutoSave<CommunityInjuryModel>(injuryId, AutoSaveTypes.CommunityInjuryReport);
  },
  findCatastrophicInjuryAutoSave(injuryId: number) {
    return this.findAutoSave<CatastrophicInjuryModel>(injuryId, AutoSaveTypes.CatastrophicInjuryReport);
  },
  findCatastrophicFollowUpAutoSave(injuryId: number) {
    return this.findAutoSave<CatastrophicFollowUpModel>(injuryId, AutoSaveTypes.CatastrophicFollowUp);
  },
  findIllnessAutoSave(illnessId: number) {
    return this.findAutoSave<IllnessModel>(illnessId, AutoSaveTypes.IllnessReport);
  },
  findExposureAutoSave(exposureId: number) {
    return this.findAutoSave<ExposureModel>(exposureId, AutoSaveTypes.ExposureReport);
  },
  async findAutoSave<T extends ReportModel>(forId: number, autoSaveTypeId: number) {
    const data = (await ApiService.autoSave().findAutoSave(forId, autoSaveTypeId)).data;
    return this.parseAutoSave<T>(data);
  },

  // OTHER
  parseAutoSave<T extends ReportModel>(data: AutoSaveModel) {
    if (!data) {
      return null;
    }

    // Base64 encode/decode had to be used because Axios was somehow interfering with the serialized
    // JSON and was injecting an extra [] characters inside already serialized JSON. That was making
    // the JSON un-parsable after fetching it from the WebAPI. Also instead of using window.btoa or
    // window.atob, I used this library because it's supposed to be able to handle payload other
    // than ASCII. Since the app will be used all over the world, non ASCII characters can be expected.
    const model = JSON.parse(decode(data.payload)) as T;

    // Manually appending the autoSaveId here because in some edge-cases, it doesn't get
    // saved to "payload" in the DB and then that causes further autoSave failures.
    // This issue can be reproduced like this:
    // - A user goes to edit a page, and changes at least one field on the form
    // - The change gets "auto-saved"
    // - AutoSave.Id didn't exist when the first payload was being saved
    // - User navigates away from the edit page before another autoSave request can be
    //   made which would also contain the autoSaveId inside the payload itself
    // - User comes back to edit page and changes something else again
    // - The payload fetched from the DB still doesn't have autoSaveId, but because
    //   autoSaveId wasn't in the URL, it's attempting a PUT req. instead of a POST req.
    // - PUT requires autoSaveId so the update od auto-save fails
    //
    // So, just make sure that autoSaveId is always set after parsing the payload, and voila, the problem is gone.
    model.autoSaveId = data.id;

    return model;
  },
  autoSaveInjury(model: InjuryModel, comparisonModel: InjuryModel) {
    return this.autoSaveItem(AutoSaveTypes.InjuryReport, model, comparisonModel);
  },
  autoSaveCommunityInjury(model: CommunityInjuryModel, comparisonModel: CommunityInjuryModel) {
    return this.autoSaveItem(AutoSaveTypes.CommunityInjuryReport, model, comparisonModel);
  },
  autoSaveCatastrophicInjury(model: CatastrophicInjuryModel, comparisonModel: CatastrophicInjuryModel) {
    return this.autoSaveItem(AutoSaveTypes.CatastrophicInjuryReport, model, comparisonModel);
  },
  autoSaveCatastrophicFollowUp(model: CatastrophicFollowUpModel, comparisonModel: CatastrophicFollowUpModel) {
    return this.autoSaveItem(AutoSaveTypes.CatastrophicFollowUp, model, comparisonModel);
  },
  autoSaveIllness(model: IllnessModel, comparisonModel: IllnessModel) {
    return this.autoSaveItem(AutoSaveTypes.IllnessReport, model, comparisonModel);
  },
  autoSaveExposure(model: ExposureModel, comparisonModel: ExposureModel) {
    return this.autoSaveItem(AutoSaveTypes.ExposureReport, model, comparisonModel);
  },
  async autoSaveItem(autoSaveTypeId: number, model: ReportModel, comparisonModel: ReportModel) {
    if (!model || !comparisonModel || _.isEqual(JSON.stringify(model), JSON.stringify(comparisonModel))) {
      return null;
    }

    try {
      return !model.autoSaveId
        ? (await this.createAutoSave(autoSaveTypeId, model, model.id)).data
        : (await this.updateAutoSave(model.autoSaveId, model)).data;
    } catch (e) {
      // We don't want to be bothering the user with failed autoSave
      // notifications at the moment, so just swallow the error

      // eslint-disable-next-line no-console
      console.log(e);
    }

    return null;
  },
  createAutoSave(autoSaveTypeId: number, modelToSave: ReportModel, forId?: number) {
    // Base64 encode/decode had to be used because Axios was somehow interfering with the serialized
    // JSON and was injecting an extra [] characters inside already serialized JSON. That was making
    // the JSON un-parsable after fetching it from the WebAPI. Also instead of using window.btoa or
    // window.atob, I used this library because it's supposed to be able to handle payload other
    // than ASCII. Since the app will be used all over the world, non ASCII characters can be expected.
    const payload = encode(JSON.stringify(modelToSave));
    const organisationId = store.state?.userContext?.currentOrganisationId ?? 0;
    const model = new AutoSaveModel({
      forId,
      autoSaveTypeId,
      organisationId,
      payload,
    });

    return ApiService.autoSave().createAutoSave(model);
  },
  updateAutoSave(autoSaveId: number, modelToSave: ReportModel) {
    // Base64 encode/decode had to be used because Axios was somehow interfering with the serialized
    // JSON and was injecting an extra [] characters inside already serialized JSON. That was making
    // the JSON un-parsable after fetching it from the WebAPI. Also instead of using window.btoa or
    // window.atob, I used this library because it's supposed to be able to handle payload other
    // than ASCII. Since the app will be used all over the world, non ASCII characters can be expected.
    const payload = encode(JSON.stringify(modelToSave));
    const model = new AutoSaveModel({
      id: autoSaveId,
      payload,
    });

    return ApiService.autoSave().updateAutoSave(model);
  },
  async getAllDrafts(organisationId: number) {
    return (await ApiService.autoSave().getAllDrafts(organisationId)).data;
  },
};
