


















import { Component, Prop } from 'vue-property-decorator';

import { ValidationMessageCpt, BaseInputComponent } from '.';

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputDateCpt extends BaseInputComponent {
  @Prop({ default: 'yyyy-MM-dd' }) format: string;
  @Prop({ default: true }) clearable: boolean;
  @Prop({ default: true }) editable: boolean;
  @Prop({
    default: () => {
      return {
        firstDayOfWeek: 1,
      };
    },
  })
  pickerOptions?: any;
}
