















































































import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { store } from '@/core/store';
import { RedirectService } from '@/core/services';

@Component
export default class UserDropdownCpt extends AppVue {
  get roles() {
    return this.userContext?.availableUserRoles ?? [];
  }

  get organisations() {
    return this.userContext?.reporterOrganisations ?? [];
  }

  get displayName() {
    return this.userContext?.fullName?.trim() ?? this.userContext?.email;
  }

  get userRole() {
    return this.userContext?.roleName;
  }

  goToPrivacyPolicy() {
    window.open('https://www.world.rugby/privacy-policy');
  }

  goToRequestAccess() {
    this.$router.push('/request-access');
  }

  goToProfile() {
    this.$router.push('/profile');
  }

  goToSmtp4Dev() {
    window.open('http://localhost:32525/');
  }

  goToLocaliseBiz() {
    window.open('https://localise.biz/');
  }

  async logout() {
    await this.$msal.logout();

    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('msg.youAreNowLoggedOut') as string,
      type: 'success',
    });

    this.$router.push('/login');
  }

  async changeRole(roleId: number) {
    if (roleId === this.userContext.contextRoleId) {
      return;
    }

    await store.dispatch('setUserContext', { roleId });
    this.$forceUpdate();
  }

  async changeOrganisation(organisationId: number) {
    if (organisationId === this.userContext.currentOrganisationId) {
      return;
    }

    await store.dispatch('setUserContext', { organisationId });
    this.$forceUpdate();
  }
}
