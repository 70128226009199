export const authRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'empty', anonymousOnly: true, title: 'route.login' },
    component: () => import(/* webpackChunkName: "auth" */ './LoginView.vue'),
  },
  {
    path: '/register',
    name: 'register',
    meta: { layout: 'empty', anonymousOnly: true, title: 'route.register' },
    component: () => import(/* webpackChunkName: "auth" */ './RegisterView.vue'),
  },
  {
    path: '/authenticate',
    name: 'authenticate',
    meta: { layout: 'empty', anonymousOnly: true, title: 'route.authenticate' },
    component: () => import(/* webpackChunkName: "auth" */ './AuthenticateView.vue'),
  },
  {
    path: '/no-access',
    name: 'noAccess',
    meta: { layout: 'default', title: 'route.noAccess' },
    component: () => import(/* webpackChunkName: "auth" */ './NoAccessView.vue'),
  },
];
