


















































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { StudyGuidanceFileModel } from '@/core/webapi';

@Component
export default class SidebarCpt extends AppVue {
  get routeName() {
    return this.$route.name;
  }

  get routePath() {
    return this.$route.path;
  }

  get version() {
    return process.env.VUE_APP_VERSION;
  }

  get reporterGuidances() {
    return (
      this.userContext.reporterGuidances?.filter((p: StudyGuidanceFileModel) => p.locale == this.$i18n.locale) ?? []
    );
  }

  async openGuidanceInNewTab(url: string) {
    const tab = window.open(url, '_blank');
    if (tab) {
      tab.focus();
    }
  }
}
