





import { Vue, Component, Prop } from 'vue-property-decorator';

import { Validatable } from '@/core/models';

@Component
export default class ValidationMessageCpt extends Vue {
  @Prop() model: Validatable;
  @Prop() prop: string;

  get validationMessage() {
    if (!this.prop || !this.model || !this.model.modelState) {
      return '';
    }

    const messages = this.model.modelState[this.prop];

    if (!messages || messages.length === 0) {
      return '';
    }

    return (this.$t(messages[0]) as string) || '';
  }
}
