







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DetailsYesNoUnknownCpt extends Vue {
  @Prop() label: string;
  @Prop() value: boolean;

  get nullableValue() {
    if (this.value === null) {
      return this.$t('lbl.unknown');
    }

    return this.$t(this.value ? 'lbl.yes' : 'lbl.no');
  }
}
