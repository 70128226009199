import { VueUserContextModel } from '@/core/webapi';
import { StorageService } from './storage.service';

export const RedirectService = {
  getDefaultAuthenticatedRoute(userContext: VueUserContextModel, isAuthenticated: boolean) {
    if (!isAuthenticated || !userContext?.userId) {
      throw new Error('User is not initialized');
    }

    const redirectUrl = StorageService.getAndRemoveRedirectToUrl();
    if (redirectUrl) {
      return redirectUrl;
    }

    if (userContext.isStudyAdmin || userContext.isMedicalAdmin || userContext.isAdmin) {
      return '/admin';
    } else if (userContext.isReporter) {
      if (!userContext.currentOrganisationId) {
        return '/no-access';
      }

      if (!userContext?.anyReporterStudies) {
        return '/profile';
      }

      if (userContext.isEliteReporter) {
        if (userContext.canAccessInjuryAndIllnessData) {
          return '/players';
        } else if (userContext.canAccessExposureData) {
          return '/exposures';
        } else if (userContext.canAccessCatastrophicData) {
          return '/catastrophic-injuries';
        } else {
          return '/no-access';
        }
      } else if (userContext.isCommunityReporter) {
        return '/c/match-exposures';
      } else {
        return '/no-access';
      }
    } else if (userContext.isAnalyst) {
      return '/analyst-dashboard';
    }

    // If unsure, return admin
    return '/admin';
  },
};
