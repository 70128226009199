/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from 'vue';
import Vuex from 'vuex';

import { ApiService } from '@/core/services';
import { VueUserContextModel } from '@/core/webapi';

Vue.use(Vuex);

// Storage keys
export const USER_CONTEXT_KEY = 'iss-user-profile';

const MsalService = new Vue({
  methods: {
    logout(redirectToRouteName?: string, notifyConfig?: any) {
      if (notifyConfig) {
        this.$notify(notifyConfig);
      }

      return this.$msal.logout(redirectToRouteName);
    },
  },
});

export function createVueUserContext() {
  return new VueUserContextModel({
    timestamp: new Date() as any,
    anyReporterStudies: false,
    isAdmin: false,
    isStudyAdmin: false,
    isStudyOrSiteAdmin: false,
    isReporter: false,
    isAnalyst: false,
    isMedicalAdmin: false,
    isMedicalOrSiteAdmin: false,
    isMedicalOrStudyOrSiteAdmin: false,
    canAccessInjuryAndIllnessData: false,
    canAccessExposureData: false,
    canAccessCatastrophicData: false,
    isEliteReporter: false,
    isCommunityReporter: false,
    reporterOrganisations: [],
    reporterGuidances: [],
  });
}

function getUserContextFromStorage() {
  const userContext = sessionStorage.getItem(USER_CONTEXT_KEY);

  return userContext ? ((JSON.parse(userContext) as any) as VueUserContextModel) : null;
}

export interface IAppStoreState {
  userContext: VueUserContextModel;
  isFetchingUserContext: boolean;
}

export interface ISetUSerContextParams {
  roleId?: number | null;
  organisationId?: number;
}

export const store = new Vuex.Store<IAppStoreState>({
  state: {
    userContext: getUserContextFromStorage() ?? createVueUserContext(),
    isFetchingUserContext: false,
  },
  getters: {
    userContext(state) {
      return state.userContext;
    },
  },
  mutations: {
    setUserContext(state, nextUserContext) {
      state.userContext = nextUserContext ?? createVueUserContext();
      sessionStorage.setItem(USER_CONTEXT_KEY, JSON.stringify(state.userContext));
    },
  },
  actions: {
    setUserContext({ state, commit }, params: ISetUSerContextParams = {}) {
      if (state.isFetchingUserContext) {
        return;
      }

      const { roleId, organisationId } = params;
      let options: any = roleId
        ? {
            headers: {
              'X-Context-Role-Id': roleId,
            },
          }
        : undefined;

      state.isFetchingUserContext = true;

      return ApiService.account()
        .getVueUserContext(organisationId, options)
        .then(({ data }) => {
          commit('setUserContext', data);
        })
        .catch(e => {
          if (e && e.response && e.response.status === 401) {
            // This user does not have the permissions to access the backend
            commit('setUserContext', null);
            return MsalService.logout('/login', {
              title: 'Login Failed',
              message: 'Please contact your administrator',
              type: 'error',
            });
          }
        })
        .finally(() => {
          state.isFetchingUserContext = false;
        });
    },
    clearUserData({ commit, state }) {
      commit('setUserContext', null);
    },
  },
});
