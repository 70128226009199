




import { Component, Vue, Prop } from 'vue-property-decorator';

import { LookupsService } from '@/core/services';
import { SimpleLookupModel } from '@/core/models';

@Component
export default class LookupValueCpt extends Vue {
  @Prop() lookups: SimpleLookupModel[];
  @Prop() lookupId: number;

  get lookupText() {
    return LookupsService.getLookupText(this.lookups, this.lookupId);
  }
}
