





















import { Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import { ValidationMessageCpt } from '.';
import BaseInputComponent from './BaseInputComponent.vue';

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputYesNoNotKnownCpt extends BaseInputComponent {
  @Prop() onClear: () => void;
  @Prop({ default: false }) clearable: boolean;

  get lookupsInternal() {
    return [
      { value: 1, text: this.$t('lbl.yes') },
      { value: 2, text: this.$t('lbl.no') },
      { value: 4, text: this.$t('lbl.notKnown') },
    ];
  }

  clearSelection() {
    // NOTE: If using the onClear, the same kind of deletion and force update needs
    // to be done in the parent component as well to clean up the original "model"
    delete (this.model as any)[this.prop];
    this.$forceUpdate();

    if (this.onClear) {
      this.onClear();
    }
  }
}
