






















import { Component } from 'vue-property-decorator';

import { ValidationMessageCpt, BaseSelectableInputComponent } from '.';

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputRadioCpt extends BaseSelectableInputComponent {
  get itemWidth() {
    if (!this.lookups) {
      return 0;
    }

    return 100 / this.lookups.length;
  }
}
