










import { Component, Vue, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import { PlayerCollectionModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import { AutoCompleteItem } from '@/core/models';
import BaseFinderCpt from './BaseFinderCpt.vue';

@Component({
  components: {
    BaseFinderCpt,
  },
})
export default class PlayerFinderCpt extends Vue {
  @Prop() onSelected?: (foundItem: AutoCompleteItem) => void;
  @Prop({ default: false }) autofocus: boolean;

  get noResultsText() {
    return this.$t('lbl.noMatchingPlayersFound');
  }

  async findMethod(search: string) {
    return (await ApiService.players().filterPlayers({ search } as any)).data?.items;
  }

  formatResultList(items: PlayerCollectionModel[]) {
    return _.map(items, item => {
      return {
        id: item.id,
        value: `${item.firstName} ${item.lastName}`,
      };
    });
  }
}
