



















import { Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { AutoCompleteItem } from '@/core/models';

@Component
export default class BaseFinderCpt extends AppVue {
  @Prop() onSelected?: (foundItem: AutoCompleteItem) => void;
  @Prop() findMethod: (search: string) => Promise<any[]>;
  @Prop() formatResultList: (items: any[]) => AutoCompleteItem[];
  @Prop({ default: 'lbl.noItemsFound' }) noResultsText: string;
  @Prop({ default: false }) autofocus: boolean;

  state = '';
  foundItems: any[] | null = null;

  get placeholder() {
    return this.$t('lbl.search');
  }
  async created() {
    if (this.autofocus) {
      // It looks like ElementFE "autofocus" property on el-autocomplete doesn't
      // work well with el-dialog, so this workaround is required...
      // SEE: https://github.com/ElemeFE/element/issues/3871#issuecomment-507126410
      this.$nextTick(() => (this.$refs.finder as any).focus());
    }
  }

  async find(search: string, callback: (items: AutoCompleteItem[]) => any) {
    if (!search) {
      callback([]);
      return;
    }

    this.foundItems = null;
    const items = await this.findMethod(search);
    this.foundItems = items;

    callback(this.formatResultList(items));
  }

  onSelectedInternal(item: AutoCompleteItem) {
    if (this.onSelected) {
      this.onSelected(item);
    }

    this.state = '';
  }
}
