import { Locales, LOCALES } from './locales';

import en from './en-IE/world-rugby-iss-en-IE.json';
import fr from './fr-FR/world-rugby-iss-fr-FR.json';
import es from './es-ES/world-rugby-iss-es-ES.json';
import nl from './nl-NL/world-rugby-iss-nl-NL.json';
import jp from './ja-JP/world-rugby-iss-ja-JP.json';
import pt from './pt-PT/world-rugby-iss-pt-PT.json';
import ru from './ru-RU/world-rugby-iss-ru-RU.json';
import ro from './ro-RO/world-rugby-iss-ro-RO.json';
import kage from './ka-GE/world-rugby-iss-ka-GE.json';

export const messages = {
  [Locales.ENIE]: en,
  [Locales.FRFR]: fr,
  [Locales.ESES]: es,
  [Locales.NLNL]: nl,
  [Locales.JAJP]: jp,
  [Locales.PTPT]: pt,
  [Locales.RURU]: ru,
  [Locales.RORO]: ro,
  [Locales.KAGE]: kage,
};

export const defaultLocale = Locales.ENIE;

export * from './components';
export { Locales, LOCALES };
