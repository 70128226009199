export enum Locales {
  ENIE = 'en-IE',
  FRFR = 'fr-FR',
  NLNL = 'nl-NL',
  JAJP = 'ja-JP',
  PTPT = 'pt-PT',
  RURU = 'ru-RU',
  ESES = 'es-ES',
  RORO = 'ro-RO',
  KAGE = 'ka-GE',
}

export const LOCALES = [
  { value: Locales.ENIE, caption: 'English' },
  { value: Locales.FRFR, caption: 'Français' },
  { value: Locales.NLNL, caption: 'Nederlandse' },
  { value: Locales.JAJP, caption: '日本語' },
  { value: Locales.ESES, caption: 'Español' },
  { value: Locales.PTPT, caption: 'Português' },
  { value: Locales.KAGE, caption: 'ქართული ენა' },
  { value: Locales.RORO, caption: 'Română' },
  { value: Locales.RURU, caption: 'Pусский' },
];
