


















import Vue, { PropType } from 'vue';

import { SimpleLookupModel } from '@/core/models';
import { GridModel } from './grid.models';

export default Vue.extend({
  name: 'GridSelectFilterCpt',
  props: {
    grid: Object as PropType<GridModel<any>>,
    lookups: Array as PropType<SimpleLookupModel[]>,
    prop: String,
    placeholder: String,
    filterable: {
      type: Boolean,
      required: false,
      default: true,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onChange() {
      this.grid.filter.page = 1;
      this.$emit('change');
      this.grid.update();
    },
  },
});
