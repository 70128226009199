



















import { LocaleChangerDropCpt } from '@/i18n';
import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import UserDropdownCpt from './UserDropdownCpt.vue';

@Component({
  components: {
    UserDropdownCpt,
    LocaleChangerDropCpt: () => Promise.resolve(LocaleChangerDropCpt),
  },
})
export default class HeaderCpt extends AppVue {
  get routeName() {
    return _.startCase(this.$route.name ?? '');
  }

  get showReporterOrg() {
    return this.isReporter && this.userContext.currentReporterOrganisationName && !this.$route.meta?.hideReporterOrg;
  }
}
