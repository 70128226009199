export const reportsExposuresRoutes = [
  {
    path: '/exposures',
    name: 'exposures',
    meta: { layout: 'default', title: 'route.exposures' },
    component: () => import(/* webpackChunkName: "exposures" */ './EliteExposuresGridView.vue'),
  },
  {
    path: '/exposures/calendar',
    name: 'exposuresCalendar',
    meta: { layout: 'default', title: 'route.exposuresCalendar' },
    component: () => import(/* webpackChunkName: "exposures" */ './ExposureCalendarView.vue'),
  },
  {
    path: '/c/match-exposures',
    name: 'matchExposures',
    meta: { layout: 'default', title: 'route.communityMatchExposures' },
    component: () => import(/* webpackChunkName: "exposures" */ './CommunityExposureMatchesGridView.vue'),
  },
  {
    path: '/c/training-exposures',
    name: 'trainingExposures',
    meta: { layout: 'default', title: 'route.communityTrainingExposures' },
    component: () => import(/* webpackChunkName: "exposures" */ './CommunityExposureTrainingGridView.vue'),
  },
];
