import Vue from 'vue';
import VueI18n from 'vue-i18n';

import IdleVue from 'idle-vue';
import VueProgressBar from 'vue-progressbar';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/variables.scss';
import locale from 'element-ui/lib/locale/lang/en';
import { LOCALES } from '@/i18n';

import App from './App.vue';
import VueAxios from 'vue-axios';
import MsalPlugin from '@/core/plugins/vue.msal.plugin';
import VueMoment from 'vue-moment';
import axios from 'axios';
import '@/core/filters/time';

import { store } from '@/core/store';
import router from './router';
import './registerServiceWorker';

import { Default, Empty } from '@/layouts';
import { messages, defaultLocale } from '@/i18n';

Vue.component('default-layout', Default);
Vue.component('empty-layout', Empty);

Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue();
Vue.use(VueI18n);

const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  silentTranslationWarn: process.env.VUE_APP_ENVIRONMENT !== 'Dev',
  missing: (locale, key) => {
    if (process.env.VUE_APP_ENVIRONMENT === 'Dev' && locale === 'en-IE' && !LOCALES.map(p => p.caption).includes(key)) {
      console.log(`Missing translation for "${key}" in ${locale}`);
    }
  },
});
Vue.use(VueAxios, axios);
Vue.use(MsalPlugin, {});
Vue.use(VueProgressBar, {
  color: '#5fce58',
  failedColor: '#F56C6C',
  thickness: '2px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
});
Vue.use(ElementUI, { locale });
Vue.use(VueMoment);

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');

Vue.use(IdleVue, {
  eventEmitter: vm.$eventHub,
  idleTime: 1000,
});
