import _ from 'lodash';

import {
  ExposureLookupModel,
  ExposureModel,
  StudyModel,
  PlayingPositionLookupModel,
  OrganisationModel,
} from '@/core/webapi';
import { LookupsService } from '@/core/services';

interface WithStudiesLookup {
  studies?: StudyModel[] | null | undefined;
}

interface WithOrganisationsLookup {
  organisations?: OrganisationModel[] | null | undefined;
}

interface WithOrgStudyIds {
  organisationId?: number | null;
  studyId?: number | null;
}

export const ReportLookupService = {
  getStudies(lookups: WithStudiesLookup | WithOrganisationsLookup, obj: WithOrgStudyIds, isReporter: boolean) {
    if (!lookups) {
      return [];
    }

    if (isReporter) {
      if (!(lookups as WithOrganisationsLookup)?.organisations || !obj.organisationId) {
        return [];
      }

      return (lookups as WithOrganisationsLookup).organisations?.find(p => p.id === obj.organisationId)?.studies ?? [];
    } else {
      return (lookups as WithStudiesLookup).studies ?? [];
    }
  },
  getSelectedStudy(lookups: WithStudiesLookup | WithOrganisationsLookup, obj: WithOrgStudyIds, isReporter: boolean) {
    const studies = ReportLookupService.getStudies(lookups, obj, isReporter);
    return studies?.find(p => p.id === obj.studyId);
  },
  filterStudies(lookups: WithStudiesLookup, studyTypeId: number) {
    if (!lookups || !lookups.studies) {
      return [];
    }

    return lookups.studies.filter(p => p.studyTypeId === studyTypeId);
  },
  filterStudyOrgs(lookups: WithStudiesLookup, studyId: number | null, studyTypeId: number) {
    if (!lookups?.studies || !studyId) {
      return [];
    }

    const study = lookups.studies.find(p => p.id === studyId);
    if (!study?.organisations) {
      return [];
    }

    return study.organisations.filter(p => p.studyTypeId === studyTypeId);
  },
  filterOrgs(lookups: WithOrganisationsLookup, studyTypeId: number) {
    if (!lookups || !lookups.organisations) {
      return [];
    }

    return lookups.organisations.filter(p => p.studyTypeId === studyTypeId);
  },
  filterOrgStudies(lookups: WithOrganisationsLookup, organisationId: number | null, studyTypeId: number) {
    if (!lookups?.organisations || !organisationId) {
      return [];
    }

    const organisation = lookups.organisations.find(p => p.id === organisationId);
    if (!organisation?.studies) {
      return [];
    }

    return organisation.studies.filter(p => p.studyTypeId === studyTypeId);
  },
  getPlayingPositions(playingPositions: PlayingPositionLookupModel[], studies: StudyModel[], studyId: number) {
    if (!playingPositions || !studies || !studyId) {
      return [];
    }

    const study = studies.find(p => p.id === studyId);
    if (!study) {
      return [];
    }

    return playingPositions.filter(p => !!p.gameFormatIds?.includes(study.gameFormatId));
  },
  resolveStudyOrOrgIds(
    lookups: WithStudiesLookup | WithOrganisationsLookup,
    obj: WithOrgStudyIds,
    preselectIfMultipleNested: boolean,
    studyTypeId: number,
    isReporter: boolean,
  ) {
    isReporter
      ? this.resolveOrgStudyFilterIds(lookups as any, obj, preselectIfMultipleNested, studyTypeId)
      : this.resolveStudyOrgFilterIds(lookups as any, obj, preselectIfMultipleNested, studyTypeId);

    return obj;
  },
  resolveStudyOrgFilterIds(
    lookups: WithStudiesLookup,
    obj: WithOrgStudyIds,
    preselectIfMultipleOrgs: boolean,
    studyTypeId: number,
  ) {
    if (!obj.studyId && lookups && lookups.studies && lookups.studies.length > 0) {
      const studies = lookups.studies.filter(p => p.studyTypeId === studyTypeId);
      if (!studies?.length) {
        return;
      }

      const study = studies[0];
      obj.studyId = study.id;

      this.resolveOrgId(study.organisations, obj, preselectIfMultipleOrgs, studyTypeId);
    }

    return obj;
  },
  resolveOrgId(
    organisations: OrganisationModel[] | null | undefined,
    obj: WithOrgStudyIds,
    preselectIfMultipleOrgs: boolean,
    studyTypeId: number,
  ) {
    organisations = organisations?.filter(p => p.studyTypeId === studyTypeId) ?? [];

    if (organisations.length === 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      obj.organisationId = null;
    } else if (organisations.length === 1 || (organisations.length > 1 && preselectIfMultipleOrgs)) {
      // preselectIfMultipleStudies is basically only for grid filters - to make it easier to user grids.
      // It should be set to false when the select-dropdown is used inside an actual data form. There we don't want
      // to preselect the study for users as that makes them be lazy and they don't change the study
      // to the correct one. This leads to bad data for WRY.
      obj.organisationId = organisations[0].id;
    }

    return obj;
  },
  resolveOrgStudyFilterIds(
    lookups: WithOrganisationsLookup,
    obj: WithOrgStudyIds,
    preselectIfMultipleStudies: boolean,
    studyTypeId: number,
  ) {
    if (!obj.organisationId && lookups && lookups.organisations && lookups.organisations.length > 0) {
      const organisations = lookups.organisations.filter(p => p.studyTypeId === studyTypeId);
      if (!organisations?.length) {
        return;
      }

      const organisation = organisations[0];
      obj.organisationId = organisation.id;

      this.resolveStudyId(organisation.studies, obj, preselectIfMultipleStudies, studyTypeId);
    }

    return obj;
  },
  resolveStudyId(
    studies: StudyModel[] | null | undefined,
    obj: WithOrgStudyIds,
    preselectIfMultipleStudies: boolean,
    studyTypeId: number,
  ) {
    studies = studies?.filter(p => p.studyTypeId === studyTypeId) ?? [];

    if (studies.length === 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      obj.studyId = null;
    } else if (studies.length === 1 || (studies.length > 1 && preselectIfMultipleStudies)) {
      // preselectIfMultipleStudies is basically only for grid filters - to make it easier to user grids.
      // It should be set to false when the select-dropdown is used inside an actual data form. There we don't want
      // to preselect the study for users as that makes them be lazy and they don't change the study
      // to the correct one. This leads to bad data for WRY.
      obj.studyId = studies[0].id;
    }

    return obj;
  },
  getSessionPositionText(index: number, sessionPositionId: number, model: ExposureModel, lookups: ExposureLookupModel) {
    const sessions = _.take(model.sessions, index + 1);
    const positionTypeNo = _.filter(sessions, { sessionPositionId }).length;
    const positionType = LookupsService.findLookupText(lookups.sessionPositions!, sessionPositionId);

    return `${positionType} #${positionTypeNo} session`;
  },
};
