





import { Component, Vue, Prop } from 'vue-property-decorator';

import { LookupsService } from '@/core/services';
import { SimpleLookupModel } from '@/core/models';
import DetailsTableRowCpt from './DetailsTableRowCpt.vue';

@Component({
  components: {
    DetailsTableRowCpt,
  },
})
export default class DetailsLookupTableRowCpt extends Vue {
  @Prop() label: string;
  @Prop() lookups: SimpleLookupModel[];
  @Prop() lookupId: number;

  get lookupText() {
    return LookupsService.getLookupText(this.lookups, this.lookupId) ?? '-';
  }
}
