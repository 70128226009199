
import { Vue } from 'vue-property-decorator';
import _ from 'lodash';

import { VueUserContextModel } from './core/webapi';

export default class AppVue extends Vue {
  get userContext() {
    return this.$store.state.userContext as VueUserContextModel;
  }

  get isAuthenticated() {
    return this.$msal.isAuthenticated();
  }

  get email() {
    return this.userContext.email;
  }

  get currentUserId() {
    return this.userContext.userId;
  }

  get currentOrganisationId() {
    return this.userContext.currentOrganisationId;
  }

  get currentStudyTypeId() {
    return this.userContext.currentStudyTypeId;
  }

  get anyReporterStudies() {
    return this.userContext.anyReporterStudies;
  }

  get isAdmin() {
    return this.userContext.isAdmin;
  }

  get isStudyAdmin() {
    return this.userContext.isStudyAdmin;
  }

  get isStudyOrSiteAdmin() {
    return this.userContext.isStudyOrSiteAdmin;
  }

  get isReporter() {
    return this.userContext.isReporter;
  }

  get isAnalyst() {
    return this.userContext.isAnalyst;
  }

  get isMedicalAdmin() {
    return this.userContext.isMedicalAdmin;
  }

  get isMedicalOrSiteAdmin() {
    return this.userContext.isMedicalOrSiteAdmin;
  }

  get isMedicalOrStudyOrSiteAdmin() {
    return this.userContext.isMedicalOrStudyOrSiteAdmin;
  }

  get canAccessInjuryAndIllnessData() {
    return this.userContext.canAccessInjuryAndIllnessData;
  }

  get canAccessExposureData() {
    return this.userContext.canAccessExposureData;
  }

  get canAccessCatastrophicData() {
    return this.userContext.canAccessCatastrophicData;
  }

  get isEliteReporter() {
    return this.userContext.isEliteReporter;
  }

  get isCommunityReporter() {
    return this.userContext.isCommunityReporter;
  }

  get environment() {
    return process.env.VUE_APP_ENVIRONMENT;
  }

  get isDevEnv() {
    return this.environment === 'Dev';
  }

  get isTestEnv() {
    return this.environment === 'Test';
  }

  get isProdEnv() {
    return this.environment === 'Production';
  }

  get isOfflineMode() {
    let isOffline = this.isOnline();

    window.addEventListener('online', () => {
      isOffline = this.isOnline();
      this.$forceUpdate();
    });
    window.addEventListener('offline', () => {
      isOffline = this.isOnline();
      this.$forceUpdate();
    });

    return isOffline;
  }

  protected notify(title: string, message: string, type: any) {
    this.$notify({
      title,
      message,
      type,
    });
  }

  protected getUrlParamAsNumber(paramName: string) {
    const id = this.$route.params[paramName];
    return !!id ? parseInt(id, 10) : undefined;
  }

  private isOnline() {
    return !window.navigator.onLine;
  }
}
