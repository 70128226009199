















import { Component } from 'vue-property-decorator';
import LocaleChangerBase from './LocaleChangerBase.vue';

@Component
export default class LocaleChangerDropCpt extends LocaleChangerBase {}
