export const accessRequestsRoutes = [
  {
    path: '/reporter-access-request/:inviteKey',
    name: 'reporterAccessRequest',
    meta: { layout: 'empty', anonymousOnly: true, title: 'route.reporterAccessRequest' },
    component: () => import(/* webpackChunkName: "accessRequests" */ './ReporterAccessRequestView.vue'),
  },
  {
    path: '/organisation-access-request/:organisationId',
    name: 'organisationAccessRequest',
    meta: { layout: 'default', hideReporterOrg: true, title: 'route.organisationAccessRequest' },
    component: () => import(/* webpackChunkName: "accessRequests" */ './ReporterOrganisationAccessRequestView.vue'),
  },
  {
    path: '/admin/organisation-access-request',
    name: 'organisationAccessRequestReview',
    meta: { layout: 'default', hideReporterOrg: true, title: 'route.organisationAccessRequestReview' },
    component: () => import(/* webpackChunkName: "accessRequests" */ './ReporterOrganisationAccessRequestView.vue'),
  },
];
