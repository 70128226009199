export const adminUsersRoutes = [
  {
    path: '/admin/users/new',
    name: 'adminUserNew',
    meta: { layout: 'default', title: 'route.adminUserNew' },
    component: () => import(/* webpackChunkName: "admin" */ './UserFormView.vue'),
  },
  {
    path: '/admin/users/:userId/edit',
    name: 'adminUserEdit',
    meta: { layout: 'default', title: 'route.adminUserEdit' },
    component: () => import(/* webpackChunkName: "admin" */ './UserFormView.vue'),
  },
  {
    path: '/admin/users/:userId/view',
    name: 'adminUserView',
    meta: { layout: 'default', title: 'route.adminUserView' },
    component: () => import(/* webpackChunkName: "admin" */ './UserFormView.vue'),
  },
  {
    path: '/profile',
    name: 'userProfile',
    meta: { layout: 'default', hideReporterOrg: true, title: 'route.userProfile' },
    component: () => import(/* webpackChunkName: "admin" */ './ReporterProfileView.vue'),
  },
  {
    path: '/admin/users/:userId/activity',
    name: 'adminUserActivity',
    meta: { layout: 'default', title: 'route.adminUserActivity' },
    component: () => import(/* webpackChunkName: "admin" */ './UserEventLogView.vue'),
  },
  {
    path: '/admin/users',
    name: 'adminUserGrid',
    meta: { layout: 'default', title: 'route.adminUserGrid' },
    component: () => import(/* webpackChunkName: "admin" */ './UsersGridView.vue'),
  },
];
