

















import { Component } from 'vue-property-decorator';
import moment from 'moment';

import AppVue from '@/AppVue.vue';
import { StorageService } from '@/core/services';

@Component
export default class IdleWarningCpt extends AppVue {
  // Threshold of remaining allowed idle time (in minutes) before getting logged out
  private IDLE_TIME_REMAINING_WARNING_THRESHOLD = 2;

  // Total allowed idle time before being logged out
  private ALLOWED_IDLE_TIME_IN_MINUTES = this.isTestEnv ? 30 : 15;

  // Total amount of time since last inactive to start showing the idle warning
  private SHOW_IDLE_WARNING_IN_MINUTES = this.ALLOWED_IDLE_TIME_IN_MINUTES - this.IDLE_TIME_REMAINING_WARNING_THRESHOLD;

  // Convert to milliseconds
  private SHOW_IDLE_WARNING_IN_MILLIS = this.SHOW_IDLE_WARNING_IN_MINUTES * 60 * 1000;

  // Interval at which to keep checking if the user has clicked the "Keep me logged in" button
  private KEEP_LOGGED_IN_CHECK_INTERVAL = 1000;

  private remainingLoggedInSeconds = 0;
  private showDialog = false;
  private idleTimeout: number;
  private logoutCountdownInterval: number;
  private logoutAt = moment().add(this.ALLOWED_IDLE_TIME_IN_MINUTES, 'minutes');

  async created() {
    this.$eventHub.$on('idleVue_onIdle', () => {
      this.onUiIdle();
    });

    this.$eventHub.$on('idleVue_onActive', () => {
      this.onUiActive();
    });
  }

  get inactivityMessage() {
    return this.$t('lbl.youWillBeLoggedOutInXsecondsDueToInactivity', [this.remainingLoggedInSeconds]) as string;
  }

  keepLoggedIn() {
    // This is the same action as if the user clicked outside of the
    // modal window or if he closed it by pressing the X button
    this.showDialog = false;
  }

  onClosed() {
    this.clearIdleTimeout();
    this.clearLogoutCountdown();

    if (!this.$msal.isAuthenticated()) {
      this.$notify({
        title: this.$t('lbl.loggedOut') as string,
        message: this.$t('msg.youWereAutoLoggedOutToKeepYourDataSecure') as string,
        type: 'info',
        duration: 0,
      });
      StorageService.setRedirectToUrl(this.$route.fullPath);
      this.$router.push('/login');
    } else {
      this.emitUserSoonToBeLoggedOut();
    }
  }

  private onUiIdle() {
    if (this.$msal.isAuthenticated() && !this.showDialog) {
      this.initializeIdleTimeout();
    }
  }

  private onUiActive() {
    this.clearIdleTimeout();

    if (this.$msal.isAuthenticated() && !this.showDialog) {
      // Prevent refreshing if the modal is visible, it needs to be
      // explicitly confirmed via a click in that case.
      this.emitUserSoonToBeLoggedOut();
    }
  }

  private initializeIdleTimeout() {
    this.logoutAt = moment().add(this.ALLOWED_IDLE_TIME_IN_MINUTES, 'minutes');
    this.clearIdleTimeout();
    this.idleTimeout = setTimeout(() => this.onUserSoonToBeLoggedOut(), this.SHOW_IDLE_WARNING_IN_MILLIS);
  }

  private clearIdleTimeout() {
    clearTimeout(this.idleTimeout);
  }

  private onUserSoonToBeLoggedOut() {
    this.showDialog = true;
    this.initializeLogoutCountdown();
  }

  private initializeLogoutCountdown() {
    this.clearLogoutCountdown();
    this.remainingLoggedInSeconds = this.getRemainingLoggedInSeconds();
    this.logoutCountdownInterval = setInterval(() => this.onNoKeepLoggedIn(), this.KEEP_LOGGED_IN_CHECK_INTERVAL);
  }

  private clearLogoutCountdown() {
    this.remainingLoggedInSeconds = 0;
    clearInterval(this.logoutCountdownInterval);
  }

  private async onNoKeepLoggedIn() {
    this.remainingLoggedInSeconds = this.getRemainingLoggedInSeconds();
    if (this.remainingLoggedInSeconds <= 0) {
      await this.$msal.logout();
      this.showDialog = false;
    }
  }

  private getRemainingLoggedInDuration() {
    const expiryTime = this.logoutAt.utc();
    const now = moment.utc();
    const diff = moment.duration(expiryTime.diff(now));

    return diff;
  }

  private getRemainingLoggedInSeconds() {
    return Number(this.getRemainingLoggedInDuration().asSeconds().toFixed(0));
  }

  private getRemainingLoggedInMinutes() {
    return this.getRemainingLoggedInDuration().asMinutes();
  }

  private emitUserSoonToBeLoggedOut() {
    const diff = this.getRemainingLoggedInMinutes();
    if (diff > 0 && diff < this.IDLE_TIME_REMAINING_WARNING_THRESHOLD) {
      this.$eventHub.$emit('USER_SOON_TO_BE_LOGGED_OUT');
    }
  }
}
