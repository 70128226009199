const REDIRECT_TO_URL_KEY = 'iss-redirect-to-url';

export const StorageService = {
  getStorage(): Storage {
    return sessionStorage;
  },

  // This is for better login experience, remember the URL that the
  // user intended to visit before being forced to login
  setRedirectToUrl(redirectToUrl: string) {
    if (['/no-access'].includes(redirectToUrl)) {
      // Exceptions of when to not remember the redirect URL, add more if needed
      return;
    }

    this.getStorage().setItem(REDIRECT_TO_URL_KEY, redirectToUrl);
  },

  // This is for better login experience, remember the URL that the
  // user intended to visit before being forced to login
  getAndRemoveRedirectToUrl(): string {
    const value = this.getStorage().getItem(REDIRECT_TO_URL_KEY);
    this.getStorage().removeItem(REDIRECT_TO_URL_KEY);

    return value ? value : '';
  },
};
