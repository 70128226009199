export const playersRoutes = [
  {
    path: '/players',
    name: 'players',
    meta: { layout: 'default', title: 'route.players' },
    component: () => import(/* webpackChunkName: "players" */ './PlayersGridView.vue'),
  },
  {
    path: '/players/import',
    name: 'playersImport',
    meta: { layout: 'default', title: 'route.playersImport' },
    component: () => import(/* webpackChunkName: "players" */ './PlayersImportView.vue'),
  },
  {
    path: '/players/new',
    name: 'playerNew',
    meta: { layout: 'default', title: 'route.playerNew' },
    component: () => import(/* webpackChunkName: "admin" */ './PlayerFormView.vue'),
  },
  {
    path: '/players/:playerId/edit',
    name: 'playerEdit',
    meta: { layout: 'default', title: 'route.playerEdit' },
    component: () => import(/* webpackChunkName: "admin" */ './PlayerFormView.vue'),
  },
  {
    path: '/players/:playerId',
    name: 'playerDetails',
    meta: { layout: 'default', title: 'route.playerDetails' },
    component: () => import(/* webpackChunkName: "players" */ './PlayerDetailsView.vue'),
  },
];
