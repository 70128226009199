
import { Prop } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { Validatable } from '@/core/models';

export default class BaseInputComponent extends AppVue {
  @Prop() model: Validatable;
  @Prop() prop: string;
  @Prop() label?: string;
  @Prop() size?: string;
  @Prop() placeholder?: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: '' }) tooltipContent: string;

  get isError() {
    if (!this.prop || !this.model || !this.model.modelState) {
      return false;
    }

    const modelErrors = this.model.modelState[this.prop];
    return _.filter(modelErrors, item => !!item).length > 0;
  }

  onChange(value: any) {
    this.$emit('change', value);

    if (!this.prop || !this.model || !this.model.modelState) {
      return;
    }

    this.model.modelState[this.prop] = null as any;
  }
}
