import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  EndSessionRequest,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
  LogLevel,
  AuthError,
  AccountEntity,
} from '@azure/msal-browser';

import { USER_CONTEXT_KEY, store } from '@/core/store';
import router from '@/router';

const DEFAULT_UNAUTHENTICATED_REDIRECT_URI = '/login';
const DEFAULT_AUTHENTICATED_REDIRECT_URI = '/';
const CANCEL_ERROR_CODE = 'AADB2C90091';
const PASSWORD_RESET_ERROR_CODE = 'AADB2C90118';

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: process.env.VUE_APP_B2C_CLIENT_ID,
    authority: process.env.VUE_APP_B2C_SIGN_UP_SIGN_IN_PHONE_AUTHORITY,
    knownAuthorities: [process.env.VUE_APP_B2C_KNOWN_AUTHORITY],
    redirectUri: process.env.VUE_APP_B2C_REDIRECT_URI,
    // We have custom code to handle return URL's. We are using custom code as we want
    //  to retrieve the logged in user roles before redirecting to the return URL
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        return; // Logger is too noisy, uncomment if debugging is needed

        if (containsPii) {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export class AuthService {
  private myMSALObj: PublicClientApplication;
  private account: AccountInfo | null;
  private loginRedirectRequest: RedirectRequest;

  constructor() {
    window.addEventListener('load', async () => {
      this.loadAuthModule();
    });

    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
    this.account = this.getLoggedInAccount();

    this.loginRedirectRequest = {
      scopes: [process.env.VUE_APP_B2C_ISS_API_READ_WRITE_SCOPE],
      redirectStartPage: process.env.VUE_APP_B2C_REDIRECT_URI,
    };
  }

  /**
   * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs
   *   /initialization.md#redirect-apis
   */
  loadAuthModule() {
    this.myMSALObj
      .handleRedirectPromise()
      .then((response: AuthenticationResult | null) => {
        this.handleResponse(response);
      })
      .catch((error: any) => {
        if (error && error instanceof AuthError) {
          const authError = error as AuthError;

          if (this.isOfErrorCode(authError, CANCEL_ERROR_CODE)) {
            // The user has clicked "Cancel" on B2C policy form
            this.goToDefaultRoute();
            return;
          } else if (this.isOfErrorCode(authError, PASSWORD_RESET_ERROR_CODE)) {
            // User has requested a password reset - redirect them to tha correct policy
            this.passwordReset();
            return;
          }
        }

        console.error(error);
      });
  }

  isOfErrorCode(error: AuthError, errorCode: string) {
    return error.errorCode === 'access_denied' && error.errorMessage.startsWith(`${errorCode}:`);
  }

  /**
   * Redirects user to the default route. The default route depends on whether the user is logged in or not.
   */
  goToDefaultRoute() {
    if (this.isAuthenticated()) {
      router.push(DEFAULT_AUTHENTICATED_REDIRECT_URI);
    } else {
      router.push(DEFAULT_UNAUTHENTICATED_REDIRECT_URI);
    }
  }

  register() {
    const signUpRequest: RedirectRequest = {
      scopes: [process.env.VUE_APP_B2C_ISS_API_READ_WRITE_SCOPE],
      authority: process.env.VUE_APP_B2C_SIGN_UP_AUTHORITY,
      redirectStartPage: DEFAULT_UNAUTHENTICATED_REDIRECT_URI,
    };

    return this.myMSALObj.loginRedirect(signUpRequest);
  }

  loginPhone() {
    return this.myMSALObj.loginRedirect(this.loginRedirectRequest);
  }

  loginTotp() {
    const loginRequest: RedirectRequest = {
      scopes: [process.env.VUE_APP_B2C_ISS_API_READ_WRITE_SCOPE],
      authority: process.env.VUE_APP_B2C_SIGN_UP_SIGN_IN_TOTP_AUTHORITY,
      redirectStartPage: DEFAULT_UNAUTHENTICATED_REDIRECT_URI,
    };

    return this.myMSALObj.loginRedirect(loginRequest);
  }

  logout(redirectToRoute: string = DEFAULT_UNAUTHENTICATED_REDIRECT_URI) {
    if (!this.isAuthenticated()) {
      sessionStorage.removeItem(USER_CONTEXT_KEY);
      return Promise.resolve();
    }

    const logOutRequest: EndSessionRequest = {
      account: this.getLoggedInAccountOrThrow(),
      postLogoutRedirectUri: redirectToRoute,
    };

    this.account = null;
    sessionStorage.removeItem(USER_CONTEXT_KEY);

    return this.myMSALObj.logoutRedirect(logOutRequest);
  }

  passwordReset() {
    const passwordResetRequest: RedirectRequest = {
      scopes: [process.env.VUE_APP_B2C_ISS_API_READ_WRITE_SCOPE],
      authority: process.env.VUE_APP_B2C_PASSWORD_RESET_AUTHORITY,
      redirectStartPage: DEFAULT_UNAUTHENTICATED_REDIRECT_URI,
    };

    this.myMSALObj.loginRedirect(passwordResetRequest);
  }

  isAuthenticated() {
    return this.account !== null || this.getLoggedInAccount() !== null;
  }

  /**
   * Maybe add account chooser code in the future. For now, just select the LAST one found.
   * Initial setup done by Tomas would pick up the first one found, but I think that might be causing issues.
   * Will try test this change out and see how it goes.
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   *
   * By Kit:
   * The difference between getLoggedInAccount and getLoggedInAccountOrThrow is that the second one throws, so it's
   * unsafe. I wanted to merge these 2 methods, but I suspect that the safe version was actually kind of needed
   * somewhere, instead of interrupting the flow by throwing errors.
   */
  getLoggedInAccount() {
    const cachedAccounts = this.myMSALObj.getAllAccounts();

    // NOTE: Keeping the commented code for potential future debugging
    // for (const i in cachedAccounts) {
    //   const acc = cachedAccounts[i];
    //   const key = AccountEntity.generateAccountCacheKey(acc);
    //   console.log('---- ' + key);
    //   console.log(acc);
    //   console.log(new Date((acc.idTokenClaims as any).exp * 1000));
    // }

    if (!cachedAccounts || cachedAccounts.length === 0) {
      this.account = null;
      return null;
    }

    const orderedByExpiry = cachedAccounts.sort(p => (p.idTokenClaims as any).exp);
    const activeAccount = orderedByExpiry[orderedByExpiry.length - 1];

    // Check if latest token has expired
    const exp = (activeAccount.idTokenClaims as any)?.exp;
    if (!exp || exp <= Date.now() / 1000) {
      this.removeStaleAccounts(cachedAccounts, '');
      this.account = null;
      return null;
    }

    this.account = activeAccount;

    if (cachedAccounts.length > 1) {
      const activeAccountKey = AccountEntity.generateAccountCacheKey(activeAccount);
      this.removeStaleAccounts(cachedAccounts, activeAccountKey);
    }

    return activeAccount;
  }

  removeStaleAccounts(accountsToRemove: AccountInfo[], activeAccountKey: string) {
    const keysToRemove: string[] = [];

    for (const i in accountsToRemove) {
      const key = AccountEntity.generateAccountCacheKey(accountsToRemove[i]);
      if (key !== activeAccountKey) {
        keysToRemove.push(key);
      }
    }

    for (const i in keysToRemove) {
      (this.myMSALObj as any).browserStorage.removeAccount(keysToRemove[i]);
      // console.log(`Removed stale account: ${keysToRemove[i]}`);
    }
  }

  getLoggedInAccountOrThrow() {
    const account = this.getLoggedInAccount();
    if (account === null) {
      throw new Error('User is not currently logged in');
    }

    return account;
  }

  /**
   * Gets the token silently, or falls back to interactive redirect.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getTokenRedirect(silentRequest: SilentRequest, interactiveRequest?: RedirectRequest) {
    try {
      const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        this.myMSALObj.acquireTokenRedirect(this.loginRedirectRequest).catch(console.error);
      } else {
        console.error(e);
      }
    }

    return '';
  }

  async getAccessToken() {
    if (!this.isAuthenticated) {
      return '';
    }

    const silentRequest: SilentRequest = {
      scopes: [process.env.VUE_APP_B2C_ISS_API_READ_WRITE_SCOPE],
      forceRefresh: false,
      account: this.getLoggedInAccountOrThrow(),
    };

    return await this.getTokenRedirect(silentRequest);
  }

  /**
   * Handles the response from a popup or redirect. If response is null, will check if we have any
   * accounts and attempt to sign in.
   */
  private async handleResponse(response: AuthenticationResult | null) {
    this.account = response !== null ? response.account : this.getLoggedInAccount();

    if (this.account) {
      // We are logged in! Now get user profile data for this user
      await store.dispatch('setUserContext');

      // Once the user profile is updated, the /authenticate component will spot this and
      // redirect the user the correct URL
    } else if (response === null) {
      // This is probably caused by the "interaction_in_progress:  Interaction is currently in progress" error.
      if (router.currentRoute.path === '/authenticate') {
        router.push(DEFAULT_UNAUTHENTICATED_REDIRECT_URI);
      }
    }
  }
}
