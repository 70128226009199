










import { Component, Prop } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';

@Component
export default class StatusTagCpt extends AppVue {
  @Prop() type: 'success' | 'warning' | 'info';
  @Prop() text: string;
  @Prop({ default: true }) enforcePendingInfoIcon: boolean;

  get effect() {
    return this.type === 'success' ? 'dark' : 'plain';
  }

  get iconClass() {
    if (this.type === 'success') {
      return 'fas el-icon-fa-check';
    }

    if (this.type === 'warning') {
      return 'fas el-icon-fa-clock';
    }

    if (this.type === 'info' && this.enforcePendingInfoIcon) {
      return 'fas el-icon-fa-clock';
    }

    return null;
  }
}
