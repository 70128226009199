












import Vue, { PropType } from 'vue';

import { GridModel } from './grid.models';

export default Vue.extend({
  name: 'GridSearchCpt',
  props: {
    grid: Object as PropType<GridModel<any>>,
    placeholder: String,
  },
  data: () => ({
    debounce: 0,
    search: '',
  }),
  watch: {
    grid: {
      handler(grid: any) {
        if (grid.filter) {
          this.search = grid.filter.search;
          this.$forceUpdate();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.search = this.grid.filter.search;
    this.$eventHub.$on('GRID_DATA_UPDATED', () => {
      this.$forceUpdate();
    });
  },
  beforeDestroy() {
    this.$eventHub.$off('GRID_DATA_UPDATED');
  },
  methods: {
    onChange() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.grid.search(this.search);
      }, 350);
    },
    onClear() {
      this.grid.search('');
    },
  },
});
