export const adminRegistrationRoutes = [
  {
    path: '/admin/reporter-access-requests',
    name: 'adminReporterAccessRequests',
    meta: { layout: 'default', title: 'route.reporterAccessRequests' },
    component: () => import(/* webpackChunkName: "admin" */ './ReporterAccessRequestsGridView.vue'),
  },
  {
    path: '/admin/reporter-access-requests/:requestId/review',
    name: 'reporterAccessRequestReview',
    meta: { layout: 'default', title: 'route.reporterAccessRequestReview' },
    component: () => import(/* webpackChunkName: "admin" */ './ReporterAccessRequestReviewView.vue'),
  },
];
