
import AppVue from '@/AppVue.vue';

export default abstract class BaseFormComponent extends AppVue {
  submitInProgress = false;
  abstract onSubmitForm(): Promise<void>;

  async submitForm() {
    if (!this.onSubmitForm) {
      throw new Error('onSubmitForm not implemented');
    }

    this.submitInProgress = true;

    try {
      await this.onSubmitForm();
    } finally {
      this.submitInProgress = false;
    }
  }

  protected onSuccess(route?: string, customMessage?: string) {
    // TODO: i18n
    this.notify('Success', customMessage || (this.$t('lbl.itemSaved') as string), 'success');

    if (route) {
      this.$router.push(route);
    }
  }
}
