import Vue from 'vue';
import Router from 'vue-router';

import { RedirectService, StorageService } from '@/core/services';
import { authRoutes } from '@/modules/auth/routes';
import { playersRoutes } from '@/modules/players/routes';
import { reportsRoutes } from '@/modules/reports/routes';
import { adminRoutes } from '@/modules/admin/routes';
import { accessRequestsRoutes } from '@/modules/access-requests/routes';
import { createVueUserContext, IAppStoreState } from './core/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...authRoutes, ...playersRoutes, ...reportsRoutes, ...adminRoutes, ...accessRequestsRoutes],
});

router.beforeEach((to, from, next) => {
  const anonymousOnly = to.matched.some(record => record.meta.anonymousOnly);
  const storeState = (router.app.$store?.state as any) as IAppStoreState;
  const userContext = storeState.userContext ?? createVueUserContext();
  const isAuthenticated = router.app.$msal?.isAuthenticated() ?? false;
  const isUserContextLoaded = !!userContext.userId;

  // For duplicate redirects
  // SEE: https://stackoverflow.com/a/59431264/413785

  if (!to.name) {
    // The first two rules are not part of getDefaultAuthenticatedRoute because
    // they're only fallbacks in case of a non-existing route. We just want
    // to help the user a little so they can then navigate further.
    // The getDefaultAuthenticatedRoute() function is for fully loaded users only.
    if (!isAuthenticated) {
      next('/login');
    } else if (!userContext.userId) {
      next('/admin'); // Some kind of fallback so users can navigate further from there
    } else {
      next(RedirectService.getDefaultAuthenticatedRoute(userContext, isAuthenticated));
    }

    return;
  }

  if (!anonymousOnly) {
    if (!isAuthenticated) {
      // If a non-anonymous-only route and user not authenticated, redirect to login
      StorageService.setRedirectToUrl(to?.fullPath);
      next('/login');
      return;
    } else if (!isUserContextLoaded) {
      // If the context user did not load yet from the backend, remember their intended route
      StorageService.setRedirectToUrl(to?.fullPath);
    }
    // If non-anonymous-only route and user authenticated, don't do anything
  }

  next();
});

export default router;
