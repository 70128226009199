















































import { Component, Watch } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { StudyTypes } from '@/core/constants';
import { store } from '@/core/store';
import { VueUserContextModel } from '../webapi';

@Component
export default class NoReporterOrganisationMessageCpt extends AppVue {
  organisationId: number | null = 0;
  missingAccessMessage = '';
  isReporterWithNoAccess = false;
  visible = true;

  get organisations() {
    return this.userContext?.reporterOrganisations ?? [];
  }

  get showOrganisationPicker() {
    const isMultiOrgReporter = this.organisations && this.organisations.length > 1;
    return this.organisationId && isMultiOrgReporter;
  }

  get showRequestChanges() {
    return this.organisations && this.organisations.length > 0;
  }

  get canRequestStudyAccess() {
    const isProfilePage = this.$route.name === 'route.userProfile';
    return !!this.organisationId && isProfilePage && !this.anyReporterStudies;
  }

  get actionMessage() {
    if (this.showOrganisationPicker) {
      return this.$t('lbl.pleaseRequestChangesOnTheProfilePageOrSwitchToAnotherOrganisation');
    } else if (this.canRequestStudyAccess) {
      return this.$t('lbl.pleaseRequestChangesOrContactAdministrator');
    } else {
      return this.$t('lbl.pleaseContactYourAdministrator');
    }
  }

  @Watch('$store.state.userContext')
  onUserContextChanged(val: VueUserContextModel, oldVal: VueUserContextModel) {
    this.updateOrgReporterState();
  }

  async created() {
    this.updateOrgReporterState();
  }

  updateOrgReporterState() {
    this.missingAccessMessage = '';
    this.organisationId = this.currentOrganisationId;

    if (!this.userContext) {
      return;
    }

    if (!this.isReporter) {
      return;
    }

    if (!this.organisationId) {
      this.missingAccessMessage = this.$t('msg.youHaveNotBeenAddedToAnOrganisationYet') as string;
      return;
    }

    const allowIaI = this.userContext.canAccessInjuryAndIllnessData;
    const allowExp = this.userContext.canAccessExposureData;
    const allowCts = this.userContext.canAccessCatastrophicData;
    const dontAllowElite = !allowIaI && !allowExp && !allowCts;

    if (this.currentStudyTypeId === StudyTypes.Elite && dontAllowElite) {
      this.missingAccessMessage = this.$t('msg.youDontHaveReporterAccessToPlayerOrTrainingDataYet') as string;
      return;
    }

    if (!this.anyReporterStudies) {
      this.missingAccessMessage = this.$t('msg.youDontHaveReporterStudyAccessForThisOrganisationYet') as string;
      return;
    }
  }

  requestChanges() {
    this.close();
    if (!this.$route.path.startsWith('/profile')) {
      this.$router.push('/profile');
    }
  }

  async logout() {
    await this.$msal.logout();

    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('msg.youAreNowLoggedOut') as string,
      type: 'success',
    });

    this.$router.push('/login');
  }

  async changeOrganisation(organisationId: number) {
    await store.dispatch('setUserContext', { organisationId });
    this.$forceUpdate();
  }

  close() {
    this.visible = false;
  }
}
