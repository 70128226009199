







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DetailsTableRowCpt extends Vue {
  @Prop() label: string;
  @Prop() value: string;
}
