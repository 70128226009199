import { AccountInfo } from '@azure/msal-browser';

import { AuthService } from '@/core/services/auth.service';

export interface IMsalPlugin {
  isAuthenticated(): boolean;
  register(): Promise<void>;
  loginPhone(): Promise<void>;
  loginTotp(): Promise<void>;
  logout(redirectToRoute?: string): Promise<void>;
  getAccessToken(): Promise<string>;
  getLoggedInAccount(): AccountInfo | null;
  getLoggedInAccountOrThrow(): AccountInfo;
  getStatus(): string;
  passwordReset(): void;
}

export default class MsalPlugin {
  static install(Vue: any, options: any) {
    Vue.prototype.$msal = new MsalPlugin(options, Vue);
  }

  // eslint-disable-next-line
  constructor(options: any, Vue?: any) {
    const authService = new AuthService();

    const exposed: IMsalPlugin = {
      isAuthenticated() {
        return authService.isAuthenticated();
      },
      register() {
        return authService.register();
      },
      loginPhone() {
        return authService.loginPhone();
      },
      loginTotp() {
        return authService.loginTotp();
      },
      logout(redirectToRoute?: string) {
        return authService.logout(redirectToRoute);
      },
      getAccessToken() {
        return authService.getAccessToken();
      },
      getLoggedInAccount() {
        return authService.getLoggedInAccount();
      },
      getLoggedInAccountOrThrow() {
        return authService.getLoggedInAccountOrThrow();
      },
      getStatus() {
        return `S ${authService.isAuthenticated()}`;
      },
      passwordReset() {
        authService.passwordReset();
      },
    };

    return exposed;
  }
}
