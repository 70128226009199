










import { Component, Vue, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import { StudyCollectionModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import { AutoCompleteItem } from '@/core/models';
import BaseFinderCpt from './BaseFinderCpt.vue';

@Component({
  components: {
    BaseFinderCpt,
  },
})
export default class StudyFinderCpt extends Vue {
  @Prop() onSelected?: (foundItem: AutoCompleteItem) => void;
  @Prop({ default: false }) autofocus: boolean;
  @Prop() studyTypeId?: number;

  get noResultsText() {
    return this.$t('lbl.noMatchingStudiesFound');
  }

  async findMethod(search: string) {
    const filter = { search, studyTypeId: this.studyTypeId } as any;
    return (await ApiService.studies().filterStudies(filter)).data?.items;
  }

  formatResultList(items: StudyCollectionModel[]) {
    return _.map(items, item => {
      return {
        id: item.id,
        value: `${item.name} (${item.studyType})`,
      };
    });
  }
}
