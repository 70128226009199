/* tslint:disable */
/* eslint-disable */
/**
 * WRY_Altaria API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AnalystDashboardItem
 */
export class AnalystDashboardItem {
  /**
   *
   * @type {string}
   * @memberof AnalystDashboardItem
   */
  reportType: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AnalystDashboardItem
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AnalystDashboardItem
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AnalystDashboardItem
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AnalystDashboardItem
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AnalystDashboardItem
   */
  awaitingAnalystCount: number = undefined as any;

  constructor(data?: Partial<AnalystDashboardItem>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AnalystRolePermissionsModel
 */
export class AnalystRolePermissionsModel {
  /**
   *
   * @type {number}
   * @memberof AnalystRolePermissionsModel
   */
  userId: number = undefined as any;
  /**
   * Analyst study ids - only for aspNetUsers with Analyst role id.
   * @type {Array<number>}
   * @memberof AnalystRolePermissionsModel
   */
  analystStudyIds?: Array<number> | null = undefined as any;

  constructor(data?: Partial<AnalystRolePermissionsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ApiKeyCollectionModel
 */
export class ApiKeyCollectionModel {
  /**
   *
   * @type {number}
   * @memberof ApiKeyCollectionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ApiKeyCollectionModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ApiKeyCollectionModel
   */
  keyHint: string = undefined as any;
  /**
   *
   * @type {Array<ApiKeyCollectionStudyModel>}
   * @memberof ApiKeyCollectionModel
   */
  studies?: Array<ApiKeyCollectionStudyModel> | null = undefined as any;

  constructor(data?: Partial<ApiKeyCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ApiKeyCollectionModelGridCollection
 */
export class ApiKeyCollectionModelGridCollection {
  /**
   *
   * @type {Array<ApiKeyCollectionModel>}
   * @memberof ApiKeyCollectionModelGridCollection
   */
  items?: Array<ApiKeyCollectionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ApiKeyCollectionModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ApiKeyCollectionModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ApiKeyCollectionModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ApiKeyCollectionModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<ApiKeyCollectionModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ApiKeyCollectionStudyModel
 */
export class ApiKeyCollectionStudyModel {
  /**
   *
   * @type {number}
   * @memberof ApiKeyCollectionStudyModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ApiKeyCollectionStudyModel
   */
  name: string = undefined as any;

  constructor(data?: Partial<ApiKeyCollectionStudyModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AuditLogEventModel
 */
export class AuditLogEventModel {
  /**
   *
   * @type {number}
   * @memberof AuditLogEventModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AuditLogEventModel
   */
  timestamp: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AuditLogEventModel
   */
  type: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AuditLogEventModel
   */
  data: string = undefined as any;

  constructor(data?: Partial<AuditLogEventModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AutoSaveCollectionModel
 */
export class AutoSaveCollectionModel {
  /**
   *
   * @type {number}
   * @memberof AutoSaveCollectionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AutoSaveCollectionModel
   */
  forId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AutoSaveCollectionModel
   */
  autoSaveTypeId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AutoSaveCollectionModel
   */
  autoSaveType: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AutoSaveCollectionModel
   */
  dateCreated: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AutoSaveCollectionModel
   */
  dateUpdated: string = undefined as any;

  constructor(data?: Partial<AutoSaveCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AutoSaveModel
 */
export class AutoSaveModel {
  /**
   * AutoSave.Id (PK).
   * @type {number}
   * @memberof AutoSaveModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AutoSaveModel
   */
  forId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AutoSaveModel
   */
  payload: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AutoSaveModel
   */
  autoSaveTypeId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AutoSaveModel
   */
  organisationId: number = undefined as any;

  constructor(data?: Partial<AutoSaveModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Model for CRUD of basic user account info.
 * @export
 * @interface BasicUserAccountDetailsModel
 */
export class BasicUserAccountDetailsModel {
  /**
   * User Id.
   * @type {number}
   * @memberof BasicUserAccountDetailsModel
   */
  id: number = undefined as any;
  /**
   * First name.
   * @type {string}
   * @memberof BasicUserAccountDetailsModel
   */
  firstName: string = undefined as any;
  /**
   * Last name.
   * @type {string}
   * @memberof BasicUserAccountDetailsModel
   */
  lastName: string = undefined as any;
  /**
   * User email.
   * @type {string}
   * @memberof BasicUserAccountDetailsModel
   */
  email: string = undefined as any;
  /**
   * Mobile phone number.
   * @type {string}
   * @memberof BasicUserAccountDetailsModel
   */
  mobile: string = undefined as any;
  /**
   * Is email confirmed?
   * @type {boolean}
   * @memberof BasicUserAccountDetailsModel
   */
  emailConfirmed: boolean = undefined as any;
  /**
   * Is user locked out?
   * @type {boolean}
   * @memberof BasicUserAccountDetailsModel
   */
  isLockedOut: boolean = undefined as any;
  /**
   * Role ids user belongs to.
   * @type {Array<number>}
   * @memberof BasicUserAccountDetailsModel
   */
  roleIds?: Array<number> | null = undefined as any;

  constructor(data?: Partial<BasicUserAccountDetailsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CatastrophicFollowUpLookupModel
 */
export class CatastrophicFollowUpLookupModel {
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicFollowUpLookupModel
   */
  perHospitalCare?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicFollowUpLookupModel
   */
  hospitalTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicFollowUpLookupModel
   */
  glasgow?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<CatastrophicFollowUpLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CatastrophicFollowUpModel
 */
export class CatastrophicFollowUpModel {
  /**
   *
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  id: number = undefined as any;
  /**
   * Follow up Id.
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  followUpId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  autoSaveId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  playerFirstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  playerLastName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  refNumber: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  dateOccurred: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  perHospitalCareId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  perHospitalCareOther: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  dateOfHospitalArrival: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  timeOfHospitalArrival: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  hospitalTypeId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  hospitalTypeOther: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  finalDiagnosis: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  anyProceduresSince?: boolean | null = undefined as any;
  /**
   *
   * @type {Array<CatastrophicFollowUpProcedureModel>}
   * @memberof CatastrophicFollowUpModel
   */
  procedures?: Array<CatastrophicFollowUpProcedureModel> | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  wasSpinalChordInjury?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  didExhibitPreExistingSpinal?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  preExistingSpinalDetails: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  onAdmissionSensoryAsia: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  onAdmissionMotorAsia: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  preFirstProcedureSensoryAsia: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  preFirstProcedureMotorAsia: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  postLastProcedureSensoryAsia: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  postLastProcedureMotorAsia: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  yearPostInjurySensoryAsia: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  yearPostInjuryMotorAsia: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  asiaOther: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  wasTraumaticHeadInjury?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  wasOpenFracture?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  wasClosedFracture?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  wasInterCranialBleed?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  interCranialBleedType: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  wasPostTraumaticAmnesia?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  postTraumaticAmnesiaPeriod: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  wasSeizure?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  seizurePeriod: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  wasLossOfConsciousness?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  lossOfConsciousnessPeriod: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  onAdmissionGcs?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  onAdmissionGosId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  preFirstProcedureGcs?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  preFirstProcedureGosId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  postLastProcedureGcs?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  postLastProcedureGosId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  yearPostInjuryGcs?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicFollowUpModel
   */
  yearPostInjuryGosId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  otherGcsGocInfo: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  wasFatality?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  dateOfDeath: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  causeOfDeathPostMortem: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  wasCardiaDeath?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  anyPreExistingHeartConditions?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  preExistingHeartConditions: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  hadPreviousCardiacScreening?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  hadScreeningQuestionnaire?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  hadScreeningPhysicalExamination?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  hadScreening12LeadEcg?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  hadScreeningEchocardiagram?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicFollowUpModel
   */
  hadScreeningOther?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  screeningOther: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpModel
   */
  otherNonCoveredInfo: string = undefined as any;

  constructor(data?: Partial<CatastrophicFollowUpModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CatastrophicFollowUpProcedureModel
 */
export class CatastrophicFollowUpProcedureModel {
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpProcedureModel
   */
  dateOfProcedure: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicFollowUpProcedureModel
   */
  description: string = undefined as any;

  constructor(data?: Partial<CatastrophicFollowUpProcedureModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * This filter is to be used in all the places where organisationId  is required for reporters to display the grid data.
 * @export
 * @interface CatastrophicGridFilter
 */
export class CatastrophicGridFilter {
  /**
   *
   * @type {number}
   * @memberof CatastrophicGridFilter
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicGridFilter
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicGridFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicGridFilter
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicGridFilter
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicGridFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicGridFilter
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicGridFilter
   */
  search: string = undefined as any;

  constructor(data?: Partial<CatastrophicGridFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CatastrophicInjuryCollectionModel
 */
export class CatastrophicInjuryCollectionModel {
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryCollectionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryCollectionModel
   */
  followUpId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryCollectionModel
   */
  playerFirstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryCollectionModel
   */
  playerLastName: string = undefined as any;

  constructor(data?: Partial<CatastrophicInjuryCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CatastrophicInjuryCollectionModelGridCollection
 */
export class CatastrophicInjuryCollectionModelGridCollection {
  /**
   *
   * @type {Array<CatastrophicInjuryCollectionModel>}
   * @memberof CatastrophicInjuryCollectionModelGridCollection
   */
  items?: Array<CatastrophicInjuryCollectionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryCollectionModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryCollectionModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryCollectionModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryCollectionModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<CatastrophicInjuryCollectionModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CatastrophicInjuryLookupModel
 */
export class CatastrophicInjuryLookupModel {
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  ageCategories?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  sex?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  ethnicities?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  agesStartedPlaying?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  gradesOfPlay?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  playingPositions?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  gameFormats?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  seasonStages?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  matchLevels?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  matchTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  injuryTimes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  dangerousPlays?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  lightTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  activityTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  rolesInEvent?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  typesOfContact?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  scrumOppositions?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  scrumTeams?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  injuryReasons?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  ruckPositions?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  maulOccurredDuring?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  occurredHow?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  trainingUndertaken?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  medicalPractitioners?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  firstAttendedBy?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  firstAttendeeActions?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  firstAttendedLocations?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  removalAssistance?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  removalEquipment?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  transport?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  initialDiagnoses?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  stingers?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  pastStingersFrequencies?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  stingersAffects?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  neckProblems?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  treatmentReceived?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  trainingRegimens?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  scrumEngagements?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  neckStrengtheningFrequencies?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  surfaceTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  footwear?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  surfaceFirmness?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  surfaceConditions?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  surfaceGrips?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CatastrophicInjuryLookupModel
   */
  playingEquipment?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<CatastrophicInjuryLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CatastrophicInjuryModel
 */
export class CatastrophicInjuryModel {
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  autoSaveId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  playerFirstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  playerLastName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  ageCategoryId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  sexId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  weight?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  height?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  ethnicityId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  ethnicityOther: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  ageStartedPlayingId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  totalYearsPlayed?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  gradeOfPlayId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  isRegisteredByTheUnion?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  usualPlayingPositionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  yearsPlayedPosition?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  yearInjuryOccurred?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  gameFormatId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  seasonStageId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  matchLevelId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  matchTypeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  wasOppositionSameAgeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  wasOppositionSameGradeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  wasOppositionEqualStandardId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  injuryTimeId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  wasResultOfDangerousPlay?: boolean | null = undefined as any;
  /**
   *
   * @type {Array<number>}
   * @memberof CatastrophicInjuryModel
   */
  dangerousPlayIds?: Array<number> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  injuredPlayerPositionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  wasPlayingNormalPositionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  lightTypeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  wasMatchOfficiatedId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  wasTrainingSessionSupervisedId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  wasRefereeQualifiedId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  wasCoachQualifiedId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  activityTypeId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  activityTypeOther: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  roleInTackleId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  typeOfContactId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  noOfTacklersInvolved?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  relevantTackleInfo: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  scrumOppositionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  noOfPlayersMachineScrumming?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  wasScrumContestedId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  noOfTeamPlayersScrumming?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  noOfOppositionPlayersScrumming?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  scrumTeamId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  injuryReasonId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  didInjuryOccurAfterResetId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  noOfScrumResets?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  ruckRoleId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  ruckPositionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  maulOccurredDuringId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  maulOccurredDuringOther: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  maulOtherInfo: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  occurredHowId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  occurredHowOther: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  lineoutInfo: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  trainingUndertakenId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  wasMedicalPractitionerPresent?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  presentMedicalPractitionerId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  wasFirstAttendeeNonMedical?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  firstAttendedById?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  firstAttendeeActionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  firstMedicalPractitionerId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  firstAttendedLocationId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  removalAssistanceId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  removalAssistanceOther: string = undefined as any;
  /**
   *
   * @type {Array<number>}
   * @memberof CatastrophicInjuryModel
   */
  removalEquipmentIds?: Array<number> | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  removalEquipmentOther: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  wasImmediatelyHospitalized?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  wasTakenToHospitalLaterStage?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  hoursFromInjuryToHospitalization?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  daysFromInjuryToHospitalization?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  circumstancesOfLateHospitalization: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  transportId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  transportOther: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  initialDiagnosisId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  initialDiagnosisOther: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  didSufferAndInterruptedPriorInjury?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  priorSufferingDetails: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  priorSufferingDiagnosedByMedical?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  didHaveLongTermConditions?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  longTermConditionsDetails: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  longTermConditionsDiagnosedByMedical?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  hasStingerInjuriesHistory?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  stingerInjuryYears: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  stringerId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  stingerFrequencyId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  stingerAffectId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  any24hStringersId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  anyProlongedStringersId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  anyNeckSpinalInjuries?: boolean | null = undefined as any;
  /**
   *
   * @type {Array<number>}
   * @memberof CatastrophicInjuryModel
   */
  neckProblemsIds?: Array<number> | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  hasRecoveredFromNeckSpinal?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  hasReceivedTreatmentForPreviousNeckSpinal?: boolean | null = undefined as any;
  /**
   *
   * @type {Array<number>}
   * @memberof CatastrophicInjuryModel
   */
  treatmentsReceivedIds?: Array<number> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  anyHeadBrainInjuriesId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  headBrainInjuryDetails: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  hasReceivedTreatmentForHeadBrainId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  headBrainTreatmentDetails: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  hasApprovedAfterHeadBrainId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  preSeasonTrainingWeeks?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  seasonMatchesPriorToInjury?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  tacklingTraining?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  scrumTraining?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  ruckMaulTraining?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  lineoutTraining?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  hadRegularCoach?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  coachSameAsTrainingSessionSupervision?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  wasRelevantCoachQualified?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  teamTrainingSessionsPerWeek?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  individualTrainingSessionsPerWeek?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  trainingVolumeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  trainingIntensityId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  scrumEngagementsId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  scrumVolumeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  scrumIntensityId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  neckStrengtheningFrequencyId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CatastrophicInjuryModel
   */
  wereWeatherConditionsTypical?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  typicalWeatherConditions: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  weatherConditionsDifferedHow: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  surfaceTypeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  footwearId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  footwearOther: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  surfaceFirmnessId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  surfaceConditionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CatastrophicInjuryModel
   */
  surfaceGripId?: number | null = undefined as any;
  /**
   *
   * @type {Array<number>}
   * @memberof CatastrophicInjuryModel
   */
  playingEquipmentIds?: Array<number> | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CatastrophicInjuryModel
   */
  playingEquipmentOther: string = undefined as any;

  constructor(data?: Partial<CatastrophicInjuryModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CommunityExposureGridFilter
 */
export class CommunityExposureGridFilter {
  /**
   *
   * @type {number}
   * @memberof CommunityExposureGridFilter
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityExposureGridFilter
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityExposureGridFilter
   */
  qcStatusId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityExposureGridFilter
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityExposureGridFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityExposureGridFilter
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityExposureGridFilter
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CommunityExposureGridFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityExposureGridFilter
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityExposureGridFilter
   */
  search: string = undefined as any;

  constructor(data?: Partial<CommunityExposureGridFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CommunityExposureLookupModel
 */
export class CommunityExposureLookupModel {
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof CommunityExposureLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;
  /**
   *
   * @type {Array<StudyModel>}
   * @memberof CommunityExposureLookupModel
   */
  studies?: Array<StudyModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityExposureLookupModel
   */
  gameFormats?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityExposureLookupModel
   */
  qcStatuses?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<CommunityExposureLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CommunityInjuryCollectionLookupModel
 */
export class CommunityInjuryCollectionLookupModel {
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof CommunityInjuryCollectionLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;
  /**
   *
   * @type {Array<StudyModel>}
   * @memberof CommunityInjuryCollectionLookupModel
   */
  studies?: Array<StudyModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityInjuryCollectionLookupModel
   */
  qcStatuses?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<CommunityInjuryCollectionLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CommunityInjuryCollectionModel
 */
export class CommunityInjuryCollectionModel {
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryCollectionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryCollectionModel
   */
  createdById: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryCollectionModel
   */
  playerFirstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryCollectionModel
   */
  playerLastName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryCollectionModel
   */
  reporterFullName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryCollectionModel
   */
  dateOfInjury: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryCollectionModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryCollectionModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryCollectionModel
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryCollectionModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryCollectionModel
   */
  teamOrAgeGroup: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryCollectionModel
   */
  venue: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryCollectionModel
   */
  qcStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryCollectionModel
   */
  qcStatus: string = undefined as any;

  constructor(data?: Partial<CommunityInjuryCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CommunityInjuryCollectionModelGridCollection
 */
export class CommunityInjuryCollectionModelGridCollection {
  /**
   *
   * @type {Array<CommunityInjuryCollectionModel>}
   * @memberof CommunityInjuryCollectionModelGridCollection
   */
  items?: Array<CommunityInjuryCollectionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryCollectionModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryCollectionModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryCollectionModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryCollectionModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<CommunityInjuryCollectionModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CommunityInjuryGridFilter
 */
export class CommunityInjuryGridFilter {
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryGridFilter
   */
  organisationId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryGridFilter
   */
  studyId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryGridFilter
   */
  qcStatusId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryGridFilter
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryGridFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryGridFilter
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryGridFilter
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CommunityInjuryGridFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryGridFilter
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryGridFilter
   */
  search: string = undefined as any;

  constructor(data?: Partial<CommunityInjuryGridFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CommunityInjuryLookupModel
 */
export class CommunityInjuryLookupModel {
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof CommunityInjuryLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityInjuryLookupModel
   */
  sex?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<PlayingPositionLookupModel>}
   * @memberof CommunityInjuryLookupModel
   */
  playingPositions?: Array<PlayingPositionLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityInjuryLookupModel
   */
  missedDays?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityInjuryLookupModel
   */
  missedMatches?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityInjuryLookupModel
   */
  medicalTreatments?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityInjuryLookupModel
   */
  injuryTimes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityInjuryLookupModel
   */
  gameFormats?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityInjuryLookupModel
   */
  surfaceTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityInjuryLookupModel
   */
  injuryOnsets?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityInjuryLookupModel
   */
  injuryOnsetCauses?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof CommunityInjuryLookupModel
   */
  injuryEventCauses?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<InjuryLocationLookupModel>}
   * @memberof CommunityInjuryLookupModel
   */
  injuryLocations?: Array<InjuryLocationLookupModel> | null = undefined as any;

  constructor(data?: Partial<CommunityInjuryLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CommunityInjuryModel
 */
export class CommunityInjuryModel {
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  autoSaveId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  playerFirstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  playerLastName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  dateOfInjury: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  dateOfBirth: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  sexId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  teamOrAgeGroup: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  usualPositionId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  venue: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  dateOfReturnToSchoolOrWork: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  dateOfReturnToMatches: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CommunityInjuryModel
   */
  isCatastrophicInjury: boolean = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  schoolOrWorkMissedDaysId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  trainingMissedDaysId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  missedMatchesId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  medicalTreatmentId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  injuryTimeId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  gameFormatId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  surfaceTypeId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  injuryOnsetId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  injuryOnsetCauseId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  injuryEventCauseId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  injuryLocationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  injuryTypeId: number = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CommunityInjuryModel
   */
  wasWearingHeadgear: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CommunityInjuryModel
   */
  wasWearingPadding: boolean = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  wasResultOfFoulPlayId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  positionAtTimeOfInjuryId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityInjuryModel
   */
  qcStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  qcStatus: string = undefined as any;
  /**
   * This property is added just to make it easier on the client side to handle the dialog box checkbox.
   * @type {boolean}
   * @memberof CommunityInjuryModel
   */
  isReadyForQc: boolean = undefined as any;
  /**
   * This property is added just to make it easier on the client side to handle the dialog box checkbox.
   * @type {boolean}
   * @memberof CommunityInjuryModel
   */
  requireReporterInfo: boolean = undefined as any;
  /**
   * This property is added just to make it easier on the client side to handle the dialog box checkbox.
   * @type {boolean}
   * @memberof CommunityInjuryModel
   */
  isQcComplete: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  reporterComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  analystComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityInjuryModel
   */
  analystNotes: string = undefined as any;

  constructor(data?: Partial<CommunityInjuryModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CommunityMatchExposureModel
 */
export class CommunityMatchExposureModel {
  /**
   *
   * @type {number}
   * @memberof CommunityMatchExposureModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityMatchExposureModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityMatchExposureModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityMatchExposureModel
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityMatchExposureModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityMatchExposureModel
   */
  dateFrom: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityMatchExposureModel
   */
  dateTo: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityMatchExposureModel
   */
  teamName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityMatchExposureModel
   */
  gameFormatId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityMatchExposureModel
   */
  noOfMatchesPlayed?: number | null = undefined as any;
  /**
   * Length of matches in minutes.
   * @type {number}
   * @memberof CommunityMatchExposureModel
   */
  lengthOfMatches?: number | null = undefined as any;
  /**
   * Number of on-field players per team (excluding sanctions and injuries).
   * @type {number}
   * @memberof CommunityMatchExposureModel
   */
  noOfOnFieldPlayers?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityMatchExposureModel
   */
  qcStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityMatchExposureModel
   */
  qcStatus: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CommunityMatchExposureModel
   */
  isReadyForQc: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CommunityMatchExposureModel
   */
  requireReporterInfo: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof CommunityMatchExposureModel
   */
  isQcComplete: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityMatchExposureModel
   */
  reporterComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityMatchExposureModel
   */
  analystComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityMatchExposureModel
   */
  analystNotes: string = undefined as any;

  constructor(data?: Partial<CommunityMatchExposureModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CommunityMatchExposureModelGridCollection
 */
export class CommunityMatchExposureModelGridCollection {
  /**
   *
   * @type {Array<CommunityMatchExposureModel>}
   * @memberof CommunityMatchExposureModelGridCollection
   */
  items?: Array<CommunityMatchExposureModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityMatchExposureModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityMatchExposureModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityMatchExposureModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityMatchExposureModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<CommunityMatchExposureModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CommunityTrainingExposureModel
 */
export class CommunityTrainingExposureModel {
  /**
   *
   * @type {number}
   * @memberof CommunityTrainingExposureModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityTrainingExposureModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityTrainingExposureModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityTrainingExposureModel
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityTrainingExposureModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityTrainingExposureModel
   */
  dateFrom: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityTrainingExposureModel
   */
  dateTo: string = undefined as any;
  /**
   * Teams / Age group(s) present.
   * @type {string}
   * @memberof CommunityTrainingExposureModel
   */
  teamsOrAgeGroups: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityTrainingExposureModel
   */
  noOfTrainingSessions?: number | null = undefined as any;
  /**
   * Avg. length of training sessions in minutes.
   * @type {number}
   * @memberof CommunityTrainingExposureModel
   */
  avgLengthOfTrainingSession?: number | null = undefined as any;
  /**
   * Avg. number of players attending training sessions.
   * @type {number}
   * @memberof CommunityTrainingExposureModel
   */
  avgNoOfPlayersInTrainingSession?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityTrainingExposureModel
   */
  qcStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityTrainingExposureModel
   */
  qcStatus: string = undefined as any;
  /**
   * This property is added just to make it easier on the client side to handle the dialog box checkbox.
   * @type {boolean}
   * @memberof CommunityTrainingExposureModel
   */
  isReadyForQc: boolean = undefined as any;
  /**
   * This property is added just to make it easier on the client side to handle the dialog box checkbox.
   * @type {boolean}
   * @memberof CommunityTrainingExposureModel
   */
  requireReporterInfo: boolean = undefined as any;
  /**
   * This property is added just to make it easier on the client side to handle the dialog box checkbox.
   * @type {boolean}
   * @memberof CommunityTrainingExposureModel
   */
  isQcComplete: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityTrainingExposureModel
   */
  reporterComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityTrainingExposureModel
   */
  analystComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof CommunityTrainingExposureModel
   */
  analystNotes: string = undefined as any;

  constructor(data?: Partial<CommunityTrainingExposureModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CommunityTrainingExposureModelGridCollection
 */
export class CommunityTrainingExposureModelGridCollection {
  /**
   *
   * @type {Array<CommunityTrainingExposureModel>}
   * @memberof CommunityTrainingExposureModelGridCollection
   */
  items?: Array<CommunityTrainingExposureModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityTrainingExposureModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityTrainingExposureModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityTrainingExposureModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CommunityTrainingExposureModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<CommunityTrainingExposureModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ExportEventModel
 */
export class ExportEventModel {
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  dataTypeId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  dataType: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  qcStatusId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  qcStatus: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  reporterComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  analystComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  organisationId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  studyRimsId: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  studyStartDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  studyEndDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  studySex: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  studyId?: number | null = undefined as any;
  /**
   * Event date (contextual).
   * @type {string}
   * @memberof ExportEventModel
   */
  date: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  dateQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isDateQcd?: boolean | null = undefined as any;
  /**
   * Event month of the year (contextual).
   * @type {string}
   * @memberof ExportEventModel
   */
  monthOfTheYear: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  monthOfTheYearQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isMonthOfTheYearQcd?: boolean | null = undefined as any;
  /**
   * Event week of the year (contextual).
   * @type {string}
   * @memberof ExportEventModel
   */
  weekOfTheYear: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  weekOfTheYearQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isWeekOfTheYearQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  tournament: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  playerId?: number | null = undefined as any;
  /**
   * Player first name.
   * @type {string}
   * @memberof ExportEventModel
   */
  firstName: string = undefined as any;
  /**
   * Player last name.
   * @type {string}
   * @memberof ExportEventModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  dateOfBirth: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  ageAtTimeOfEvent: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  ageAtTimeOfEventQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isAgeAtTimeOfEventQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  primaryHand: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  primaryFoot: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  usual15sPlayingPosition: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  usual7sPlayingPosition: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  sex: string = undefined as any;
  /**
   * Height (banded).
   * @type {string}
   * @memberof ExportEventModel
   */
  height: string = undefined as any;
  /**
   * Weight (banded).
   * @type {string}
   * @memberof ExportEventModel
   */
  weight: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  exposureId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  exposureSessionId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  sessionType: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  sessionTypeQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isSessionTypeQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  sessionPosition: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  sessionMinutes?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  noOfPlayers?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  noOfForwardsSessionsOnThatDay?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  noOfBacksSessionsOnThatDay?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  injuryId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  illnessId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  opponent: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  opponentQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isOpponentQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  studyYear?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  hasPlayerReturned?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  hasPlayerReturnedQc?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isHasPlayerReturnedQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  previousInjuryReturnDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  previousInjuryReturnDateQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isPreviousInjuryReturnDateQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  estimatedReturnDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  estimatedReturnDateQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isEstimatedReturnDateQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  actualReturnDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  actualReturnDateQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isActualReturnDateQcd?: boolean | null = undefined as any;
  /**
   * Difference between previous injury date and actual return date in days.
   * @type {number}
   * @memberof ExportEventModel
   */
  injuryReturnDatesDiff?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  injuryReturnDatesDiffQc?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isInjuryReturnDatesDiffQcd?: boolean | null = undefined as any;
  /**
   * Based around the date of return and for how long the player was injured.
   * @type {number}
   * @memberof ExportEventModel
   */
  severity?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  severityQc?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  illnessStart: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  illnessStartQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isIllnessStartQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  activityAtTimeOfInjury: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  activityAtTimeOfInjuryQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isActivityAtTimeOfInjuryQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  eventCausingInjury: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  eventCausingInjuryQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isEventCausingInjuryQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  timeOfInjury: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  timeOfInjuryQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isTimeOfInjuryQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  playingPositionAtTimeOfInjury: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  playingPositionAtTimeOfInjuryQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isPlayingPositionAtTimeOfInjuryQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  playerStartTime: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  playerStartTimeQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isPlayerStartTimeQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  onsetRate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  onsetRateQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isOnsetRateQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  onsetCause: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  onsetCauseQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isOnsetCauseQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  playerRemoval: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  playerRemovalQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isPlayerRemovalQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  bodySide: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  bodySideQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isBodySideQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  priorSymptoms: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  priorSymptomsQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isPriorSymptomsQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  medicalNotes: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  medicalNotesQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isMedicalNotesQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isRecurrence?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isRecurrenceQc?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isIsRecurrenceQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  invasiveProcedureIds: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  invasiveProcedureIdsQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isInvasiveProcedureIdsQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  medicalImagingIds: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  medicalImagingIdsQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isMedicalImagingIdsQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  ipAndMiDetails: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  ipAndMiDetailsQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isIpAndMiDetailsQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  diagnosticsDetails: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  proceduresDetails: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  medicationDetails: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  osicsRegion: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  osicsRegionQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isOsicsRegionQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  osicsParent: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  osicsParentQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isOsicsParentQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  osicsSpecific: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  osicsSpecificQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isOsicsSpecificQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  osicsDetail: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  osicsDetailQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isOsicsDetailQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  osicsCode: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  osicsCodeQc: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  noOfPreviousGamesOnThatDay?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExportEventModel
   */
  noOfPreviousGamesOnThatDayQc?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isNoOfPreviousGamesOnThatDayQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  surfaceType: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExportEventModel
   */
  surfaceTypeQc: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isSurfaceTypeQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  wasWearingHeadgear?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  wasWearingHeadgearQc?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isWasWearingHeadgearQcd?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  wasWearingPadding?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  wasWearingPaddingQc?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExportEventModel
   */
  isWasWearingPaddingQcd?: boolean | null = undefined as any;

  constructor(data?: Partial<ExportEventModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ExposureCalendarModel
 */
export class ExposureCalendarModel {
  /**
   *
   * @type {string}
   * @memberof ExposureCalendarModel
   */
  forDate: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCalendarModel
   */
  noOfForwardsSessions: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCalendarModel
   */
  noOfBacksSessions: number = undefined as any;

  constructor(data?: Partial<ExposureCalendarModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ExposureCalendarModelCalendarDayModel
 */
export class ExposureCalendarModelCalendarDayModel {
  /**
   *
   * @type {string}
   * @memberof ExposureCalendarModelCalendarDayModel
   */
  date: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCalendarModelCalendarDayModel
   */
  dayOfMonth: number = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExposureCalendarModelCalendarDayModel
   */
  isWeekendDay: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExposureCalendarModelCalendarDayModel
   */
  isMainMonthDay: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExposureCalendarModelCalendarDayModel
   */
  isFutureDate: boolean = undefined as any;
  /**
   *
   * @type {ExposureCalendarModel}
   * @memberof ExposureCalendarModelCalendarDayModel
   */
  data: ExposureCalendarModel = undefined as any;

  constructor(data?: Partial<ExposureCalendarModelCalendarDayModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ExposureCalendarModelCalendarModel
 */
export class ExposureCalendarModelCalendarModel {
  /**
   *
   * @type {string}
   * @memberof ExposureCalendarModelCalendarModel
   */
  dataStartDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureCalendarModelCalendarModel
   */
  dataEndDate: string = undefined as any;
  /**
   *
   * @type {Array<Array<ExposureCalendarModelCalendarDayModel>>}
   * @memberof ExposureCalendarModelCalendarModel
   */
  weeks?: Array<Array<ExposureCalendarModelCalendarDayModel>> | null = undefined as any;

  constructor(data?: Partial<ExposureCalendarModelCalendarModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ExposureCollectionModel
 */
export class ExposureCollectionModel {
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureCollectionModel
   */
  forDate: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  sessionTypeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  noOfSessions: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  noOfInjuries: number = undefined as any;
  /**
   * Forwards - No of Players.
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  fwdNoOfPlayers?: number | null = undefined as any;
  /**
   * Forwards - Time (minutes).
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  fwdTime?: number | null = undefined as any;
  /**
   * Backs - No of Players.
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  bckNoOfPlayers?: number | null = undefined as any;
  /**
   * Backs - Time (minutes).
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  bckTime?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureCollectionModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureCollectionModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureCollectionModel
   */
  tournament: string = undefined as any;
  /**
   * Can inline data be saved in the calendar view?
   * @type {boolean}
   * @memberof ExposureCollectionModel
   */
  isSaveable: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureCollectionModel
   */
  metric1: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureCollectionModel
   */
  metric2: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureCollectionModel
   */
  metric3: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  metric1Value?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  metric2Value?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  metric3Value?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModel
   */
  qcStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureCollectionModel
   */
  qcStatus: string = undefined as any;
  /**
   * This property is added just to make it easier on the client side to handle the dialog box checkbox.
   * @type {boolean}
   * @memberof ExposureCollectionModel
   */
  isReadyForQc: boolean = undefined as any;
  /**
   * This property is added just to make it easier on the client side to handle the dialog box checkbox.
   * @type {boolean}
   * @memberof ExposureCollectionModel
   */
  requireReporterInfo: boolean = undefined as any;
  /**
   * This property is added just to make it easier on the client side to handle the dialog box checkbox.
   * @type {boolean}
   * @memberof ExposureCollectionModel
   */
  isQcComplete: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureCollectionModel
   */
  reporterComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureCollectionModel
   */
  analystComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureCollectionModel
   */
  analystNotes: string = undefined as any;

  constructor(data?: Partial<ExposureCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ExposureCollectionModelGridCollection
 */
export class ExposureCollectionModelGridCollection {
  /**
   *
   * @type {Array<ExposureCollectionModel>}
   * @memberof ExposureCollectionModelGridCollection
   */
  items?: Array<ExposureCollectionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureCollectionModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<ExposureCollectionModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ExposureGridFilter
 */
export class ExposureGridFilter {
  /**
   *
   * @type {number}
   * @memberof ExposureGridFilter
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureGridFilter
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureGridFilter
   */
  qcStatusId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureGridFilter
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureGridFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureGridFilter
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureGridFilter
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ExposureGridFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureGridFilter
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureGridFilter
   */
  search: string = undefined as any;

  constructor(data?: Partial<ExposureGridFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ExposureLookupModel
 */
export class ExposureLookupModel {
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof ExposureLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;
  /**
   *
   * @type {Array<StudyModel>}
   * @memberof ExposureLookupModel
   */
  studies?: Array<StudyModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof ExposureLookupModel
   */
  sessionTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof ExposureLookupModel
   */
  sessionPositions?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof ExposureLookupModel
   */
  qcStatuses?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<ExposureLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ExposureModel
 */
export class ExposureModel {
  /**
   *
   * @type {number}
   * @memberof ExposureModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureModel
   */
  autoSaveId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureModel
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureModel
   */
  forDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureModel
   */
  tournament: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureModel
   */
  noOfInjuries: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureModel
   */
  metric1: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureModel
   */
  metric2: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureModel
   */
  metric3: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureModel
   */
  metric1Value?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureModel
   */
  metric2Value?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureModel
   */
  metric3Value?: number | null = undefined as any;
  /**
   *
   * @type {Array<ExposureSessionModel>}
   * @memberof ExposureModel
   */
  sessions?: Array<ExposureSessionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureModel
   */
  qcStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureModel
   */
  reporterComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureModel
   */
  analystComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ExposureModel
   */
  analystNotes: string = undefined as any;

  constructor(data?: Partial<ExposureModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ExposureSessionModel
 */
export class ExposureSessionModel {
  /**
   *
   * @type {number}
   * @memberof ExposureSessionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureSessionModel
   */
  sessionTypeId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureSessionModel
   */
  sessionMinutes: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureSessionModel
   */
  noOfPlayers: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ExposureSessionModel
   */
  sessionPositionId: number = undefined as any;

  constructor(data?: Partial<ExposureSessionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FileDownloadModel
 */
export class FileDownloadModel {
  /**
   *
   * @type {string}
   * @memberof FileDownloadModel
   */
  fileName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FileDownloadModel
   */
  contentType: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FileDownloadModel
   */
  data: string = undefined as any;

  constructor(data?: Partial<FileDownloadModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GridFilter
 */
export class GridFilter {
  /**
   *
   * @type {number}
   * @memberof GridFilter
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GridFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GridFilter
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GridFilter
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof GridFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GridFilter
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GridFilter
   */
  search: string = undefined as any;

  constructor(data?: Partial<GridFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface IllnessCollectionLookupModel
 */
export class IllnessCollectionLookupModel {
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof IllnessCollectionLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;
  /**
   *
   * @type {Array<StudyModel>}
   * @memberof IllnessCollectionLookupModel
   */
  studies?: Array<StudyModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof IllnessCollectionLookupModel
   */
  returnToPlayStatuses?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof IllnessCollectionLookupModel
   */
  qcStatuses?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<IllnessCollectionLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface IllnessCollectionModel
 */
export class IllnessCollectionModel {
  /**
   *
   * @type {number}
   * @memberof IllnessCollectionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessCollectionModel
   */
  playerId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessCollectionModel
   */
  firstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessCollectionModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessCollectionModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessCollectionModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessCollectionModel
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessCollectionModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessCollectionModel
   */
  tournament: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessCollectionModel
   */
  returnToPlayStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessCollectionModel
   */
  returnToPlayStatus: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessCollectionModel
   */
  qcStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessCollectionModel
   */
  qcStatus: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessCollectionModel
   */
  dateOfSymptomPresentation: string = undefined as any;

  constructor(data?: Partial<IllnessCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface IllnessCollectionModelGridCollection
 */
export class IllnessCollectionModelGridCollection {
  /**
   *
   * @type {Array<IllnessCollectionModel>}
   * @memberof IllnessCollectionModelGridCollection
   */
  items?: Array<IllnessCollectionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessCollectionModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessCollectionModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessCollectionModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessCollectionModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<IllnessCollectionModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface IllnessGridFilter
 */
export class IllnessGridFilter {
  /**
   *
   * @type {number}
   * @memberof IllnessGridFilter
   */
  organisationId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessGridFilter
   */
  studyId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessGridFilter
   */
  returnStatusId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessGridFilter
   */
  qcStatusId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessGridFilter
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessGridFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessGridFilter
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessGridFilter
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessGridFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessGridFilter
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessGridFilter
   */
  search: string = undefined as any;

  constructor(data?: Partial<IllnessGridFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface IllnessLookupModel
 */
export class IllnessLookupModel {
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof IllnessLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;
  /**
   *
   * @type {Array<OsicsLookupModel>}
   * @memberof IllnessLookupModel
   */
  osics?: Array<OsicsLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof IllnessLookupModel
   */
  illnessStartTimes?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<IllnessLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface IllnessModel
 */
export class IllnessModel {
  /**
   *
   * @type {number}
   * @memberof IllnessModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessModel
   */
  autoSaveId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessModel
   */
  qcStatusId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessModel
   */
  playerId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  playerFirstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  playerLastName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessModel
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  tournament: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  dateOfSymptomPresentation: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessModel
   */
  osicsRegionId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessModel
   */
  osicsParentId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessModel
   */
  osicsSpecificId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessModel
   */
  osicsDetailId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessModel
   */
  diagnosticsUsed: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  diagnosticsDetails: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessModel
   */
  proceduresUsed: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  proceduresDetails: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessModel
   */
  medicationPrescribed: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  medicationDetails: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessModel
   */
  illnessStartId: number = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessModel
   */
  hasPlayerReturned: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  estimatedReturnDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  actualReturnDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  comment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessModel
   */
  qcComment: string = undefined as any;
  /**
   *
   * @type {IllnessQualityControlStatuses}
   * @memberof IllnessModel
   */
  qcStatuses: IllnessQualityControlStatuses = undefined as any;

  constructor(data?: Partial<IllnessModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface IllnessQualityControlModel
 */
export class IllnessQualityControlModel {
  /**
   * Illness Quality Control Id.
   * @type {number}
   * @memberof IllnessQualityControlModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessQualityControlModel
   */
  forIllnessReportId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessQualityControlModel
   */
  qcStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessQualityControlModel
   */
  comment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessQualityControlModel
   */
  notes: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessQualityControlModel
   */
  tournament: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessQualityControlModel
   */
  dateOfSymptomPresentation: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessQualityControlModel
   */
  osicsRegionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessQualityControlModel
   */
  osicsParentId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessQualityControlModel
   */
  osicsSpecificId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessQualityControlModel
   */
  osicsDetailId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlModel
   */
  diagnosticsUsed?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessQualityControlModel
   */
  diagnosticsDetails: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlModel
   */
  proceduresUsed?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessQualityControlModel
   */
  proceduresDetails: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlModel
   */
  medicationPrescribed?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessQualityControlModel
   */
  medicationDetails: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof IllnessQualityControlModel
   */
  illnessStartId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlModel
   */
  hasPlayerReturned?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessQualityControlModel
   */
  estimatedReturnDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof IllnessQualityControlModel
   */
  actualReturnDate: string = undefined as any;
  /**
   *
   * @type {IllnessQualityControlStatuses}
   * @memberof IllnessQualityControlModel
   */
  qcStatuses: IllnessQualityControlStatuses = undefined as any;

  constructor(data?: Partial<IllnessQualityControlModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface IllnessQualityControlResponse
 */
export class IllnessQualityControlResponse {
  /**
   *
   * @type {IllnessModel}
   * @memberof IllnessQualityControlResponse
   */
  report: IllnessModel = undefined as any;
  /**
   *
   * @type {IllnessQualityControlModel}
   * @memberof IllnessQualityControlResponse
   */
  qualityControl: IllnessQualityControlModel = undefined as any;

  constructor(data?: Partial<IllnessQualityControlResponse>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface IllnessQualityControlStatuses
 */
export class IllnessQualityControlStatuses {
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isTournamentQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isDateOfSymptomPresentationQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isOsicsRegionQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isOsicsParentQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isOsicsSpecificQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isOsicsDetailQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isDiagnosticsUsedQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isDiagnosticsDetailsQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isProceduresUsedQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isProceduresDetailsQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isMedicationPrescribedQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isMedicationDetailsQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isIllnessStartQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isHasPlayerReturnedQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isEstimatedReturnDateQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof IllnessQualityControlStatuses
   */
  isActualReturnDateQcd: boolean = undefined as any;

  constructor(data?: Partial<IllnessQualityControlStatuses>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InjuryCollectionLookupModel
 */
export class InjuryCollectionLookupModel {
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof InjuryCollectionLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;
  /**
   *
   * @type {Array<StudyModel>}
   * @memberof InjuryCollectionLookupModel
   */
  studies?: Array<StudyModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryCollectionLookupModel
   */
  returnToPlayStatuses?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryCollectionLookupModel
   */
  qcStatuses?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<InjuryCollectionLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InjuryCollectionModel
 */
export class InjuryCollectionModel {
  /**
   *
   * @type {number}
   * @memberof InjuryCollectionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryCollectionModel
   */
  playerId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryCollectionModel
   */
  firstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryCollectionModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryCollectionModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryCollectionModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryCollectionModel
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryCollectionModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryCollectionModel
   */
  tournament: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryCollectionModel
   */
  returnToPlayStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryCollectionModel
   */
  returnToPlayStatus: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryCollectionModel
   */
  qcStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryCollectionModel
   */
  qcStatus: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryCollectionModel
   */
  dateOfInjury: string = undefined as any;

  constructor(data?: Partial<InjuryCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InjuryCollectionModelGridCollection
 */
export class InjuryCollectionModelGridCollection {
  /**
   *
   * @type {Array<InjuryCollectionModel>}
   * @memberof InjuryCollectionModelGridCollection
   */
  items?: Array<InjuryCollectionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryCollectionModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryCollectionModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryCollectionModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryCollectionModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<InjuryCollectionModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InjuryGridFilter
 */
export class InjuryGridFilter {
  /**
   *
   * @type {number}
   * @memberof InjuryGridFilter
   */
  organisationId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryGridFilter
   */
  studyId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryGridFilter
   */
  returnStatusId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryGridFilter
   */
  qcStatusId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryGridFilter
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryGridFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryGridFilter
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryGridFilter
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryGridFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryGridFilter
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryGridFilter
   */
  search: string = undefined as any;

  constructor(data?: Partial<InjuryGridFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InjuryLocationLookupModel
 */
export class InjuryLocationLookupModel {
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryLocationLookupModel
   */
  injuryTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryLocationLookupModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryLocationLookupModel
   */
  text: string = undefined as any;

  constructor(data?: Partial<InjuryLocationLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InjuryLookupModel
 */
export class InjuryLookupModel {
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof InjuryLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryLookupModel
   */
  activityTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<InjuryTimesLookup>}
   * @memberof InjuryLookupModel
   */
  injuryTimes?: Array<InjuryTimesLookup> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryLookupModel
   */
  sessionTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<PlayingPositionLookupModel>}
   * @memberof InjuryLookupModel
   */
  playingPositions?: Array<PlayingPositionLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryLookupModel
   */
  playerStatuses?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryLookupModel
   */
  injuryEventCauses?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryLookupModel
   */
  onsets?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryLookupModel
   */
  onsetCauses?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryLookupModel
   */
  playerRemovalTimes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryLookupModel
   */
  injurySides?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<OsicsLookupModel>}
   * @memberof InjuryLookupModel
   */
  osics?: Array<OsicsLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryLookupModel
   */
  medicalImagingTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryLookupModel
   */
  invasiveProcedureTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof InjuryLookupModel
   */
  surfaceTypes?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<InjuryLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InjuryModel
 */
export class InjuryModel {
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  autoSaveId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  qcStatusId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  playerId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  playerFirstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  playerLastName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  dateOfInjury: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  previousInjuryReturnDate: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  injuryActivityTypeId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  opponent: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  tournament: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  injuryTimeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  playingPositionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  injuryPlayerStatusId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  sessionTypeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  injuryEventCauseId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  injuryOnsetId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  injuryOnsetCauseId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  injuryPlayerRemovalTimeId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  injurySideId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  osicsRegionId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  osicsParentId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  osicsSpecificId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  osicsDetailId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryModel
   */
  isRecurrence: boolean = undefined as any;
  /**
   *
   * @type {Array<number>}
   * @memberof InjuryModel
   */
  medicalImagingTypeIds?: Array<number> | null = undefined as any;
  /**
   *
   * @type {Array<number>}
   * @memberof InjuryModel
   */
  invasiveProcedureTypeIds?: Array<number> | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  medicalNotes: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryModel
   */
  hasPlayerReturned: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  estimatedReturnDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  actualReturnDate: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  noOfPreviousGamesOnThatDay?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryModel
   */
  surfaceTypeId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryModel
   */
  wasWearingHeadgear?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryModel
   */
  wasWearingPadding?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  comment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryModel
   */
  qcComment: string = undefined as any;
  /**
   *
   * @type {InjuryQualityControlStatuses}
   * @memberof InjuryModel
   */
  qcStatuses: InjuryQualityControlStatuses = undefined as any;

  constructor(data?: Partial<InjuryModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InjuryQualityControlModel
 */
export class InjuryQualityControlModel {
  /**
   * Injury Quality Control Id.
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  forInjuryReportId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  qcStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryQualityControlModel
   */
  comment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryQualityControlModel
   */
  notes: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryQualityControlModel
   */
  dateOfInjury: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryQualityControlModel
   */
  previousInjuryReturnDate: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  injuryActivityTypeId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryQualityControlModel
   */
  opponent: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryQualityControlModel
   */
  tournament: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  injuryTimeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  playingPositionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  injuryPlayerStatusId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  sessionTypeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  injuryEventCauseId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  injuryOnsetId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  injuryOnsetCauseId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  injuryPlayerRemovalTimeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  injurySideId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  osicsRegionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  osicsParentId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  osicsSpecificId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  osicsDetailId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlModel
   */
  isRecurrence?: boolean | null = undefined as any;
  /**
   *
   * @type {Array<number>}
   * @memberof InjuryQualityControlModel
   */
  medicalImagingTypeIds?: Array<number> | null = undefined as any;
  /**
   *
   * @type {Array<number>}
   * @memberof InjuryQualityControlModel
   */
  invasiveProcedureTypeIds?: Array<number> | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryQualityControlModel
   */
  medicalNotes: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlModel
   */
  hasPlayerReturned?: boolean | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryQualityControlModel
   */
  estimatedReturnDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryQualityControlModel
   */
  actualReturnDate: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  noOfPreviousGamesOnThatDay?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryQualityControlModel
   */
  surfaceTypeId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlModel
   */
  wasWearingHeadgear?: boolean | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlModel
   */
  wasWearingPadding?: boolean | null = undefined as any;
  /**
   *
   * @type {InjuryQualityControlStatuses}
   * @memberof InjuryQualityControlModel
   */
  qcStatuses: InjuryQualityControlStatuses = undefined as any;

  constructor(data?: Partial<InjuryQualityControlModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InjuryQualityControlResponse
 */
export class InjuryQualityControlResponse {
  /**
   *
   * @type {InjuryModel}
   * @memberof InjuryQualityControlResponse
   */
  report: InjuryModel = undefined as any;
  /**
   *
   * @type {InjuryQualityControlModel}
   * @memberof InjuryQualityControlResponse
   */
  qualityControl: InjuryQualityControlModel = undefined as any;

  constructor(data?: Partial<InjuryQualityControlResponse>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InjuryQualityControlStatuses
 */
export class InjuryQualityControlStatuses {
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isDateOfInjuryQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isPreviousInjuryReturnDateQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isInjuryActivityTypeQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isOpponentQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isTournamentQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isInjuryTimeQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isPlayingPositionQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isInjuryPlayerStatusQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isSessionTypeQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isInjuryEventCauseQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isInjuryOnsetQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isInjuryOnsetCauseQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isInjuryPlayerRemovalTimeQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isInjurySideQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isOsicsRegionQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isOsicsParentQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isOsicsSpecificQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isOsicsDetailQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isIsRecurrenceQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isMedicalImagingTypesQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isInvasiveProcedureTypesQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isMedicalNotesQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isHasPlayerReturnedQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isEstimatedReturnDateQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isActualReturnDateQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isNoOfPreviousGamesOnThatDayQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isSurfaceTypeQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isWasWearingHeadgearQcd: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof InjuryQualityControlStatuses
   */
  isWasWearingPaddingQcd: boolean = undefined as any;

  constructor(data?: Partial<InjuryQualityControlStatuses>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InjuryTimesLookup
 */
export class InjuryTimesLookup {
  /**
   *
   * @type {number}
   * @memberof InjuryTimesLookup
   */
  gameFormatId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof InjuryTimesLookup
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof InjuryTimesLookup
   */
  text: string = undefined as any;

  constructor(data?: Partial<InjuryTimesLookup>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MedicalAdminRolePermissionsModel
 */
export class MedicalAdminRolePermissionsModel {
  /**
   *
   * @type {number}
   * @memberof MedicalAdminRolePermissionsModel
   */
  userId: number = undefined as any;
  /**
   * MedicalAdmin organisation ids - only for aspNetUsers with MedicalAdmin role id.
   * @type {Array<number>}
   * @memberof MedicalAdminRolePermissionsModel
   */
  medicalAdminOrganisationIds?: Array<number> | null = undefined as any;

  constructor(data?: Partial<MedicalAdminRolePermissionsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MergeAndDeduplicateModel
 */
export class MergeAndDeduplicateModel {
  /**
   * Id of the player to keep and to merge the other player into.
   * @type {number}
   * @memberof MergeAndDeduplicateModel
   */
  keepPlayerId: number = undefined as any;
  /**
   * Id of the player to merge into the other player, this player will be deleted.
   * @type {number}
   * @memberof MergeAndDeduplicateModel
   */
  deletePlayerId: number = undefined as any;

  constructor(data?: Partial<MergeAndDeduplicateModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface OrganisationCollectionFilter
 */
export class OrganisationCollectionFilter {
  /**
   *
   * @type {number}
   * @memberof OrganisationCollectionFilter
   */
  studyTypeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationCollectionFilter
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationCollectionFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationCollectionFilter
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationCollectionFilter
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof OrganisationCollectionFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationCollectionFilter
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationCollectionFilter
   */
  search: string = undefined as any;

  constructor(data?: Partial<OrganisationCollectionFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface OrganisationCollectionModel
 */
export class OrganisationCollectionModel {
  /**
   *
   * @type {number}
   * @memberof OrganisationCollectionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationCollectionModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationCollectionModel
   */
  studyType: string = undefined as any;

  constructor(data?: Partial<OrganisationCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface OrganisationCollectionModelGridCollection
 */
export class OrganisationCollectionModelGridCollection {
  /**
   *
   * @type {Array<OrganisationCollectionModel>}
   * @memberof OrganisationCollectionModelGridCollection
   */
  items?: Array<OrganisationCollectionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationCollectionModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationCollectionModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationCollectionModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationCollectionModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<OrganisationCollectionModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface OrganisationLookupModel
 */
export class OrganisationLookupModel {
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof OrganisationLookupModel
   */
  studyTypes?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<OrganisationLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface OrganisationModel
 */
export class OrganisationModel {
  /**
   *
   * @type {number}
   * @memberof OrganisationModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OrganisationModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof OrganisationModel
   */
  studyTypeId: number = undefined as any;
  /**
   *
   * @type {Array<StudyModel>}
   * @memberof OrganisationModel
   */
  studies?: Array<StudyModel> | null = undefined as any;

  constructor(data?: Partial<OrganisationModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface OsicsLookupModel
 */
export class OsicsLookupModel {
  /**
   *
   * @type {number}
   * @memberof OsicsLookupModel
   */
  id?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OsicsLookupModel
   */
  text: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof OsicsLookupModel
   */
  code: string = undefined as any;
  /**
   *
   * @type {Array<OsicsLookupModel>}
   * @memberof OsicsLookupModel
   */
  children?: Array<OsicsLookupModel> | null = undefined as any;

  constructor(data?: Partial<OsicsLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PlayerCollectionModel
 */
export class PlayerCollectionModel {
  /**
   *
   * @type {number}
   * @memberof PlayerCollectionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof PlayerCollectionModel
   */
  firstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof PlayerCollectionModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof PlayerCollectionModel
   */
  dateOfBirth: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof PlayerCollectionModel
   */
  dateCreated: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerCollectionModel
   */
  injuryCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerCollectionModel
   */
  illnessCount: number = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof PlayerCollectionModel
   */
  isFavourite: boolean = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerCollectionModel
   */
  weight?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerCollectionModel
   */
  height?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof PlayerCollectionModel
   */
  organisation: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof PlayerCollectionModel
   */
  study: string = undefined as any;

  constructor(data?: Partial<PlayerCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PlayerCollectionModelGridCollection
 */
export class PlayerCollectionModelGridCollection {
  /**
   *
   * @type {Array<PlayerCollectionModel>}
   * @memberof PlayerCollectionModelGridCollection
   */
  items?: Array<PlayerCollectionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerCollectionModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerCollectionModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerCollectionModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerCollectionModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<PlayerCollectionModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PlayerGridFilter
 */
export class PlayerGridFilter {
  /**
   * Player group -> favourites / all.
   * @type {string}
   * @memberof PlayerGridFilter
   */
  group: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerGridFilter
   */
  organisationId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerGridFilter
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerGridFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof PlayerGridFilter
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof PlayerGridFilter
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof PlayerGridFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerGridFilter
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof PlayerGridFilter
   */
  search: string = undefined as any;

  constructor(data?: Partial<PlayerGridFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PlayerGridLookupModel
 */
export class PlayerGridLookupModel {
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof PlayerGridLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;

  constructor(data?: Partial<PlayerGridLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PlayerImportDuplicateModel
 */
export class PlayerImportDuplicateModel {
  /**
   *
   * @type {number}
   * @memberof PlayerImportDuplicateModel
   */
  userId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof PlayerImportDuplicateModel
   */
  fullName: string = undefined as any;

  constructor(data?: Partial<PlayerImportDuplicateModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PlayerImportLookupModel
 */
export class PlayerImportLookupModel {
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof PlayerImportLookupModel
   */
  bodySides?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<PlayingPositionLookupModel>}
   * @memberof PlayerImportLookupModel
   */
  playingPositions?: Array<PlayingPositionLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof PlayerImportLookupModel
   */
  sex?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<StudyModel>}
   * @memberof PlayerImportLookupModel
   */
  studies?: Array<StudyModel> | null = undefined as any;

  constructor(data?: Partial<PlayerImportLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PlayerImportModel
 */
export class PlayerImportModel {
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof PlayerImportModel
   */
  modelState?: { [key: string]: Array<string> } | null = undefined as any;
  /**
   *
   * @type {PlayerImportDuplicateModel}
   * @memberof PlayerImportModel
   */
  potentialDuplicate: PlayerImportDuplicateModel = undefined as any;
  /**
   * Is this player ready to be imported by the submit form / table?  If no validation errors have been found and not a potential  duplicate record, mark this as true by default.
   * @type {boolean}
   * @memberof PlayerImportModel
   */
  shouldImport: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof PlayerImportModel
   */
  isImportSuccessful?: boolean | null = undefined as any;
  /**
   * Player Id.
   * @type {number}
   * @memberof PlayerImportModel
   */
  id: number = undefined as any;
  /**
   * First name.
   * @type {string}
   * @memberof PlayerImportModel
   */
  firstName: string = undefined as any;
  /**
   * Last name.
   * @type {string}
   * @memberof PlayerImportModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerImportModel
   */
  playerOrganisationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerImportModel
   */
  playerStudyId?: number | null = undefined as any;
  /**
   * Player height.
   * @type {number}
   * @memberof PlayerImportModel
   */
  height: number = undefined as any;
  /**
   * Player weight.
   * @type {number}
   * @memberof PlayerImportModel
   */
  weight: number = undefined as any;
  /**
   * Date of user creation (registration date).
   * @type {string}
   * @memberof PlayerImportModel
   */
  dateOfBirth: string = undefined as any;
  /**
   * Player primary hand side id.
   * @type {number}
   * @memberof PlayerImportModel
   */
  primaryHandSideId: number = undefined as any;
  /**
   * Player primary foot side id.
   * @type {number}
   * @memberof PlayerImportModel
   */
  primaryFootSideId: number = undefined as any;
  /**
   * Player usual 15s playing position id.
   * @type {number}
   * @memberof PlayerImportModel
   */
  usual15sPositionId?: number | null = undefined as any;
  /**
   * Player usual 7s playing position id.
   * @type {number}
   * @memberof PlayerImportModel
   */
  usual7sPositionId?: number | null = undefined as any;
  /**
   * Player sex id.
   * @type {number}
   * @memberof PlayerImportModel
   */
  sexId: number = undefined as any;

  constructor(data?: Partial<PlayerImportModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PlayerLookupModel
 */
export class PlayerLookupModel {
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof PlayerLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof PlayerLookupModel
   */
  bodySides?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<PlayingPositionLookupModel>}
   * @memberof PlayerLookupModel
   */
  playingPositions?: Array<PlayingPositionLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof PlayerLookupModel
   */
  sex?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<PlayerLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PlayerModel
 */
export class PlayerModel {
  /**
   * Player Id.
   * @type {number}
   * @memberof PlayerModel
   */
  id: number = undefined as any;
  /**
   * First name.
   * @type {string}
   * @memberof PlayerModel
   */
  firstName: string = undefined as any;
  /**
   * Last name.
   * @type {string}
   * @memberof PlayerModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerModel
   */
  playerOrganisationId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayerModel
   */
  playerStudyId?: number | null = undefined as any;
  /**
   * Player height.
   * @type {number}
   * @memberof PlayerModel
   */
  height: number = undefined as any;
  /**
   * Player weight.
   * @type {number}
   * @memberof PlayerModel
   */
  weight: number = undefined as any;
  /**
   * Date of user creation (registration date).
   * @type {string}
   * @memberof PlayerModel
   */
  dateOfBirth: string = undefined as any;
  /**
   * Player primary hand side id.
   * @type {number}
   * @memberof PlayerModel
   */
  primaryHandSideId: number = undefined as any;
  /**
   * Player primary foot side id.
   * @type {number}
   * @memberof PlayerModel
   */
  primaryFootSideId: number = undefined as any;
  /**
   * Player usual 15s playing position id.
   * @type {number}
   * @memberof PlayerModel
   */
  usual15sPositionId?: number | null = undefined as any;
  /**
   * Player usual 7s playing position id.
   * @type {number}
   * @memberof PlayerModel
   */
  usual7sPositionId?: number | null = undefined as any;
  /**
   * Player sex id.
   * @type {number}
   * @memberof PlayerModel
   */
  sexId: number = undefined as any;

  constructor(data?: Partial<PlayerModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PlayingPositionLookupModel
 */
export class PlayingPositionLookupModel {
  /**
   *
   * @type {Array<number>}
   * @memberof PlayingPositionLookupModel
   */
  gameFormatIds?: Array<number> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof PlayingPositionLookupModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof PlayingPositionLookupModel
   */
  text: string = undefined as any;

  constructor(data?: Partial<PlayingPositionLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterAccessRequestCollectionModel
 */
export class ReporterAccessRequestCollectionModel {
  /**
   * Reporter request id.
   * @type {number}
   * @memberof ReporterAccessRequestCollectionModel
   */
  requestId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestCollectionModel
   */
  firstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestCollectionModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestCollectionModel
   */
  email: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestCollectionModel
   */
  requestedAt: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ReporterAccessRequestCollectionModel
   */
  isSpamChecked: boolean = undefined as any;
  /**
   * Overall status of the request.
   * @type {number}
   * @memberof ReporterAccessRequestCollectionModel
   */
  statusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestCollectionModel
   */
  statusText: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestCollectionModel
   */
  reporterUserId?: number | null = undefined as any;
  /**
   *
   * @type {Array<ReporterOrganisationAccessRequestCollectionModel>}
   * @memberof ReporterAccessRequestCollectionModel
   */
  organisations?: Array<ReporterOrganisationAccessRequestCollectionModel> | null = undefined as any;

  constructor(data?: Partial<ReporterAccessRequestCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterAccessRequestCollectionModelGridCollection
 */
export class ReporterAccessRequestCollectionModelGridCollection {
  /**
   *
   * @type {Array<ReporterAccessRequestCollectionModel>}
   * @memberof ReporterAccessRequestCollectionModelGridCollection
   */
  items?: Array<ReporterAccessRequestCollectionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestCollectionModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestCollectionModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestCollectionModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestCollectionModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<ReporterAccessRequestCollectionModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterAccessRequestGridFilter
 */
export class ReporterAccessRequestGridFilter {
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestGridFilter
   */
  organisationId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestGridFilter
   */
  statusId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestGridFilter
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestGridFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestGridFilter
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestGridFilter
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ReporterAccessRequestGridFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestGridFilter
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestGridFilter
   */
  search: string = undefined as any;

  constructor(data?: Partial<ReporterAccessRequestGridFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterAccessRequestInviteModel
 */
export class ReporterAccessRequestInviteModel {
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestInviteModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestInviteModel
   */
  email: string = undefined as any;

  constructor(data?: Partial<ReporterAccessRequestInviteModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterAccessRequestLookupModel
 */
export class ReporterAccessRequestLookupModel {
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestLookupModel
   */
  preselectedOrganisationId?: number | null = undefined as any;
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof ReporterAccessRequestLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;

  constructor(data?: Partial<ReporterAccessRequestLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterAccessRequestModel
 */
export class ReporterAccessRequestModel {
  /**
   * Reporter request id.
   * @type {number}
   * @memberof ReporterAccessRequestModel
   */
  requestId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestModel
   */
  inviteKey: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestModel
   */
  firstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestModel
   */
  email: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestModel
   */
  phoneNumber: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ReporterAccessRequestModel
   */
  isSpamChecked: boolean = undefined as any;
  /**
   * Overall status of the request.
   * @type {number}
   * @memberof ReporterAccessRequestModel
   */
  statusId: number = undefined as any;
  /**
   *
   * @type {Array<ReporterAccessRequestOrganisationPermissionsModel>}
   * @memberof ReporterAccessRequestModel
   */
  reporterOrganisations?: Array<ReporterAccessRequestOrganisationPermissionsModel> | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestModel
   */
  captchaSolution: string = undefined as any;

  constructor(data?: Partial<ReporterAccessRequestModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterAccessRequestOrganisationPermissionsModel
 */
export class ReporterAccessRequestOrganisationPermissionsModel {
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  organisationRequestId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  reporterComment: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  medicalAdminComment: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  isMedicalAdminApproved: boolean = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  studyTypeId: number = undefined as any;
  /**
   * Has access to Men\'s data within an organisation (for elite study type)?
   * @type {boolean}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  allowMaleData: boolean = undefined as any;
  /**
   * Has access to Women\'s data within an organisation (for elite study type)?
   * @type {boolean}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  allowFemaleData: boolean = undefined as any;
  /**
   * Has access to Injury and Illness data (for elite study type)?
   * @type {boolean}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  allowInjuryAndIllnessData: boolean = undefined as any;
  /**
   * Has access to Exposure data (for elite study type)?
   * @type {boolean}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  allowExposureData: boolean = undefined as any;
  /**
   * Has access to catastrophic data?
   * @type {boolean}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  allowCatastrophicData: boolean = undefined as any;
  /**
   *
   * @type {Array<ReporterOrganisationStudyPermissionsModel>}
   * @memberof ReporterAccessRequestOrganisationPermissionsModel
   */
  studies?: Array<ReporterOrganisationStudyPermissionsModel> | null = undefined as any;

  constructor(data?: Partial<ReporterAccessRequestOrganisationPermissionsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterAccessRequestRejectionModel
 */
export class ReporterAccessRequestRejectionModel {
  /**
   *
   * @type {number}
   * @memberof ReporterAccessRequestRejectionModel
   */
  requestId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterAccessRequestRejectionModel
   */
  rejectionReason: string = undefined as any;

  constructor(data?: Partial<ReporterAccessRequestRejectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterOrganisationAccessRequestCollectionModel
 */
export class ReporterOrganisationAccessRequestCollectionModel {
  /**
   *
   * @type {number}
   * @memberof ReporterOrganisationAccessRequestCollectionModel
   */
  organisationRequestId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterOrganisationAccessRequestCollectionModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterOrganisationAccessRequestCollectionModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ReporterOrganisationAccessRequestCollectionModel
   */
  isMedicalAdminApproved: boolean = undefined as any;

  constructor(data?: Partial<ReporterOrganisationAccessRequestCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterOrganisationAccessRequestModel
 */
export class ReporterOrganisationAccessRequestModel {
  /**
   *
   * @type {number}
   * @memberof ReporterOrganisationAccessRequestModel
   */
  organisationRequestId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterOrganisationAccessRequestModel
   */
  firstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterOrganisationAccessRequestModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterOrganisationAccessRequestModel
   */
  email: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterOrganisationAccessRequestModel
   */
  phoneNumber: string = undefined as any;
  /**
   * Overall status of the request.
   * @type {number}
   * @memberof ReporterOrganisationAccessRequestModel
   */
  statusId: number = undefined as any;
  /**
   *
   * @type {ReporterOrganisationPermissionsModel}
   * @memberof ReporterOrganisationAccessRequestModel
   */
  currentOrganisationPermissions: ReporterOrganisationPermissionsModel = undefined as any;
  /**
   *
   * @type {ReporterAccessRequestOrganisationPermissionsModel}
   * @memberof ReporterOrganisationAccessRequestModel
   */
  requestOrganisationPermissions: ReporterAccessRequestOrganisationPermissionsModel = undefined as any;

  constructor(data?: Partial<ReporterOrganisationAccessRequestModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterOrganisationAccessRequestRejectionModel
 */
export class ReporterOrganisationAccessRequestRejectionModel {
  /**
   *
   * @type {number}
   * @memberof ReporterOrganisationAccessRequestRejectionModel
   */
  organisationRequestId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterOrganisationAccessRequestRejectionModel
   */
  rejectionReason: string = undefined as any;

  constructor(data?: Partial<ReporterOrganisationAccessRequestRejectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterOrganisationPermissionsModel
 */
export class ReporterOrganisationPermissionsModel {
  /**
   *
   * @type {number}
   * @memberof ReporterOrganisationPermissionsModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterOrganisationPermissionsModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof ReporterOrganisationPermissionsModel
   */
  studyTypeId: number = undefined as any;
  /**
   * Has access to Men\'s data within an organisation (for elite study type)?
   * @type {boolean}
   * @memberof ReporterOrganisationPermissionsModel
   */
  allowMaleData: boolean = undefined as any;
  /**
   * Has access to Women\'s data within an organisation (for elite study type)?
   * @type {boolean}
   * @memberof ReporterOrganisationPermissionsModel
   */
  allowFemaleData: boolean = undefined as any;
  /**
   * Has access to Injury and Illness data (for elite study type)?
   * @type {boolean}
   * @memberof ReporterOrganisationPermissionsModel
   */
  allowInjuryAndIllnessData: boolean = undefined as any;
  /**
   * Has access to Exposure data (for elite study type)?
   * @type {boolean}
   * @memberof ReporterOrganisationPermissionsModel
   */
  allowExposureData: boolean = undefined as any;
  /**
   * Has access to catastrophic data?
   * @type {boolean}
   * @memberof ReporterOrganisationPermissionsModel
   */
  allowCatastrophicData: boolean = undefined as any;
  /**
   *
   * @type {Array<ReporterOrganisationStudyPermissionsModel>}
   * @memberof ReporterOrganisationPermissionsModel
   */
  studies?: Array<ReporterOrganisationStudyPermissionsModel> | null = undefined as any;

  constructor(data?: Partial<ReporterOrganisationPermissionsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterOrganisationStudyPermissionsModel
 */
export class ReporterOrganisationStudyPermissionsModel {
  /**
   *
   * @type {number}
   * @memberof ReporterOrganisationStudyPermissionsModel
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterOrganisationStudyPermissionsModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterOrganisationStudyPermissionsModel
   */
  validFrom: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ReporterOrganisationStudyPermissionsModel
   */
  validUntil: string = undefined as any;

  constructor(data?: Partial<ReporterOrganisationStudyPermissionsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterProfileLookupModel
 */
export class ReporterProfileLookupModel {
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof ReporterProfileLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;

  constructor(data?: Partial<ReporterProfileLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ReporterRolePermissionsModel
 */
export class ReporterRolePermissionsModel {
  /**
   *
   * @type {number}
   * @memberof ReporterRolePermissionsModel
   */
  userId: number = undefined as any;
  /**
   * Reporter organisations - only for aspNetUsers with Reporter role id.
   * @type {Array<ReporterOrganisationPermissionsModel>}
   * @memberof ReporterRolePermissionsModel
   */
  reporterOrganisations?: Array<ReporterOrganisationPermissionsModel> | null = undefined as any;

  constructor(data?: Partial<ReporterRolePermissionsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SimpleLookupModel
 */
export class SimpleLookupModel {
  /**
   *
   * @type {number}
   * @memberof SimpleLookupModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SimpleLookupModel
   */
  text: string = undefined as any;

  constructor(data?: Partial<SimpleLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface StudyAdminRolePermissionsModel
 */
export class StudyAdminRolePermissionsModel {
  /**
   *
   * @type {number}
   * @memberof StudyAdminRolePermissionsModel
   */
  userId: number = undefined as any;
  /**
   * StudyAdmin study ids - only for aspNetUsers with Study role id.
   * @type {Array<number>}
   * @memberof StudyAdminRolePermissionsModel
   */
  studyAdminStudyIds?: Array<number> | null = undefined as any;

  constructor(data?: Partial<StudyAdminRolePermissionsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface StudyCollectionFilter
 */
export class StudyCollectionFilter {
  /**
   *
   * @type {number}
   * @memberof StudyCollectionFilter
   */
  studyTypeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof StudyCollectionFilter
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof StudyCollectionFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyCollectionFilter
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyCollectionFilter
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof StudyCollectionFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof StudyCollectionFilter
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyCollectionFilter
   */
  search: string = undefined as any;

  constructor(data?: Partial<StudyCollectionFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface StudyCollectionModel
 */
export class StudyCollectionModel {
  /**
   *
   * @type {number}
   * @memberof StudyCollectionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyCollectionModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyCollectionModel
   */
  format: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyCollectionModel
   */
  studyType: string = undefined as any;
  /**
   *
   * @type {Array<StudyGuidanceFileModel>}
   * @memberof StudyCollectionModel
   */
  guidances?: Array<StudyGuidanceFileModel> | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyCollectionModel
   */
  startDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyCollectionModel
   */
  endDate: string = undefined as any;

  constructor(data?: Partial<StudyCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface StudyCollectionModelGridCollection
 */
export class StudyCollectionModelGridCollection {
  /**
   *
   * @type {Array<StudyCollectionModel>}
   * @memberof StudyCollectionModelGridCollection
   */
  items?: Array<StudyCollectionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof StudyCollectionModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof StudyCollectionModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof StudyCollectionModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof StudyCollectionModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<StudyCollectionModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface StudyGuidanceFileModel
 */
export class StudyGuidanceFileModel {
  /**
   *
   * @type {number}
   * @memberof StudyGuidanceFileModel
   */
  studyId: number = undefined as any;
  /**
   * File name that will show up in the sidebar. Needs to be limited in length.
   * @type {string}
   * @memberof StudyGuidanceFileModel
   */
  sidebarLabel: string = undefined as any;
  /**
   * File locale (i.e. en-IE, en-US, fr-FR, etc.).
   * @type {string}
   * @memberof StudyGuidanceFileModel
   */
  locale: string = undefined as any;
  /**
   * File name.
   * @type {string}
   * @memberof StudyGuidanceFileModel
   */
  fileName: string = undefined as any;
  /**
   * Base64 encoded file payload.
   * @type {string}
   * @memberof StudyGuidanceFileModel
   */
  base64File: string = undefined as any;
  /**
   * Azure file url.
   * @type {string}
   * @memberof StudyGuidanceFileModel
   */
  fileUrl: string = undefined as any;

  constructor(data?: Partial<StudyGuidanceFileModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface StudyLookupModel
 */
export class StudyLookupModel {
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof StudyLookupModel
   */
  gameFormats?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof StudyLookupModel
   */
  studyTypes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof StudyLookupModel
   */
  sex?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<StudyLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface StudyModel
 */
export class StudyModel {
  /**
   *
   * @type {number}
   * @memberof StudyModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyModel
   */
  rimsId: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof StudyModel
   */
  studyTypeId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof StudyModel
   */
  gameFormatId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof StudyModel
   */
  sexId: number = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof StudyModel
   */
  isReporterExportable: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof StudyModel
   */
  isWryStudy: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof StudyModel
   */
  isArchivalInitiated: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof StudyModel
   */
  isScheduledForArchival: boolean = undefined as any;
  /**
   *
   * @type {number}
   * @memberof StudyModel
   */
  archiver1Id?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyModel
   */
  archiveOn: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyModel
   */
  exposureMetric1: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyModel
   */
  exposureMetric2: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyModel
   */
  exposureMetric3: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyModel
   */
  startDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof StudyModel
   */
  endDate: string = undefined as any;
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof StudyModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;

  constructor(data?: Partial<StudyModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SystemUserExportModel
 */
export class SystemUserExportModel {
  /**
   *
   * @type {number}
   * @memberof SystemUserExportModel
   */
  userId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SystemUserExportModel
   */
  firstName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SystemUserExportModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SystemUserExportModel
   */
  email: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SystemUserExportModel
   */
  phone: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SystemUserExportModel
   */
  roles: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof SystemUserExportModel
   */
  isRegistered: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof SystemUserExportModel
   */
  isAuthAppEnforced: boolean = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SystemUserExportModel
   */
  organisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SystemUserExportModel
   */
  organisationName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SystemUserExportModel
   */
  studyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SystemUserExportModel
   */
  studyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SystemUserExportModel
   */
  studyFormat: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SystemUserExportModel
   */
  studyStartDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SystemUserExportModel
   */
  studyEndDate: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof SystemUserExportModel
   */
  canAccessInjuryAndIllnessData: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof SystemUserExportModel
   */
  canAccessExposureData: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof SystemUserExportModel
   */
  canAccessMensData: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof SystemUserExportModel
   */
  canAccessWomensData: boolean = undefined as any;

  constructor(data?: Partial<SystemUserExportModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UserAccountLookupModel
 */
export class UserAccountLookupModel {
  /**
   *
   * @type {Array<OrganisationModel>}
   * @memberof UserAccountLookupModel
   */
  organisations?: Array<OrganisationModel> | null = undefined as any;
  /**
   *
   * @type {Array<StudyModel>}
   * @memberof UserAccountLookupModel
   */
  studies?: Array<StudyModel> | null = undefined as any;
  /**
   *
   * @type {Array<UserRoleModel>}
   * @memberof UserAccountLookupModel
   */
  roles?: Array<UserRoleModel> | null = undefined as any;

  constructor(data?: Partial<UserAccountLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UserCollectionModel
 */
export class UserCollectionModel {
  /**
   * User Id.
   * @type {number}
   * @memberof UserCollectionModel
   */
  id: number = undefined as any;
  /**
   * First name.
   * @type {string}
   * @memberof UserCollectionModel
   */
  firstName: string = undefined as any;
  /**
   * Last name.
   * @type {string}
   * @memberof UserCollectionModel
   */
  lastName: string = undefined as any;
  /**
   * User email.
   * @type {string}
   * @memberof UserCollectionModel
   */
  email: string = undefined as any;
  /**
   * Phone number.
   * @type {string}
   * @memberof UserCollectionModel
   */
  phone: string = undefined as any;
  /**
   * Is user locked out?
   * @type {boolean}
   * @memberof UserCollectionModel
   */
  isLockedOut: boolean = undefined as any;
  /**
   * Is email confirmed?
   * @type {boolean}
   * @memberof UserCollectionModel
   */
  emailConfirmed: boolean = undefined as any;
  /**
   * List of user roles the user belongs to.
   * @type {Array<string>}
   * @memberof UserCollectionModel
   */
  roles?: Array<string> | null = undefined as any;
  /**
   * List of organisations the user belongs to.
   * @type {Array<string>}
   * @memberof UserCollectionModel
   */
  organisations?: Array<string> | null = undefined as any;
  /**
   * Has this user registered in B2C and do we have his Azure AD objectId in AspNetUserClaims table?
   * @type {boolean}
   * @memberof UserCollectionModel
   */
  isB2CRegistered: boolean = undefined as any;

  constructor(data?: Partial<UserCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UserCollectionModelGridCollection
 */
export class UserCollectionModelGridCollection {
  /**
   *
   * @type {Array<UserCollectionModel>}
   * @memberof UserCollectionModelGridCollection
   */
  items?: Array<UserCollectionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof UserCollectionModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof UserCollectionModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof UserCollectionModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof UserCollectionModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<UserCollectionModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * User filter model.
 * @export
 * @interface UserGridFilter
 */
export class UserGridFilter {
  /**
   *
   * @type {number}
   * @memberof UserGridFilter
   */
  roleId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof UserGridFilter
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof UserGridFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof UserGridFilter
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof UserGridFilter
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof UserGridFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof UserGridFilter
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof UserGridFilter
   */
  search: string = undefined as any;

  constructor(data?: Partial<UserGridFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UserRoleModel
 */
export class UserRoleModel {
  /**
   * Role Id.
   * @type {number}
   * @memberof UserRoleModel
   */
  id: number = undefined as any;
  /**
   * Role name.
   * @type {string}
   * @memberof UserRoleModel
   */
  name: string = undefined as any;

  constructor(data?: Partial<UserRoleModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface VueUserContextModel
 */
export class VueUserContextModel {
  /**
   * User Id.
   * @type {number}
   * @memberof VueUserContextModel
   */
  userId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof VueUserContextModel
   */
  timestamp: string = undefined as any;
  /**
   * User email.
   * @type {string}
   * @memberof VueUserContextModel
   */
  email: string = undefined as any;
  /**
   * First name.
   * @type {string}
   * @memberof VueUserContextModel
   */
  firstName: string = undefined as any;
  /**
   * Last name.
   * @type {string}
   * @memberof VueUserContextModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof VueUserContextModel
   */
  fullName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof VueUserContextModel
   */
  roleName: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  isAdmin: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  isStudyAdmin: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  isStudyOrSiteAdmin: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  isReporter: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  isAnalyst: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  isMedicalAdmin: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  isMedicalOrSiteAdmin: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  isMedicalOrStudyOrSiteAdmin: boolean = undefined as any;
  /**
   * Context organisation id. This should get populated from the request header.
   * @type {number}
   * @memberof VueUserContextModel
   */
  currentOrganisationId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof VueUserContextModel
   */
  currentReporterOrganisationName: string = undefined as any;
  /**
   * A list of reporter organisations that this user has access to.
   * @type {Array<OrganisationCollectionModel>}
   * @memberof VueUserContextModel
   */
  reporterOrganisations?: Array<OrganisationCollectionModel> | null = undefined as any;
  /**
   * A list of guidance files for this reporter user.
   * @type {Array<StudyGuidanceFileModel>}
   * @memberof VueUserContextModel
   */
  reporterGuidances?: Array<StudyGuidanceFileModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof VueUserContextModel
   */
  currentStudyTypeId: number = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  anyReporterStudies: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  isEliteReporter: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  isCommunityReporter: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  canAccessInjuryAndIllnessData: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  canAccessExposureData: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VueUserContextModel
   */
  canAccessCatastrophicData: boolean = undefined as any;
  /**
   *
   * @type {Array<UserRoleModel>}
   * @memberof VueUserContextModel
   */
  availableUserRoles?: Array<UserRoleModel> | null = undefined as any;
  /**
   * This is the context roleId under which the user is currently operating under.  It will be sent back to the backend through the \"X-Context-Role-Id\" header  on each request.
   * @type {number}
   * @memberof VueUserContextModel
   */
  contextRoleId: number = undefined as any;

  constructor(data?: Partial<VueUserContextModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Gets detailed user object for the current context user.
     * @param {number} [reporterOrganisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVueUserContext: async (reporterOrganisationId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/context`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (reporterOrganisationId !== undefined) {
        localVarQueryParameter['reporterOrganisationId'] = reporterOrganisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Gets detailed user object for the current context user.
     * @param {number} [reporterOrganisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVueUserContext(
      reporterOrganisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VueUserContextModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVueUserContext(reporterOrganisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AccountApiFp(configuration);
  return {
    /**
     *
     * @summary Gets detailed user object for the current context user.
     * @param {number} [reporterOrganisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVueUserContext(reporterOrganisationId?: number, options?: any): AxiosPromise<VueUserContextModel> {
      return localVarFp.getVueUserContext(reporterOrganisationId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AccountApi - interface
 * @export
 * @interface AccountApi
 */
export interface AccountApiInterface {
  /**
   *
   * @summary Gets detailed user object for the current context user.
   * @param {number} [reporterOrganisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  getVueUserContext(reporterOrganisationId?: number, options?: any): AxiosPromise<VueUserContextModel>;
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI implements AccountApiInterface {
  /**
   *
   * @summary Gets detailed user object for the current context user.
   * @param {number} [reporterOrganisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getVueUserContext(reporterOrganisationId?: number, options?: any) {
    return AccountApiFp(this.configuration)
      .getVueUserContext(reporterOrganisationId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ApiKeyApi - axios parameter creator
 * @export
 */
export const ApiKeyApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} apiKeyId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStudy: async (apiKeyId: number, studyId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'apiKeyId' is not null or undefined
      assertParamExists('addStudy', 'apiKeyId', apiKeyId);
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('addStudy', 'studyId', studyId);
      const localVarPath = `/api/v1/apiKey/{apiKeyId}/study/{studyId}`
        .replace(`{${'apiKeyId'}}`, encodeURIComponent(String(apiKeyId)))
        .replace(`{${'studyId'}}`, encodeURIComponent(String(studyId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterApiKeys: async (filter?: GridFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/apiKey/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} apiKeyId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeStudy: async (apiKeyId: number, studyId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'apiKeyId' is not null or undefined
      assertParamExists('removeStudy', 'apiKeyId', apiKeyId);
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('removeStudy', 'studyId', studyId);
      const localVarPath = `/api/v1/apiKey/{apiKeyId}/study/{studyId}`
        .replace(`{${'apiKeyId'}}`, encodeURIComponent(String(apiKeyId)))
        .replace(`{${'studyId'}}`, encodeURIComponent(String(studyId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApiKeyApi - functional programming interface
 * @export
 */
export const ApiKeyApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ApiKeyApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} apiKeyId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addStudy(
      apiKeyId: number,
      studyId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addStudy(apiKeyId, studyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterApiKeys(
      filter?: GridFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeyCollectionModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterApiKeys(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} apiKeyId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeStudy(
      apiKeyId: number,
      studyId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeStudy(apiKeyId, studyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ApiKeyApi - factory interface
 * @export
 */
export const ApiKeyApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ApiKeyApiFp(configuration);
  return {
    /**
     *
     * @param {number} apiKeyId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStudy(apiKeyId: number, studyId: number, options?: any): AxiosPromise<void> {
      return localVarFp.addStudy(apiKeyId, studyId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterApiKeys(filter?: GridFilter, options?: any): AxiosPromise<ApiKeyCollectionModelGridCollection> {
      return localVarFp.filterApiKeys(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} apiKeyId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeStudy(apiKeyId: number, studyId: number, options?: any): AxiosPromise<void> {
      return localVarFp.removeStudy(apiKeyId, studyId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ApiKeyApi - interface
 * @export
 * @interface ApiKeyApi
 */
export interface ApiKeyApiInterface {
  /**
   *
   * @param {number} apiKeyId
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiKeyApiInterface
   */
  addStudy(apiKeyId: number, studyId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {GridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiKeyApiInterface
   */
  filterApiKeys(filter?: GridFilter, options?: any): AxiosPromise<ApiKeyCollectionModelGridCollection>;

  /**
   *
   * @param {number} apiKeyId
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiKeyApiInterface
   */
  removeStudy(apiKeyId: number, studyId: number, options?: any): AxiosPromise<void>;
}

/**
 * ApiKeyApi - object-oriented interface
 * @export
 * @class ApiKeyApi
 * @extends {BaseAPI}
 */
export class ApiKeyApi extends BaseAPI implements ApiKeyApiInterface {
  /**
   *
   * @param {number} apiKeyId
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiKeyApi
   */
  public addStudy(apiKeyId: number, studyId: number, options?: any) {
    return ApiKeyApiFp(this.configuration)
      .addStudy(apiKeyId, studyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiKeyApi
   */
  public filterApiKeys(filter?: GridFilter, options?: any) {
    return ApiKeyApiFp(this.configuration)
      .filterApiKeys(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} apiKeyId
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiKeyApi
   */
  public removeStudy(apiKeyId: number, studyId: number, options?: any) {
    return ApiKeyApiFp(this.configuration)
      .removeStudy(apiKeyId, studyId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ArchiveApi - axios parameter creator
 * @export
 */
export const ArchiveApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveInactiveUsers: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/archive/inactiveUsers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmArchiving: async (studyId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('confirmArchiving', 'studyId', studyId);
      const localVarPath = `/api/v1/archive/studies/{studyId}/confirm`.replace(
        `{${'studyId'}}`,
        encodeURIComponent(String(studyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Archives studies ready to be archived (confirmed, and grace period of 30 days has expired).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finalizeArchiving: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/archive/studies/finalize`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} studyId
     * @param {number} archiver2Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeArchiving: async (studyId: number, archiver2Id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('initializeArchiving', 'studyId', studyId);
      // verify required parameter 'archiver2Id' is not null or undefined
      assertParamExists('initializeArchiving', 'archiver2Id', archiver2Id);
      const localVarPath = `/api/v1/archive/studies/{studyId}/initialize/{archiver2Id}`
        .replace(`{${'studyId'}}`, encodeURIComponent(String(studyId)))
        .replace(`{${'archiver2Id'}}`, encodeURIComponent(String(archiver2Id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ArchiveApi - functional programming interface
 * @export
 */
export const ArchiveApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ArchiveApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveInactiveUsers(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archiveInactiveUsers(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async confirmArchiving(
      studyId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.confirmArchiving(studyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Archives studies ready to be archived (confirmed, and grace period of 30 days has expired).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async finalizeArchiving(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.finalizeArchiving(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} studyId
     * @param {number} archiver2Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async initializeArchiving(
      studyId: number,
      archiver2Id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.initializeArchiving(studyId, archiver2Id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ArchiveApi - factory interface
 * @export
 */
export const ArchiveApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ArchiveApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveInactiveUsers(options?: any): AxiosPromise<void> {
      return localVarFp.archiveInactiveUsers(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmArchiving(studyId: number, options?: any): AxiosPromise<void> {
      return localVarFp.confirmArchiving(studyId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Archives studies ready to be archived (confirmed, and grace period of 30 days has expired).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finalizeArchiving(options?: any): AxiosPromise<void> {
      return localVarFp.finalizeArchiving(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} studyId
     * @param {number} archiver2Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeArchiving(studyId: number, archiver2Id: number, options?: any): AxiosPromise<void> {
      return localVarFp.initializeArchiving(studyId, archiver2Id, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ArchiveApi - interface
 * @export
 * @interface ArchiveApi
 */
export interface ArchiveApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchiveApiInterface
   */
  archiveInactiveUsers(options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchiveApiInterface
   */
  confirmArchiving(studyId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Archives studies ready to be archived (confirmed, and grace period of 30 days has expired).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchiveApiInterface
   */
  finalizeArchiving(options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} studyId
   * @param {number} archiver2Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchiveApiInterface
   */
  initializeArchiving(studyId: number, archiver2Id: number, options?: any): AxiosPromise<void>;
}

/**
 * ArchiveApi - object-oriented interface
 * @export
 * @class ArchiveApi
 * @extends {BaseAPI}
 */
export class ArchiveApi extends BaseAPI implements ArchiveApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchiveApi
   */
  public archiveInactiveUsers(options?: any) {
    return ArchiveApiFp(this.configuration)
      .archiveInactiveUsers(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchiveApi
   */
  public confirmArchiving(studyId: number, options?: any) {
    return ArchiveApiFp(this.configuration)
      .confirmArchiving(studyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Archives studies ready to be archived (confirmed, and grace period of 30 days has expired).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchiveApi
   */
  public finalizeArchiving(options?: any) {
    return ArchiveApiFp(this.configuration)
      .finalizeArchiving(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} studyId
   * @param {number} archiver2Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchiveApi
   */
  public initializeArchiving(studyId: number, archiver2Id: number, options?: any) {
    return ArchiveApiFp(this.configuration)
      .initializeArchiving(studyId, archiver2Id, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AutoSaveApi - axios parameter creator
 * @export
 */
export const AutoSaveApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {AutoSaveModel} [autoSaveModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAutoSave: async (autoSaveModel?: AutoSaveModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/autoSave`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(autoSaveModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAutoSave: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteAutoSave', 'id', id);
      const localVarPath = `/api/v1/autoSave/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [forId]
     * @param {number} [autoSaveTypeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAutoSave: async (forId?: number, autoSaveTypeId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/autoSave/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (forId !== undefined) {
        localVarQueryParameter['forId'] = forId;
      }

      if (autoSaveTypeId !== undefined) {
        localVarQueryParameter['autoSaveTypeId'] = autoSaveTypeId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDrafts: async (organisationId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('getAllDrafts', 'organisationId', organisationId);
      const localVarPath = `/api/v1/autoSave/getAllDrafts/{organisationId}`.replace(
        `{${'organisationId'}}`,
        encodeURIComponent(String(organisationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAutoSave: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAutoSave', 'id', id);
      const localVarPath = `/api/v1/autoSave/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AutoSaveModel} [autoSaveModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAutoSave: async (autoSaveModel?: AutoSaveModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/autoSave`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(autoSaveModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AutoSaveApi - functional programming interface
 * @export
 */
export const AutoSaveApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = AutoSaveApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AutoSaveModel} [autoSaveModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAutoSave(
      autoSaveModel?: AutoSaveModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoSaveModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAutoSave(autoSaveModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAutoSave(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAutoSave(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [forId]
     * @param {number} [autoSaveTypeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findAutoSave(
      forId?: number,
      autoSaveTypeId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoSaveModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findAutoSave(forId, autoSaveTypeId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllDrafts(
      organisationId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AutoSaveCollectionModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDrafts(organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAutoSave(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoSaveModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAutoSave(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AutoSaveModel} [autoSaveModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAutoSave(
      autoSaveModel?: AutoSaveModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoSaveModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAutoSave(autoSaveModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AutoSaveApi - factory interface
 * @export
 */
export const AutoSaveApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AutoSaveApiFp(configuration);
  return {
    /**
     *
     * @param {AutoSaveModel} [autoSaveModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAutoSave(autoSaveModel?: AutoSaveModel, options?: any): AxiosPromise<AutoSaveModel> {
      return localVarFp.createAutoSave(autoSaveModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAutoSave(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteAutoSave(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [forId]
     * @param {number} [autoSaveTypeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAutoSave(forId?: number, autoSaveTypeId?: number, options?: any): AxiosPromise<AutoSaveModel> {
      return localVarFp.findAutoSave(forId, autoSaveTypeId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDrafts(organisationId: number, options?: any): AxiosPromise<Array<AutoSaveCollectionModel>> {
      return localVarFp.getAllDrafts(organisationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAutoSave(id: number, options?: any): AxiosPromise<AutoSaveModel> {
      return localVarFp.getAutoSave(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {AutoSaveModel} [autoSaveModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAutoSave(autoSaveModel?: AutoSaveModel, options?: any): AxiosPromise<AutoSaveModel> {
      return localVarFp.updateAutoSave(autoSaveModel, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AutoSaveApi - interface
 * @export
 * @interface AutoSaveApi
 */
export interface AutoSaveApiInterface {
  /**
   *
   * @param {AutoSaveModel} [autoSaveModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutoSaveApiInterface
   */
  createAutoSave(autoSaveModel?: AutoSaveModel, options?: any): AxiosPromise<AutoSaveModel>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutoSaveApiInterface
   */
  deleteAutoSave(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} [forId]
   * @param {number} [autoSaveTypeId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutoSaveApiInterface
   */
  findAutoSave(forId?: number, autoSaveTypeId?: number, options?: any): AxiosPromise<AutoSaveModel>;

  /**
   *
   * @param {number} organisationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutoSaveApiInterface
   */
  getAllDrafts(organisationId: number, options?: any): AxiosPromise<Array<AutoSaveCollectionModel>>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutoSaveApiInterface
   */
  getAutoSave(id: number, options?: any): AxiosPromise<AutoSaveModel>;

  /**
   *
   * @param {AutoSaveModel} [autoSaveModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutoSaveApiInterface
   */
  updateAutoSave(autoSaveModel?: AutoSaveModel, options?: any): AxiosPromise<AutoSaveModel>;
}

/**
 * AutoSaveApi - object-oriented interface
 * @export
 * @class AutoSaveApi
 * @extends {BaseAPI}
 */
export class AutoSaveApi extends BaseAPI implements AutoSaveApiInterface {
  /**
   *
   * @param {AutoSaveModel} [autoSaveModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutoSaveApi
   */
  public createAutoSave(autoSaveModel?: AutoSaveModel, options?: any) {
    return AutoSaveApiFp(this.configuration)
      .createAutoSave(autoSaveModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutoSaveApi
   */
  public deleteAutoSave(id: number, options?: any) {
    return AutoSaveApiFp(this.configuration)
      .deleteAutoSave(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [forId]
   * @param {number} [autoSaveTypeId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutoSaveApi
   */
  public findAutoSave(forId?: number, autoSaveTypeId?: number, options?: any) {
    return AutoSaveApiFp(this.configuration)
      .findAutoSave(forId, autoSaveTypeId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} organisationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutoSaveApi
   */
  public getAllDrafts(organisationId: number, options?: any) {
    return AutoSaveApiFp(this.configuration)
      .getAllDrafts(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutoSaveApi
   */
  public getAutoSave(id: number, options?: any) {
    return AutoSaveApiFp(this.configuration)
      .getAutoSave(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AutoSaveModel} [autoSaveModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutoSaveApi
   */
  public updateAutoSave(autoSaveModel?: AutoSaveModel, options?: any) {
    return AutoSaveApiFp(this.configuration)
      .updateAutoSave(autoSaveModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CatastrophicInjuriesApi - axios parameter creator
 * @export
 */
export const CatastrophicInjuriesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {CatastrophicFollowUpModel} [catastrophicFollowUpModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCatastrophicFollowUp: async (
      catastrophicFollowUpModel?: CatastrophicFollowUpModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/catastrophic/injuries/followUp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        catastrophicFollowUpModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CatastrophicInjuryModel} [catastrophicInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCatastrophicInjury: async (
      catastrophicInjuryModel?: CatastrophicInjuryModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/catastrophic/injuries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        catastrophicInjuryModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCatastrophicInjury: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteCatastrophicInjury', 'id', id);
      const localVarPath = `/api/v1/catastrophic/injuries/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CatastrophicGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterCatastrophicInjuries: async (filter?: CatastrophicGridFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/catastrophic/injuries/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} injuryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatastrophicFollowUp: async (injuryId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'injuryId' is not null or undefined
      assertParamExists('getCatastrophicFollowUp', 'injuryId', injuryId);
      const localVarPath = `/api/v1/catastrophic/injuries/{injuryId}/followUp`.replace(
        `{${'injuryId'}}`,
        encodeURIComponent(String(injuryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatastrophicFollowUpFormLookupData: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/catastrophic/injuries/followUp/formLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatastrophicInjury: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getCatastrophicInjury', 'id', id);
      const localVarPath = `/api/v1/catastrophic/injuries/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatastrophicInjuryFormLookupData: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/catastrophic/injuries/formLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CatastrophicFollowUpModel} [catastrophicFollowUpModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCatastrophicFollowUp: async (
      catastrophicFollowUpModel?: CatastrophicFollowUpModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/catastrophic/injuries/followUp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        catastrophicFollowUpModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CatastrophicInjuryModel} [catastrophicInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCatastrophicInjury: async (
      catastrophicInjuryModel?: CatastrophicInjuryModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/catastrophic/injuries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        catastrophicInjuryModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CatastrophicInjuriesApi - functional programming interface
 * @export
 */
export const CatastrophicInjuriesApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = CatastrophicInjuriesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CatastrophicFollowUpModel} [catastrophicFollowUpModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCatastrophicFollowUp(
      catastrophicFollowUpModel?: CatastrophicFollowUpModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatastrophicFollowUpModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCatastrophicFollowUp(
        catastrophicFollowUpModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CatastrophicInjuryModel} [catastrophicInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCatastrophicInjury(
      catastrophicInjuryModel?: CatastrophicInjuryModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatastrophicInjuryModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCatastrophicInjury(
        catastrophicInjuryModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCatastrophicInjury(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCatastrophicInjury(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CatastrophicGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterCatastrophicInjuries(
      filter?: CatastrophicGridFilter,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatastrophicInjuryCollectionModelGridCollection>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterCatastrophicInjuries(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} injuryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatastrophicFollowUp(
      injuryId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatastrophicFollowUpModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatastrophicFollowUp(injuryId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatastrophicFollowUpFormLookupData(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatastrophicFollowUpLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatastrophicFollowUpFormLookupData(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatastrophicInjury(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatastrophicInjuryModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatastrophicInjury(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatastrophicInjuryFormLookupData(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatastrophicInjuryLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatastrophicInjuryFormLookupData(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CatastrophicFollowUpModel} [catastrophicFollowUpModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCatastrophicFollowUp(
      catastrophicFollowUpModel?: CatastrophicFollowUpModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatastrophicFollowUpModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCatastrophicFollowUp(
        catastrophicFollowUpModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CatastrophicInjuryModel} [catastrophicInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCatastrophicInjury(
      catastrophicInjuryModel?: CatastrophicInjuryModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatastrophicInjuryModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCatastrophicInjury(
        catastrophicInjuryModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CatastrophicInjuriesApi - factory interface
 * @export
 */
export const CatastrophicInjuriesApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CatastrophicInjuriesApiFp(configuration);
  return {
    /**
     *
     * @param {CatastrophicFollowUpModel} [catastrophicFollowUpModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCatastrophicFollowUp(
      catastrophicFollowUpModel?: CatastrophicFollowUpModel,
      options?: any,
    ): AxiosPromise<CatastrophicFollowUpModel> {
      return localVarFp
        .createCatastrophicFollowUp(catastrophicFollowUpModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CatastrophicInjuryModel} [catastrophicInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCatastrophicInjury(
      catastrophicInjuryModel?: CatastrophicInjuryModel,
      options?: any,
    ): AxiosPromise<CatastrophicInjuryModel> {
      return localVarFp
        .createCatastrophicInjury(catastrophicInjuryModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCatastrophicInjury(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteCatastrophicInjury(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CatastrophicGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterCatastrophicInjuries(
      filter?: CatastrophicGridFilter,
      options?: any,
    ): AxiosPromise<CatastrophicInjuryCollectionModelGridCollection> {
      return localVarFp.filterCatastrophicInjuries(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} injuryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatastrophicFollowUp(injuryId: number, options?: any): AxiosPromise<CatastrophicFollowUpModel> {
      return localVarFp.getCatastrophicFollowUp(injuryId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatastrophicFollowUpFormLookupData(options?: any): AxiosPromise<CatastrophicFollowUpLookupModel> {
      return localVarFp.getCatastrophicFollowUpFormLookupData(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatastrophicInjury(id: number, options?: any): AxiosPromise<CatastrophicInjuryModel> {
      return localVarFp.getCatastrophicInjury(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatastrophicInjuryFormLookupData(options?: any): AxiosPromise<CatastrophicInjuryLookupModel> {
      return localVarFp.getCatastrophicInjuryFormLookupData(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CatastrophicFollowUpModel} [catastrophicFollowUpModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCatastrophicFollowUp(
      catastrophicFollowUpModel?: CatastrophicFollowUpModel,
      options?: any,
    ): AxiosPromise<CatastrophicFollowUpModel> {
      return localVarFp
        .updateCatastrophicFollowUp(catastrophicFollowUpModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CatastrophicInjuryModel} [catastrophicInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCatastrophicInjury(
      catastrophicInjuryModel?: CatastrophicInjuryModel,
      options?: any,
    ): AxiosPromise<CatastrophicInjuryModel> {
      return localVarFp
        .updateCatastrophicInjury(catastrophicInjuryModel, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * CatastrophicInjuriesApi - interface
 * @export
 * @interface CatastrophicInjuriesApi
 */
export interface CatastrophicInjuriesApiInterface {
  /**
   *
   * @param {CatastrophicFollowUpModel} [catastrophicFollowUpModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApiInterface
   */
  createCatastrophicFollowUp(
    catastrophicFollowUpModel?: CatastrophicFollowUpModel,
    options?: any,
  ): AxiosPromise<CatastrophicFollowUpModel>;

  /**
   *
   * @param {CatastrophicInjuryModel} [catastrophicInjuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApiInterface
   */
  createCatastrophicInjury(
    catastrophicInjuryModel?: CatastrophicInjuryModel,
    options?: any,
  ): AxiosPromise<CatastrophicInjuryModel>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApiInterface
   */
  deleteCatastrophicInjury(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {CatastrophicGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApiInterface
   */
  filterCatastrophicInjuries(
    filter?: CatastrophicGridFilter,
    options?: any,
  ): AxiosPromise<CatastrophicInjuryCollectionModelGridCollection>;

  /**
   *
   * @param {number} injuryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApiInterface
   */
  getCatastrophicFollowUp(injuryId: number, options?: any): AxiosPromise<CatastrophicFollowUpModel>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApiInterface
   */
  getCatastrophicFollowUpFormLookupData(options?: any): AxiosPromise<CatastrophicFollowUpLookupModel>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApiInterface
   */
  getCatastrophicInjury(id: number, options?: any): AxiosPromise<CatastrophicInjuryModel>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApiInterface
   */
  getCatastrophicInjuryFormLookupData(options?: any): AxiosPromise<CatastrophicInjuryLookupModel>;

  /**
   *
   * @param {CatastrophicFollowUpModel} [catastrophicFollowUpModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApiInterface
   */
  updateCatastrophicFollowUp(
    catastrophicFollowUpModel?: CatastrophicFollowUpModel,
    options?: any,
  ): AxiosPromise<CatastrophicFollowUpModel>;

  /**
   *
   * @param {CatastrophicInjuryModel} [catastrophicInjuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApiInterface
   */
  updateCatastrophicInjury(
    catastrophicInjuryModel?: CatastrophicInjuryModel,
    options?: any,
  ): AxiosPromise<CatastrophicInjuryModel>;
}

/**
 * CatastrophicInjuriesApi - object-oriented interface
 * @export
 * @class CatastrophicInjuriesApi
 * @extends {BaseAPI}
 */
export class CatastrophicInjuriesApi extends BaseAPI implements CatastrophicInjuriesApiInterface {
  /**
   *
   * @param {CatastrophicFollowUpModel} [catastrophicFollowUpModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApi
   */
  public createCatastrophicFollowUp(catastrophicFollowUpModel?: CatastrophicFollowUpModel, options?: any) {
    return CatastrophicInjuriesApiFp(this.configuration)
      .createCatastrophicFollowUp(catastrophicFollowUpModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CatastrophicInjuryModel} [catastrophicInjuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApi
   */
  public createCatastrophicInjury(catastrophicInjuryModel?: CatastrophicInjuryModel, options?: any) {
    return CatastrophicInjuriesApiFp(this.configuration)
      .createCatastrophicInjury(catastrophicInjuryModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApi
   */
  public deleteCatastrophicInjury(id: number, options?: any) {
    return CatastrophicInjuriesApiFp(this.configuration)
      .deleteCatastrophicInjury(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CatastrophicGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApi
   */
  public filterCatastrophicInjuries(filter?: CatastrophicGridFilter, options?: any) {
    return CatastrophicInjuriesApiFp(this.configuration)
      .filterCatastrophicInjuries(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} injuryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApi
   */
  public getCatastrophicFollowUp(injuryId: number, options?: any) {
    return CatastrophicInjuriesApiFp(this.configuration)
      .getCatastrophicFollowUp(injuryId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApi
   */
  public getCatastrophicFollowUpFormLookupData(options?: any) {
    return CatastrophicInjuriesApiFp(this.configuration)
      .getCatastrophicFollowUpFormLookupData(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApi
   */
  public getCatastrophicInjury(id: number, options?: any) {
    return CatastrophicInjuriesApiFp(this.configuration)
      .getCatastrophicInjury(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApi
   */
  public getCatastrophicInjuryFormLookupData(options?: any) {
    return CatastrophicInjuriesApiFp(this.configuration)
      .getCatastrophicInjuryFormLookupData(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CatastrophicFollowUpModel} [catastrophicFollowUpModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApi
   */
  public updateCatastrophicFollowUp(catastrophicFollowUpModel?: CatastrophicFollowUpModel, options?: any) {
    return CatastrophicInjuriesApiFp(this.configuration)
      .updateCatastrophicFollowUp(catastrophicFollowUpModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CatastrophicInjuryModel} [catastrophicInjuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatastrophicInjuriesApi
   */
  public updateCatastrophicInjury(catastrophicInjuryModel?: CatastrophicInjuryModel, options?: any) {
    return CatastrophicInjuriesApiFp(this.configuration)
      .updateCatastrophicInjury(catastrophicInjuryModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CommunityExposuresApi - axios parameter creator
 * @export
 */
export const CommunityExposuresApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {CommunityMatchExposureModel} [communityMatchExposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMatchExposure: async (
      communityMatchExposureModel?: CommunityMatchExposureModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/exposures/match`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        communityMatchExposureModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CommunityTrainingExposureModel} [communityTrainingExposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrainingExposure: async (
      communityTrainingExposureModel?: CommunityTrainingExposureModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/exposures/training`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        communityTrainingExposureModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommunityExposure: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteCommunityExposure', 'id', id);
      const localVarPath = `/api/v1/community/exposures/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CommunityExposureGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterMatchExposures: async (filter?: CommunityExposureGridFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/exposures/match/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CommunityExposureGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterTrainingExposures: async (filter?: CommunityExposureGridFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/exposures/training/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommunityExposureFormLookupData: async (organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/exposures/formLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CommunityMatchExposureModel} [communityMatchExposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMatchExposure: async (
      communityMatchExposureModel?: CommunityMatchExposureModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/exposures/match`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        communityMatchExposureModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CommunityTrainingExposureModel} [communityTrainingExposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTrainingExposure: async (
      communityTrainingExposureModel?: CommunityTrainingExposureModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/exposures/training`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        communityTrainingExposureModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommunityExposuresApi - functional programming interface
 * @export
 */
export const CommunityExposuresApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = CommunityExposuresApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CommunityMatchExposureModel} [communityMatchExposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMatchExposure(
      communityMatchExposureModel?: CommunityMatchExposureModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMatchExposure(
        communityMatchExposureModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CommunityTrainingExposureModel} [communityTrainingExposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTrainingExposure(
      communityTrainingExposureModel?: CommunityTrainingExposureModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTrainingExposure(
        communityTrainingExposureModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCommunityExposure(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommunityExposure(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CommunityExposureGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterMatchExposures(
      filter?: CommunityExposureGridFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityMatchExposureModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterMatchExposures(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CommunityExposureGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterTrainingExposures(
      filter?: CommunityExposureGridFilter,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityTrainingExposureModelGridCollection>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterTrainingExposures(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommunityExposureFormLookupData(
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityExposureLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCommunityExposureFormLookupData(
        organisationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CommunityMatchExposureModel} [communityMatchExposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMatchExposure(
      communityMatchExposureModel?: CommunityMatchExposureModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMatchExposure(
        communityMatchExposureModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CommunityTrainingExposureModel} [communityTrainingExposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTrainingExposure(
      communityTrainingExposureModel?: CommunityTrainingExposureModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrainingExposure(
        communityTrainingExposureModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CommunityExposuresApi - factory interface
 * @export
 */
export const CommunityExposuresApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CommunityExposuresApiFp(configuration);
  return {
    /**
     *
     * @param {CommunityMatchExposureModel} [communityMatchExposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMatchExposure(communityMatchExposureModel?: CommunityMatchExposureModel, options?: any): AxiosPromise<void> {
      return localVarFp
        .createMatchExposure(communityMatchExposureModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CommunityTrainingExposureModel} [communityTrainingExposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrainingExposure(
      communityTrainingExposureModel?: CommunityTrainingExposureModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createTrainingExposure(communityTrainingExposureModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommunityExposure(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteCommunityExposure(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CommunityExposureGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterMatchExposures(
      filter?: CommunityExposureGridFilter,
      options?: any,
    ): AxiosPromise<CommunityMatchExposureModelGridCollection> {
      return localVarFp.filterMatchExposures(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CommunityExposureGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterTrainingExposures(
      filter?: CommunityExposureGridFilter,
      options?: any,
    ): AxiosPromise<CommunityTrainingExposureModelGridCollection> {
      return localVarFp.filterTrainingExposures(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommunityExposureFormLookupData(
      organisationId?: number,
      options?: any,
    ): AxiosPromise<CommunityExposureLookupModel> {
      return localVarFp
        .getCommunityExposureFormLookupData(organisationId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CommunityMatchExposureModel} [communityMatchExposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMatchExposure(communityMatchExposureModel?: CommunityMatchExposureModel, options?: any): AxiosPromise<void> {
      return localVarFp
        .updateMatchExposure(communityMatchExposureModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CommunityTrainingExposureModel} [communityTrainingExposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTrainingExposure(
      communityTrainingExposureModel?: CommunityTrainingExposureModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateTrainingExposure(communityTrainingExposureModel, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * CommunityExposuresApi - interface
 * @export
 * @interface CommunityExposuresApi
 */
export interface CommunityExposuresApiInterface {
  /**
   *
   * @param {CommunityMatchExposureModel} [communityMatchExposureModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApiInterface
   */
  createMatchExposure(communityMatchExposureModel?: CommunityMatchExposureModel, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {CommunityTrainingExposureModel} [communityTrainingExposureModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApiInterface
   */
  createTrainingExposure(
    communityTrainingExposureModel?: CommunityTrainingExposureModel,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApiInterface
   */
  deleteCommunityExposure(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {CommunityExposureGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApiInterface
   */
  filterMatchExposures(
    filter?: CommunityExposureGridFilter,
    options?: any,
  ): AxiosPromise<CommunityMatchExposureModelGridCollection>;

  /**
   *
   * @param {CommunityExposureGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApiInterface
   */
  filterTrainingExposures(
    filter?: CommunityExposureGridFilter,
    options?: any,
  ): AxiosPromise<CommunityTrainingExposureModelGridCollection>;

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApiInterface
   */
  getCommunityExposureFormLookupData(
    organisationId?: number,
    options?: any,
  ): AxiosPromise<CommunityExposureLookupModel>;

  /**
   *
   * @param {CommunityMatchExposureModel} [communityMatchExposureModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApiInterface
   */
  updateMatchExposure(communityMatchExposureModel?: CommunityMatchExposureModel, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {CommunityTrainingExposureModel} [communityTrainingExposureModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApiInterface
   */
  updateTrainingExposure(
    communityTrainingExposureModel?: CommunityTrainingExposureModel,
    options?: any,
  ): AxiosPromise<void>;
}

/**
 * CommunityExposuresApi - object-oriented interface
 * @export
 * @class CommunityExposuresApi
 * @extends {BaseAPI}
 */
export class CommunityExposuresApi extends BaseAPI implements CommunityExposuresApiInterface {
  /**
   *
   * @param {CommunityMatchExposureModel} [communityMatchExposureModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApi
   */
  public createMatchExposure(communityMatchExposureModel?: CommunityMatchExposureModel, options?: any) {
    return CommunityExposuresApiFp(this.configuration)
      .createMatchExposure(communityMatchExposureModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CommunityTrainingExposureModel} [communityTrainingExposureModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApi
   */
  public createTrainingExposure(communityTrainingExposureModel?: CommunityTrainingExposureModel, options?: any) {
    return CommunityExposuresApiFp(this.configuration)
      .createTrainingExposure(communityTrainingExposureModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApi
   */
  public deleteCommunityExposure(id: number, options?: any) {
    return CommunityExposuresApiFp(this.configuration)
      .deleteCommunityExposure(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CommunityExposureGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApi
   */
  public filterMatchExposures(filter?: CommunityExposureGridFilter, options?: any) {
    return CommunityExposuresApiFp(this.configuration)
      .filterMatchExposures(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CommunityExposureGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApi
   */
  public filterTrainingExposures(filter?: CommunityExposureGridFilter, options?: any) {
    return CommunityExposuresApiFp(this.configuration)
      .filterTrainingExposures(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApi
   */
  public getCommunityExposureFormLookupData(organisationId?: number, options?: any) {
    return CommunityExposuresApiFp(this.configuration)
      .getCommunityExposureFormLookupData(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CommunityMatchExposureModel} [communityMatchExposureModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApi
   */
  public updateMatchExposure(communityMatchExposureModel?: CommunityMatchExposureModel, options?: any) {
    return CommunityExposuresApiFp(this.configuration)
      .updateMatchExposure(communityMatchExposureModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CommunityTrainingExposureModel} [communityTrainingExposureModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityExposuresApi
   */
  public updateTrainingExposure(communityTrainingExposureModel?: CommunityTrainingExposureModel, options?: any) {
    return CommunityExposuresApiFp(this.configuration)
      .updateTrainingExposure(communityTrainingExposureModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CommunityInjuriesApi - axios parameter creator
 * @export
 */
export const CommunityInjuriesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {CommunityInjuryModel} [communityInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommunityInjury: async (
      communityInjuryModel?: CommunityInjuryModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/injuries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(communityInjuryModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommunityInjury: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteCommunityInjury', 'id', id);
      const localVarPath = `/api/v1/community/injuries/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CommunityInjuryGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterCommunityInjuries: async (filter?: CommunityInjuryGridFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/injuries/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommunityInjury: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getCommunityInjury', 'id', id);
      const localVarPath = `/api/v1/community/injuries/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommunityInjuryCollectionLookupData: async (
      organisationId?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/injuries/collectionLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommunityInjuryFormLookupData: async (organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/injuries/formLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CommunityInjuryModel} [communityInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQualityControl: async (
      communityInjuryModel?: CommunityInjuryModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/injuries/qualityControl`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(communityInjuryModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CommunityInjuryModel} [communityInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCommunityInjury: async (
      communityInjuryModel?: CommunityInjuryModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/community/injuries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(communityInjuryModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommunityInjuriesApi - functional programming interface
 * @export
 */
export const CommunityInjuriesApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = CommunityInjuriesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CommunityInjuryModel} [communityInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCommunityInjury(
      communityInjuryModel?: CommunityInjuryModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityInjuryModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCommunityInjury(communityInjuryModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCommunityInjury(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommunityInjury(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CommunityInjuryGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterCommunityInjuries(
      filter?: CommunityInjuryGridFilter,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityInjuryCollectionModelGridCollection>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterCommunityInjuries(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommunityInjury(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityInjuryModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCommunityInjury(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommunityInjuryCollectionLookupData(
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityInjuryCollectionLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCommunityInjuryCollectionLookupData(
        organisationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommunityInjuryFormLookupData(
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityInjuryLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCommunityInjuryFormLookupData(
        organisationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CommunityInjuryModel} [communityInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveQualityControl(
      communityInjuryModel?: CommunityInjuryModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityInjuryModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveQualityControl(communityInjuryModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CommunityInjuryModel} [communityInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCommunityInjury(
      communityInjuryModel?: CommunityInjuryModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityInjuryModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommunityInjury(communityInjuryModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CommunityInjuriesApi - factory interface
 * @export
 */
export const CommunityInjuriesApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CommunityInjuriesApiFp(configuration);
  return {
    /**
     *
     * @param {CommunityInjuryModel} [communityInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommunityInjury(
      communityInjuryModel?: CommunityInjuryModel,
      options?: any,
    ): AxiosPromise<CommunityInjuryModel> {
      return localVarFp.createCommunityInjury(communityInjuryModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommunityInjury(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteCommunityInjury(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CommunityInjuryGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterCommunityInjuries(
      filter?: CommunityInjuryGridFilter,
      options?: any,
    ): AxiosPromise<CommunityInjuryCollectionModelGridCollection> {
      return localVarFp.filterCommunityInjuries(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommunityInjury(id: number, options?: any): AxiosPromise<CommunityInjuryModel> {
      return localVarFp.getCommunityInjury(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommunityInjuryCollectionLookupData(
      organisationId?: number,
      options?: any,
    ): AxiosPromise<CommunityInjuryCollectionLookupModel> {
      return localVarFp
        .getCommunityInjuryCollectionLookupData(organisationId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommunityInjuryFormLookupData(organisationId?: number, options?: any): AxiosPromise<CommunityInjuryLookupModel> {
      return localVarFp
        .getCommunityInjuryFormLookupData(organisationId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CommunityInjuryModel} [communityInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQualityControl(communityInjuryModel?: CommunityInjuryModel, options?: any): AxiosPromise<CommunityInjuryModel> {
      return localVarFp.saveQualityControl(communityInjuryModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CommunityInjuryModel} [communityInjuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCommunityInjury(
      communityInjuryModel?: CommunityInjuryModel,
      options?: any,
    ): AxiosPromise<CommunityInjuryModel> {
      return localVarFp.updateCommunityInjury(communityInjuryModel, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CommunityInjuriesApi - interface
 * @export
 * @interface CommunityInjuriesApi
 */
export interface CommunityInjuriesApiInterface {
  /**
   *
   * @param {CommunityInjuryModel} [communityInjuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApiInterface
   */
  createCommunityInjury(communityInjuryModel?: CommunityInjuryModel, options?: any): AxiosPromise<CommunityInjuryModel>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApiInterface
   */
  deleteCommunityInjury(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {CommunityInjuryGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApiInterface
   */
  filterCommunityInjuries(
    filter?: CommunityInjuryGridFilter,
    options?: any,
  ): AxiosPromise<CommunityInjuryCollectionModelGridCollection>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApiInterface
   */
  getCommunityInjury(id: number, options?: any): AxiosPromise<CommunityInjuryModel>;

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApiInterface
   */
  getCommunityInjuryCollectionLookupData(
    organisationId?: number,
    options?: any,
  ): AxiosPromise<CommunityInjuryCollectionLookupModel>;

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApiInterface
   */
  getCommunityInjuryFormLookupData(organisationId?: number, options?: any): AxiosPromise<CommunityInjuryLookupModel>;

  /**
   *
   * @param {CommunityInjuryModel} [communityInjuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApiInterface
   */
  saveQualityControl(communityInjuryModel?: CommunityInjuryModel, options?: any): AxiosPromise<CommunityInjuryModel>;

  /**
   *
   * @param {CommunityInjuryModel} [communityInjuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApiInterface
   */
  updateCommunityInjury(communityInjuryModel?: CommunityInjuryModel, options?: any): AxiosPromise<CommunityInjuryModel>;
}

/**
 * CommunityInjuriesApi - object-oriented interface
 * @export
 * @class CommunityInjuriesApi
 * @extends {BaseAPI}
 */
export class CommunityInjuriesApi extends BaseAPI implements CommunityInjuriesApiInterface {
  /**
   *
   * @param {CommunityInjuryModel} [communityInjuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApi
   */
  public createCommunityInjury(communityInjuryModel?: CommunityInjuryModel, options?: any) {
    return CommunityInjuriesApiFp(this.configuration)
      .createCommunityInjury(communityInjuryModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApi
   */
  public deleteCommunityInjury(id: number, options?: any) {
    return CommunityInjuriesApiFp(this.configuration)
      .deleteCommunityInjury(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CommunityInjuryGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApi
   */
  public filterCommunityInjuries(filter?: CommunityInjuryGridFilter, options?: any) {
    return CommunityInjuriesApiFp(this.configuration)
      .filterCommunityInjuries(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApi
   */
  public getCommunityInjury(id: number, options?: any) {
    return CommunityInjuriesApiFp(this.configuration)
      .getCommunityInjury(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApi
   */
  public getCommunityInjuryCollectionLookupData(organisationId?: number, options?: any) {
    return CommunityInjuriesApiFp(this.configuration)
      .getCommunityInjuryCollectionLookupData(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApi
   */
  public getCommunityInjuryFormLookupData(organisationId?: number, options?: any) {
    return CommunityInjuriesApiFp(this.configuration)
      .getCommunityInjuryFormLookupData(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CommunityInjuryModel} [communityInjuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApi
   */
  public saveQualityControl(communityInjuryModel?: CommunityInjuryModel, options?: any) {
    return CommunityInjuriesApiFp(this.configuration)
      .saveQualityControl(communityInjuryModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CommunityInjuryModel} [communityInjuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommunityInjuriesApi
   */
  public updateCommunityInjury(communityInjuryModel?: CommunityInjuryModel, options?: any) {
    return CommunityInjuriesApiFp(this.configuration)
      .updateCommunityInjury(communityInjuryModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnalystDashboard: async (id?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dashboard/analyst`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnalystDashboard(
      id?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnalystDashboardItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalystDashboard(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DashboardApiFp(configuration);
  return {
    /**
     *
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnalystDashboard(id?: number, options?: any): AxiosPromise<Array<AnalystDashboardItem>> {
      return localVarFp.getAnalystDashboard(id, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DashboardApi - interface
 * @export
 * @interface DashboardApi
 */
export interface DashboardApiInterface {
  /**
   *
   * @param {number} [id]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardApiInterface
   */
  getAnalystDashboard(id?: number, options?: any): AxiosPromise<Array<AnalystDashboardItem>>;
}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI implements DashboardApiInterface {
  /**
   *
   * @param {number} [id]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardApi
   */
  public getAnalystDashboard(id?: number, options?: any) {
    return DashboardApiFp(this.configuration)
      .getAnalystDashboard(id, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DiagnosticsApi - axios parameter creator
 * @export
 */
export const DiagnosticsApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary This is for integration tests diagnostics only.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGameFormats: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/diagnostics/gameFormats`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Checks health of the API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/diagnostics/healthCheck`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testException: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/diagnostics/error`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DiagnosticsApi - functional programming interface
 * @export
 */
export const DiagnosticsApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = DiagnosticsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary This is for integration tests diagnostics only.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGameFormats(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleLookupModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGameFormats(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Checks health of the API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthCheck(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheck(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async testException(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.testException(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DiagnosticsApi - factory interface
 * @export
 */
export const DiagnosticsApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DiagnosticsApiFp(configuration);
  return {
    /**
     *
     * @summary This is for integration tests diagnostics only.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGameFormats(options?: any): AxiosPromise<Array<SimpleLookupModel>> {
      return localVarFp.getGameFormats(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Checks health of the API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options?: any): AxiosPromise<string> {
      return localVarFp.healthCheck(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testException(options?: any): AxiosPromise<string> {
      return localVarFp.testException(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DiagnosticsApi - interface
 * @export
 * @interface DiagnosticsApi
 */
export interface DiagnosticsApiInterface {
  /**
   *
   * @summary This is for integration tests diagnostics only.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiagnosticsApiInterface
   */
  getGameFormats(options?: any): AxiosPromise<Array<SimpleLookupModel>>;

  /**
   *
   * @summary Checks health of the API.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiagnosticsApiInterface
   */
  healthCheck(options?: any): AxiosPromise<string>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiagnosticsApiInterface
   */
  testException(options?: any): AxiosPromise<string>;
}

/**
 * DiagnosticsApi - object-oriented interface
 * @export
 * @class DiagnosticsApi
 * @extends {BaseAPI}
 */
export class DiagnosticsApi extends BaseAPI implements DiagnosticsApiInterface {
  /**
   *
   * @summary This is for integration tests diagnostics only.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiagnosticsApi
   */
  public getGameFormats(options?: any) {
    return DiagnosticsApiFp(this.configuration)
      .getGameFormats(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Checks health of the API.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiagnosticsApi
   */
  public healthCheck(options?: any) {
    return DiagnosticsApiFp(this.configuration)
      .healthCheck(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiagnosticsApi
   */
  public testException(options?: any) {
    return DiagnosticsApiFp(this.configuration)
      .testException(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} dataType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptCsvExportDisclaimer: async (dataType: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'dataType' is not null or undefined
      assertParamExists('acceptCsvExportDisclaimer', 'dataType', dataType);
      const localVarPath = `/api/v1/export/acceptCsvExportDisclaimer/{dataType}`.replace(
        `{${'dataType'}}`,
        encodeURIComponent(String(dataType)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} studyId
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analystExposuresCsv: async (studyId: number, organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('analystExposuresCsv', 'studyId', studyId);
      const localVarPath = `/api/v1/export/analyst/studies/{studyId}/exposures`.replace(
        `{${'studyId'}}`,
        encodeURIComponent(String(studyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} studyId
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analystIllnessesCsv: async (studyId: number, organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('analystIllnessesCsv', 'studyId', studyId);
      const localVarPath = `/api/v1/export/analyst/studies/{studyId}/illnesses`.replace(
        `{${'studyId'}}`,
        encodeURIComponent(String(studyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} studyId
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analystInjuriesCsv: async (studyId: number, organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('analystInjuriesCsv', 'studyId', studyId);
      const localVarPath = `/api/v1/export/analyst/studies/{studyId}/injuries`.replace(
        `{${'studyId'}}`,
        encodeURIComponent(String(studyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} studyId
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analystPlayersCsv: async (studyId: number, organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('analystPlayersCsv', 'studyId', studyId);
      const localVarPath = `/api/v1/export/analyst/studies/{studyId}/players`.replace(
        `{${'studyId'}}`,
        encodeURIComponent(String(studyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [apiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArchivedData: async (apiKey?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/export/studies/archived`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (apiKey !== undefined) {
        localVarQueryParameter['apiKey'] = apiKey;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [apiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLiveData: async (apiKey?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/export/studies/live`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (apiKey !== undefined) {
        localVarQueryParameter['apiKey'] = apiKey;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalAdminOrganisationStudyCsv: async (
      organisationId: number,
      studyId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('medicalAdminOrganisationStudyCsv', 'organisationId', organisationId);
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('medicalAdminOrganisationStudyCsv', 'studyId', studyId);
      const localVarPath = `/api/v1/export/medicalAdmin/organisations/{organisationId}/studies/{studyId}`
        .replace(`{${'organisationId'}}`, encodeURIComponent(String(organisationId)))
        .replace(`{${'studyId'}}`, encodeURIComponent(String(studyId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reporterExposuresCsv: async (organisationId: number, studyId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('reporterExposuresCsv', 'organisationId', organisationId);
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('reporterExposuresCsv', 'studyId', studyId);
      const localVarPath = `/api/v1/export/reporter/organisations/{organisationId}/studies/{studyId}/exposures`
        .replace(`{${'organisationId'}}`, encodeURIComponent(String(organisationId)))
        .replace(`{${'studyId'}}`, encodeURIComponent(String(studyId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reporterIllnessesCsv: async (organisationId: number, studyId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('reporterIllnessesCsv', 'organisationId', organisationId);
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('reporterIllnessesCsv', 'studyId', studyId);
      const localVarPath = `/api/v1/export/reporter/organisations/{organisationId}/studies/{studyId}/illnesses`
        .replace(`{${'organisationId'}}`, encodeURIComponent(String(organisationId)))
        .replace(`{${'studyId'}}`, encodeURIComponent(String(studyId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reporterInjuriesCsv: async (organisationId: number, studyId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('reporterInjuriesCsv', 'organisationId', organisationId);
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('reporterInjuriesCsv', 'studyId', studyId);
      const localVarPath = `/api/v1/export/reporter/organisations/{organisationId}/studies/{studyId}/injuries`
        .replace(`{${'organisationId'}}`, encodeURIComponent(String(organisationId)))
        .replace(`{${'studyId'}}`, encodeURIComponent(String(studyId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [apiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    systemUsers: async (apiKey?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/export/systemUsers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (apiKey !== undefined) {
        localVarQueryParameter['apiKey'] = apiKey;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} dataType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async acceptCsvExportDisclaimer(
      dataType: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.acceptCsvExportDisclaimer(dataType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} studyId
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async analystExposuresCsv(
      studyId: number,
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.analystExposuresCsv(studyId, organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} studyId
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async analystIllnessesCsv(
      studyId: number,
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.analystIllnessesCsv(studyId, organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} studyId
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async analystInjuriesCsv(
      studyId: number,
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.analystInjuriesCsv(studyId, organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} studyId
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async analystPlayersCsv(
      studyId: number,
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.analystPlayersCsv(studyId, organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [apiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArchivedData(
      apiKey?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExportEventModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArchivedData(apiKey, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [apiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLiveData(
      apiKey?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExportEventModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLiveData(apiKey, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalAdminOrganisationStudyCsv(
      organisationId: number,
      studyId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.medicalAdminOrganisationStudyCsv(
        organisationId,
        studyId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reporterExposuresCsv(
      organisationId: number,
      studyId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reporterExposuresCsv(organisationId, studyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reporterIllnessesCsv(
      organisationId: number,
      studyId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reporterIllnessesCsv(organisationId, studyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reporterInjuriesCsv(
      organisationId: number,
      studyId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reporterInjuriesCsv(organisationId, studyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [apiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async systemUsers(
      apiKey?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemUserExportModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.systemUsers(apiKey, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ExportApiFp(configuration);
  return {
    /**
     *
     * @param {string} dataType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptCsvExportDisclaimer(dataType: string, options?: any): AxiosPromise<void> {
      return localVarFp.acceptCsvExportDisclaimer(dataType, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} studyId
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analystExposuresCsv(studyId: number, organisationId?: number, options?: any): AxiosPromise<string> {
      return localVarFp.analystExposuresCsv(studyId, organisationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} studyId
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analystIllnessesCsv(studyId: number, organisationId?: number, options?: any): AxiosPromise<string> {
      return localVarFp.analystIllnessesCsv(studyId, organisationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} studyId
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analystInjuriesCsv(studyId: number, organisationId?: number, options?: any): AxiosPromise<string> {
      return localVarFp.analystInjuriesCsv(studyId, organisationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} studyId
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analystPlayersCsv(studyId: number, organisationId?: number, options?: any): AxiosPromise<string> {
      return localVarFp.analystPlayersCsv(studyId, organisationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [apiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArchivedData(apiKey?: string, options?: any): AxiosPromise<Array<ExportEventModel>> {
      return localVarFp.getArchivedData(apiKey, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [apiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLiveData(apiKey?: string, options?: any): AxiosPromise<Array<ExportEventModel>> {
      return localVarFp.getLiveData(apiKey, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalAdminOrganisationStudyCsv(organisationId: number, studyId: number, options?: any): AxiosPromise<string> {
      return localVarFp
        .medicalAdminOrganisationStudyCsv(organisationId, studyId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reporterExposuresCsv(organisationId: number, studyId: number, options?: any): AxiosPromise<string> {
      return localVarFp
        .reporterExposuresCsv(organisationId, studyId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reporterIllnessesCsv(organisationId: number, studyId: number, options?: any): AxiosPromise<string> {
      return localVarFp
        .reporterIllnessesCsv(organisationId, studyId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reporterInjuriesCsv(organisationId: number, studyId: number, options?: any): AxiosPromise<string> {
      return localVarFp.reporterInjuriesCsv(organisationId, studyId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [apiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    systemUsers(apiKey?: string, options?: any): AxiosPromise<Array<SystemUserExportModel>> {
      return localVarFp.systemUsers(apiKey, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ExportApi - interface
 * @export
 * @interface ExportApi
 */
export interface ExportApiInterface {
  /**
   *
   * @param {string} dataType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApiInterface
   */
  acceptCsvExportDisclaimer(dataType: string, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} studyId
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApiInterface
   */
  analystExposuresCsv(studyId: number, organisationId?: number, options?: any): AxiosPromise<string>;

  /**
   *
   * @param {number} studyId
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApiInterface
   */
  analystIllnessesCsv(studyId: number, organisationId?: number, options?: any): AxiosPromise<string>;

  /**
   *
   * @param {number} studyId
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApiInterface
   */
  analystInjuriesCsv(studyId: number, organisationId?: number, options?: any): AxiosPromise<string>;

  /**
   *
   * @param {number} studyId
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApiInterface
   */
  analystPlayersCsv(studyId: number, organisationId?: number, options?: any): AxiosPromise<string>;

  /**
   *
   * @param {string} [apiKey]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApiInterface
   */
  getArchivedData(apiKey?: string, options?: any): AxiosPromise<Array<ExportEventModel>>;

  /**
   *
   * @param {string} [apiKey]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApiInterface
   */
  getLiveData(apiKey?: string, options?: any): AxiosPromise<Array<ExportEventModel>>;

  /**
   *
   * @param {number} organisationId
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApiInterface
   */
  medicalAdminOrganisationStudyCsv(organisationId: number, studyId: number, options?: any): AxiosPromise<string>;

  /**
   *
   * @param {number} organisationId
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApiInterface
   */
  reporterExposuresCsv(organisationId: number, studyId: number, options?: any): AxiosPromise<string>;

  /**
   *
   * @param {number} organisationId
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApiInterface
   */
  reporterIllnessesCsv(organisationId: number, studyId: number, options?: any): AxiosPromise<string>;

  /**
   *
   * @param {number} organisationId
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApiInterface
   */
  reporterInjuriesCsv(organisationId: number, studyId: number, options?: any): AxiosPromise<string>;

  /**
   *
   * @param {string} [apiKey]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApiInterface
   */
  systemUsers(apiKey?: string, options?: any): AxiosPromise<Array<SystemUserExportModel>>;
}

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI implements ExportApiInterface {
  /**
   *
   * @param {string} dataType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public acceptCsvExportDisclaimer(dataType: string, options?: any) {
    return ExportApiFp(this.configuration)
      .acceptCsvExportDisclaimer(dataType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} studyId
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public analystExposuresCsv(studyId: number, organisationId?: number, options?: any) {
    return ExportApiFp(this.configuration)
      .analystExposuresCsv(studyId, organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} studyId
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public analystIllnessesCsv(studyId: number, organisationId?: number, options?: any) {
    return ExportApiFp(this.configuration)
      .analystIllnessesCsv(studyId, organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} studyId
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public analystInjuriesCsv(studyId: number, organisationId?: number, options?: any) {
    return ExportApiFp(this.configuration)
      .analystInjuriesCsv(studyId, organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} studyId
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public analystPlayersCsv(studyId: number, organisationId?: number, options?: any) {
    return ExportApiFp(this.configuration)
      .analystPlayersCsv(studyId, organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [apiKey]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public getArchivedData(apiKey?: string, options?: any) {
    return ExportApiFp(this.configuration)
      .getArchivedData(apiKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [apiKey]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public getLiveData(apiKey?: string, options?: any) {
    return ExportApiFp(this.configuration)
      .getLiveData(apiKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} organisationId
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public medicalAdminOrganisationStudyCsv(organisationId: number, studyId: number, options?: any) {
    return ExportApiFp(this.configuration)
      .medicalAdminOrganisationStudyCsv(organisationId, studyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} organisationId
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public reporterExposuresCsv(organisationId: number, studyId: number, options?: any) {
    return ExportApiFp(this.configuration)
      .reporterExposuresCsv(organisationId, studyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} organisationId
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public reporterIllnessesCsv(organisationId: number, studyId: number, options?: any) {
    return ExportApiFp(this.configuration)
      .reporterIllnessesCsv(organisationId, studyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} organisationId
   * @param {number} studyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public reporterInjuriesCsv(organisationId: number, studyId: number, options?: any) {
    return ExportApiFp(this.configuration)
      .reporterInjuriesCsv(organisationId, studyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [apiKey]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public systemUsers(apiKey?: string, options?: any) {
    return ExportApiFp(this.configuration)
      .systemUsers(apiKey, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ExposuresApi - axios parameter creator
 * @export
 */
export const ExposuresApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {ExposureModel} [exposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createExposure: async (exposureModel?: ExposureModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/exposures`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(exposureModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ExposureCollectionModel} [exposureCollectionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createExposureSimple: async (
      exposureCollectionModel?: ExposureCollectionModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/exposures/simple`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        exposureCollectionModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExposure: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteExposure', 'id', id);
      const localVarPath = `/api/v1/exposures/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ExposureGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterExposures: async (filter?: ExposureGridFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/exposures/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExposure: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getExposure', 'id', id);
      const localVarPath = `/api/v1/exposures/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExposureFormLookupData: async (organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/exposures/formLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} forYear
     * @param {number} forMonth
     * @param {number} [organisationId]
     * @param {number} [studyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExposuresCalendarSummary: async (
      forYear: number,
      forMonth: number,
      organisationId?: number,
      studyId?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'forYear' is not null or undefined
      assertParamExists('getExposuresCalendarSummary', 'forYear', forYear);
      // verify required parameter 'forMonth' is not null or undefined
      assertParamExists('getExposuresCalendarSummary', 'forMonth', forMonth);
      const localVarPath = `/api/v1/exposures/{forYear}/{forMonth}/summary`
        .replace(`{${'forYear'}}`, encodeURIComponent(String(forYear)))
        .replace(`{${'forMonth'}}`, encodeURIComponent(String(forMonth)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (studyId !== undefined) {
        localVarQueryParameter['studyId'] = studyId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ExposureModel} [exposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExposure: async (exposureModel?: ExposureModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/exposures`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(exposureModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ExposureCollectionModel} [exposureCollectionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExposureSimple: async (
      exposureCollectionModel?: ExposureCollectionModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/exposures/simple`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        exposureCollectionModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExposuresApi - functional programming interface
 * @export
 */
export const ExposuresApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ExposuresApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ExposureModel} [exposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createExposure(
      exposureModel?: ExposureModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExposureModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createExposure(exposureModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ExposureCollectionModel} [exposureCollectionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createExposureSimple(
      exposureCollectionModel?: ExposureCollectionModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createExposureSimple(exposureCollectionModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteExposure(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExposure(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ExposureGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterExposures(
      filter?: ExposureGridFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExposureCollectionModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterExposures(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExposure(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExposureModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getExposure(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExposureFormLookupData(
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExposureLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getExposureFormLookupData(organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} forYear
     * @param {number} forMonth
     * @param {number} [organisationId]
     * @param {number} [studyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExposuresCalendarSummary(
      forYear: number,
      forMonth: number,
      organisationId?: number,
      studyId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExposureCalendarModelCalendarModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getExposuresCalendarSummary(
        forYear,
        forMonth,
        organisationId,
        studyId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ExposureModel} [exposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateExposure(
      exposureModel?: ExposureModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExposureModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateExposure(exposureModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ExposureCollectionModel} [exposureCollectionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateExposureSimple(
      exposureCollectionModel?: ExposureCollectionModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateExposureSimple(exposureCollectionModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ExposuresApi - factory interface
 * @export
 */
export const ExposuresApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ExposuresApiFp(configuration);
  return {
    /**
     *
     * @param {ExposureModel} [exposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createExposure(exposureModel?: ExposureModel, options?: any): AxiosPromise<ExposureModel> {
      return localVarFp.createExposure(exposureModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ExposureCollectionModel} [exposureCollectionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createExposureSimple(exposureCollectionModel?: ExposureCollectionModel, options?: any): AxiosPromise<number> {
      return localVarFp
        .createExposureSimple(exposureCollectionModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExposure(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteExposure(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ExposureGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterExposures(filter?: ExposureGridFilter, options?: any): AxiosPromise<ExposureCollectionModelGridCollection> {
      return localVarFp.filterExposures(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExposure(id: number, options?: any): AxiosPromise<ExposureModel> {
      return localVarFp.getExposure(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExposureFormLookupData(organisationId?: number, options?: any): AxiosPromise<ExposureLookupModel> {
      return localVarFp.getExposureFormLookupData(organisationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} forYear
     * @param {number} forMonth
     * @param {number} [organisationId]
     * @param {number} [studyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExposuresCalendarSummary(
      forYear: number,
      forMonth: number,
      organisationId?: number,
      studyId?: number,
      options?: any,
    ): AxiosPromise<ExposureCalendarModelCalendarModel> {
      return localVarFp
        .getExposuresCalendarSummary(forYear, forMonth, organisationId, studyId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ExposureModel} [exposureModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExposure(exposureModel?: ExposureModel, options?: any): AxiosPromise<ExposureModel> {
      return localVarFp.updateExposure(exposureModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ExposureCollectionModel} [exposureCollectionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExposureSimple(exposureCollectionModel?: ExposureCollectionModel, options?: any): AxiosPromise<void> {
      return localVarFp
        .updateExposureSimple(exposureCollectionModel, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ExposuresApi - interface
 * @export
 * @interface ExposuresApi
 */
export interface ExposuresApiInterface {
  /**
   *
   * @param {ExposureModel} [exposureModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApiInterface
   */
  createExposure(exposureModel?: ExposureModel, options?: any): AxiosPromise<ExposureModel>;

  /**
   *
   * @param {ExposureCollectionModel} [exposureCollectionModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApiInterface
   */
  createExposureSimple(exposureCollectionModel?: ExposureCollectionModel, options?: any): AxiosPromise<number>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApiInterface
   */
  deleteExposure(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {ExposureGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApiInterface
   */
  filterExposures(filter?: ExposureGridFilter, options?: any): AxiosPromise<ExposureCollectionModelGridCollection>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApiInterface
   */
  getExposure(id: number, options?: any): AxiosPromise<ExposureModel>;

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApiInterface
   */
  getExposureFormLookupData(organisationId?: number, options?: any): AxiosPromise<ExposureLookupModel>;

  /**
   *
   * @param {number} forYear
   * @param {number} forMonth
   * @param {number} [organisationId]
   * @param {number} [studyId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApiInterface
   */
  getExposuresCalendarSummary(
    forYear: number,
    forMonth: number,
    organisationId?: number,
    studyId?: number,
    options?: any,
  ): AxiosPromise<ExposureCalendarModelCalendarModel>;

  /**
   *
   * @param {ExposureModel} [exposureModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApiInterface
   */
  updateExposure(exposureModel?: ExposureModel, options?: any): AxiosPromise<ExposureModel>;

  /**
   *
   * @param {ExposureCollectionModel} [exposureCollectionModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApiInterface
   */
  updateExposureSimple(exposureCollectionModel?: ExposureCollectionModel, options?: any): AxiosPromise<void>;
}

/**
 * ExposuresApi - object-oriented interface
 * @export
 * @class ExposuresApi
 * @extends {BaseAPI}
 */
export class ExposuresApi extends BaseAPI implements ExposuresApiInterface {
  /**
   *
   * @param {ExposureModel} [exposureModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApi
   */
  public createExposure(exposureModel?: ExposureModel, options?: any) {
    return ExposuresApiFp(this.configuration)
      .createExposure(exposureModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ExposureCollectionModel} [exposureCollectionModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApi
   */
  public createExposureSimple(exposureCollectionModel?: ExposureCollectionModel, options?: any) {
    return ExposuresApiFp(this.configuration)
      .createExposureSimple(exposureCollectionModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApi
   */
  public deleteExposure(id: number, options?: any) {
    return ExposuresApiFp(this.configuration)
      .deleteExposure(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ExposureGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApi
   */
  public filterExposures(filter?: ExposureGridFilter, options?: any) {
    return ExposuresApiFp(this.configuration)
      .filterExposures(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApi
   */
  public getExposure(id: number, options?: any) {
    return ExposuresApiFp(this.configuration)
      .getExposure(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApi
   */
  public getExposureFormLookupData(organisationId?: number, options?: any) {
    return ExposuresApiFp(this.configuration)
      .getExposureFormLookupData(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} forYear
   * @param {number} forMonth
   * @param {number} [organisationId]
   * @param {number} [studyId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApi
   */
  public getExposuresCalendarSummary(
    forYear: number,
    forMonth: number,
    organisationId?: number,
    studyId?: number,
    options?: any,
  ) {
    return ExposuresApiFp(this.configuration)
      .getExposuresCalendarSummary(forYear, forMonth, organisationId, studyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ExposureModel} [exposureModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApi
   */
  public updateExposure(exposureModel?: ExposureModel, options?: any) {
    return ExposuresApiFp(this.configuration)
      .updateExposure(exposureModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ExposureCollectionModel} [exposureCollectionModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExposuresApi
   */
  public updateExposureSimple(exposureCollectionModel?: ExposureCollectionModel, options?: any) {
    return ExposuresApiFp(this.configuration)
      .updateExposureSimple(exposureCollectionModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * HomeApi - axios parameter creator
 * @export
 */
export const HomeApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Checks health of the API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    welcome: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HomeApi - functional programming interface
 * @export
 */
export const HomeApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = HomeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Checks health of the API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async welcome(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.welcome(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * HomeApi - factory interface
 * @export
 */
export const HomeApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = HomeApiFp(configuration);
  return {
    /**
     *
     * @summary Checks health of the API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    welcome(options?: any): AxiosPromise<string> {
      return localVarFp.welcome(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * HomeApi - interface
 * @export
 * @interface HomeApi
 */
export interface HomeApiInterface {
  /**
   *
   * @summary Checks health of the API.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HomeApiInterface
   */
  welcome(options?: any): AxiosPromise<string>;
}

/**
 * HomeApi - object-oriented interface
 * @export
 * @class HomeApi
 * @extends {BaseAPI}
 */
export class HomeApi extends BaseAPI implements HomeApiInterface {
  /**
   *
   * @summary Checks health of the API.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HomeApi
   */
  public welcome(options?: any) {
    return HomeApiFp(this.configuration)
      .welcome(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * IllnessQualityControlApi - axios parameter creator
 * @export
 */
export const IllnessQualityControlApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {IllnessQualityControlModel} [illnessQualityControlModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createIllnessQualityControl: async (
      illnessQualityControlModel?: IllnessQualityControlModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/illnessQualityControl`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        illnessQualityControlModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} illnessId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIllnessQualityControl: async (illnessId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'illnessId' is not null or undefined
      assertParamExists('getIllnessQualityControl', 'illnessId', illnessId);
      const localVarPath = `/api/v1/illnessQualityControl/{illnessId}`.replace(
        `{${'illnessId'}}`,
        encodeURIComponent(String(illnessId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IllnessQualityControlModel} [illnessQualityControlModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateIllnessQualityControl: async (
      illnessQualityControlModel?: IllnessQualityControlModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/illnessQualityControl`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        illnessQualityControlModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * IllnessQualityControlApi - functional programming interface
 * @export
 */
export const IllnessQualityControlApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = IllnessQualityControlApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {IllnessQualityControlModel} [illnessQualityControlModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createIllnessQualityControl(
      illnessQualityControlModel?: IllnessQualityControlModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IllnessQualityControlModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createIllnessQualityControl(
        illnessQualityControlModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} illnessId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIllnessQualityControl(
      illnessId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IllnessQualityControlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIllnessQualityControl(illnessId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IllnessQualityControlModel} [illnessQualityControlModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateIllnessQualityControl(
      illnessQualityControlModel?: IllnessQualityControlModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IllnessQualityControlModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateIllnessQualityControl(
        illnessQualityControlModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * IllnessQualityControlApi - factory interface
 * @export
 */
export const IllnessQualityControlApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = IllnessQualityControlApiFp(configuration);
  return {
    /**
     *
     * @param {IllnessQualityControlModel} [illnessQualityControlModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createIllnessQualityControl(
      illnessQualityControlModel?: IllnessQualityControlModel,
      options?: any,
    ): AxiosPromise<IllnessQualityControlModel> {
      return localVarFp
        .createIllnessQualityControl(illnessQualityControlModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} illnessId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIllnessQualityControl(illnessId: number, options?: any): AxiosPromise<IllnessQualityControlResponse> {
      return localVarFp.getIllnessQualityControl(illnessId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {IllnessQualityControlModel} [illnessQualityControlModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateIllnessQualityControl(
      illnessQualityControlModel?: IllnessQualityControlModel,
      options?: any,
    ): AxiosPromise<IllnessQualityControlModel> {
      return localVarFp
        .updateIllnessQualityControl(illnessQualityControlModel, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * IllnessQualityControlApi - interface
 * @export
 * @interface IllnessQualityControlApi
 */
export interface IllnessQualityControlApiInterface {
  /**
   *
   * @param {IllnessQualityControlModel} [illnessQualityControlModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessQualityControlApiInterface
   */
  createIllnessQualityControl(
    illnessQualityControlModel?: IllnessQualityControlModel,
    options?: any,
  ): AxiosPromise<IllnessQualityControlModel>;

  /**
   *
   * @param {number} illnessId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessQualityControlApiInterface
   */
  getIllnessQualityControl(illnessId: number, options?: any): AxiosPromise<IllnessQualityControlResponse>;

  /**
   *
   * @param {IllnessQualityControlModel} [illnessQualityControlModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessQualityControlApiInterface
   */
  updateIllnessQualityControl(
    illnessQualityControlModel?: IllnessQualityControlModel,
    options?: any,
  ): AxiosPromise<IllnessQualityControlModel>;
}

/**
 * IllnessQualityControlApi - object-oriented interface
 * @export
 * @class IllnessQualityControlApi
 * @extends {BaseAPI}
 */
export class IllnessQualityControlApi extends BaseAPI implements IllnessQualityControlApiInterface {
  /**
   *
   * @param {IllnessQualityControlModel} [illnessQualityControlModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessQualityControlApi
   */
  public createIllnessQualityControl(illnessQualityControlModel?: IllnessQualityControlModel, options?: any) {
    return IllnessQualityControlApiFp(this.configuration)
      .createIllnessQualityControl(illnessQualityControlModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} illnessId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessQualityControlApi
   */
  public getIllnessQualityControl(illnessId: number, options?: any) {
    return IllnessQualityControlApiFp(this.configuration)
      .getIllnessQualityControl(illnessId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {IllnessQualityControlModel} [illnessQualityControlModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessQualityControlApi
   */
  public updateIllnessQualityControl(illnessQualityControlModel?: IllnessQualityControlModel, options?: any) {
    return IllnessQualityControlApiFp(this.configuration)
      .updateIllnessQualityControl(illnessQualityControlModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * IllnessesApi - axios parameter creator
 * @export
 */
export const IllnessesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {IllnessModel} [illnessModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createIllness: async (illnessModel?: IllnessModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/illnesses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(illnessModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} illnessId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteIllness: async (illnessId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'illnessId' is not null or undefined
      assertParamExists('deleteIllness', 'illnessId', illnessId);
      const localVarPath = `/api/v1/illnesses/{illnessId}`.replace(
        `{${'illnessId'}}`,
        encodeURIComponent(String(illnessId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IllnessGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterIllnesses: async (filter?: IllnessGridFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/illnesses/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} illnessId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIllness: async (illnessId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'illnessId' is not null or undefined
      assertParamExists('getIllness', 'illnessId', illnessId);
      const localVarPath = `/api/v1/illnesses/{illnessId}`.replace(
        `{${'illnessId'}}`,
        encodeURIComponent(String(illnessId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIllnessCollectionLookupData: async (organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/illnesses/collectionLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} playerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIllnessFormLookupData: async (playerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists('getIllnessFormLookupData', 'playerId', playerId);
      const localVarPath = `/api/v1/illnesses/formLookupData/{playerId}`.replace(
        `{${'playerId'}}`,
        encodeURIComponent(String(playerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IllnessModel} [illnessModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateIllness: async (illnessModel?: IllnessModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/illnesses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(illnessModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * IllnessesApi - functional programming interface
 * @export
 */
export const IllnessesApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = IllnessesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {IllnessModel} [illnessModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createIllness(
      illnessModel?: IllnessModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IllnessModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createIllness(illnessModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} illnessId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteIllness(
      illnessId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIllness(illnessId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IllnessGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterIllnesses(
      filter?: IllnessGridFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IllnessCollectionModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterIllnesses(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} illnessId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIllness(
      illnessId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IllnessModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIllness(illnessId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIllnessCollectionLookupData(
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IllnessCollectionLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIllnessCollectionLookupData(organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} playerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIllnessFormLookupData(
      playerId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IllnessLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIllnessFormLookupData(playerId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IllnessModel} [illnessModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateIllness(
      illnessModel?: IllnessModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IllnessModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateIllness(illnessModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * IllnessesApi - factory interface
 * @export
 */
export const IllnessesApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = IllnessesApiFp(configuration);
  return {
    /**
     *
     * @param {IllnessModel} [illnessModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createIllness(illnessModel?: IllnessModel, options?: any): AxiosPromise<IllnessModel> {
      return localVarFp.createIllness(illnessModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} illnessId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteIllness(illnessId: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteIllness(illnessId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {IllnessGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterIllnesses(filter?: IllnessGridFilter, options?: any): AxiosPromise<IllnessCollectionModelGridCollection> {
      return localVarFp.filterIllnesses(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} illnessId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIllness(illnessId: number, options?: any): AxiosPromise<IllnessModel> {
      return localVarFp.getIllness(illnessId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIllnessCollectionLookupData(organisationId?: number, options?: any): AxiosPromise<IllnessCollectionLookupModel> {
      return localVarFp
        .getIllnessCollectionLookupData(organisationId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} playerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIllnessFormLookupData(playerId: number, options?: any): AxiosPromise<IllnessLookupModel> {
      return localVarFp.getIllnessFormLookupData(playerId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {IllnessModel} [illnessModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateIllness(illnessModel?: IllnessModel, options?: any): AxiosPromise<IllnessModel> {
      return localVarFp.updateIllness(illnessModel, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * IllnessesApi - interface
 * @export
 * @interface IllnessesApi
 */
export interface IllnessesApiInterface {
  /**
   *
   * @param {IllnessModel} [illnessModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApiInterface
   */
  createIllness(illnessModel?: IllnessModel, options?: any): AxiosPromise<IllnessModel>;

  /**
   *
   * @param {number} illnessId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApiInterface
   */
  deleteIllness(illnessId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {IllnessGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApiInterface
   */
  filterIllnesses(filter?: IllnessGridFilter, options?: any): AxiosPromise<IllnessCollectionModelGridCollection>;

  /**
   *
   * @param {number} illnessId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApiInterface
   */
  getIllness(illnessId: number, options?: any): AxiosPromise<IllnessModel>;

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApiInterface
   */
  getIllnessCollectionLookupData(organisationId?: number, options?: any): AxiosPromise<IllnessCollectionLookupModel>;

  /**
   *
   * @param {number} playerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApiInterface
   */
  getIllnessFormLookupData(playerId: number, options?: any): AxiosPromise<IllnessLookupModel>;

  /**
   *
   * @param {IllnessModel} [illnessModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApiInterface
   */
  updateIllness(illnessModel?: IllnessModel, options?: any): AxiosPromise<IllnessModel>;
}

/**
 * IllnessesApi - object-oriented interface
 * @export
 * @class IllnessesApi
 * @extends {BaseAPI}
 */
export class IllnessesApi extends BaseAPI implements IllnessesApiInterface {
  /**
   *
   * @param {IllnessModel} [illnessModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApi
   */
  public createIllness(illnessModel?: IllnessModel, options?: any) {
    return IllnessesApiFp(this.configuration)
      .createIllness(illnessModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} illnessId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApi
   */
  public deleteIllness(illnessId: number, options?: any) {
    return IllnessesApiFp(this.configuration)
      .deleteIllness(illnessId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {IllnessGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApi
   */
  public filterIllnesses(filter?: IllnessGridFilter, options?: any) {
    return IllnessesApiFp(this.configuration)
      .filterIllnesses(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} illnessId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApi
   */
  public getIllness(illnessId: number, options?: any) {
    return IllnessesApiFp(this.configuration)
      .getIllness(illnessId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApi
   */
  public getIllnessCollectionLookupData(organisationId?: number, options?: any) {
    return IllnessesApiFp(this.configuration)
      .getIllnessCollectionLookupData(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} playerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApi
   */
  public getIllnessFormLookupData(playerId: number, options?: any) {
    return IllnessesApiFp(this.configuration)
      .getIllnessFormLookupData(playerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {IllnessModel} [illnessModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IllnessesApi
   */
  public updateIllness(illnessModel?: IllnessModel, options?: any) {
    return IllnessesApiFp(this.configuration)
      .updateIllness(illnessModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * InjuriesApi - axios parameter creator
 * @export
 */
export const InjuriesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {InjuryModel} [injuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInjury: async (injuryModel?: InjuryModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/injuries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(injuryModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInjury: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteInjury', 'id', id);
      const localVarPath = `/api/v1/injuries/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {InjuryGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterInjuries: async (filter?: InjuryGridFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/injuries/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInjury: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getInjury', 'id', id);
      const localVarPath = `/api/v1/injuries/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInjuryCollectionLookupData: async (organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/injuries/collectionLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} playerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInjuryFormLookupData: async (playerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists('getInjuryFormLookupData', 'playerId', playerId);
      const localVarPath = `/api/v1/injuries/formLookupData/{playerId}`.replace(
        `{${'playerId'}}`,
        encodeURIComponent(String(playerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {InjuryModel} [injuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInjury: async (injuryModel?: InjuryModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/injuries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(injuryModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InjuriesApi - functional programming interface
 * @export
 */
export const InjuriesApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = InjuriesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {InjuryModel} [injuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInjury(
      injuryModel?: InjuryModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InjuryModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createInjury(injuryModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteInjury(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInjury(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {InjuryGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterInjuries(
      filter?: InjuryGridFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InjuryCollectionModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterInjuries(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInjury(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InjuryModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInjury(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInjuryCollectionLookupData(
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InjuryCollectionLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInjuryCollectionLookupData(organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} playerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInjuryFormLookupData(
      playerId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InjuryLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInjuryFormLookupData(playerId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {InjuryModel} [injuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInjury(
      injuryModel?: InjuryModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InjuryModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInjury(injuryModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InjuriesApi - factory interface
 * @export
 */
export const InjuriesApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = InjuriesApiFp(configuration);
  return {
    /**
     *
     * @param {InjuryModel} [injuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInjury(injuryModel?: InjuryModel, options?: any): AxiosPromise<InjuryModel> {
      return localVarFp.createInjury(injuryModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInjury(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteInjury(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {InjuryGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterInjuries(filter?: InjuryGridFilter, options?: any): AxiosPromise<InjuryCollectionModelGridCollection> {
      return localVarFp.filterInjuries(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInjury(id: number, options?: any): AxiosPromise<InjuryModel> {
      return localVarFp.getInjury(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInjuryCollectionLookupData(organisationId?: number, options?: any): AxiosPromise<InjuryCollectionLookupModel> {
      return localVarFp
        .getInjuryCollectionLookupData(organisationId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} playerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInjuryFormLookupData(playerId: number, options?: any): AxiosPromise<InjuryLookupModel> {
      return localVarFp.getInjuryFormLookupData(playerId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {InjuryModel} [injuryModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInjury(injuryModel?: InjuryModel, options?: any): AxiosPromise<InjuryModel> {
      return localVarFp.updateInjury(injuryModel, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * InjuriesApi - interface
 * @export
 * @interface InjuriesApi
 */
export interface InjuriesApiInterface {
  /**
   *
   * @param {InjuryModel} [injuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApiInterface
   */
  createInjury(injuryModel?: InjuryModel, options?: any): AxiosPromise<InjuryModel>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApiInterface
   */
  deleteInjury(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {InjuryGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApiInterface
   */
  filterInjuries(filter?: InjuryGridFilter, options?: any): AxiosPromise<InjuryCollectionModelGridCollection>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApiInterface
   */
  getInjury(id: number, options?: any): AxiosPromise<InjuryModel>;

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApiInterface
   */
  getInjuryCollectionLookupData(organisationId?: number, options?: any): AxiosPromise<InjuryCollectionLookupModel>;

  /**
   *
   * @param {number} playerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApiInterface
   */
  getInjuryFormLookupData(playerId: number, options?: any): AxiosPromise<InjuryLookupModel>;

  /**
   *
   * @param {InjuryModel} [injuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApiInterface
   */
  updateInjury(injuryModel?: InjuryModel, options?: any): AxiosPromise<InjuryModel>;
}

/**
 * InjuriesApi - object-oriented interface
 * @export
 * @class InjuriesApi
 * @extends {BaseAPI}
 */
export class InjuriesApi extends BaseAPI implements InjuriesApiInterface {
  /**
   *
   * @param {InjuryModel} [injuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApi
   */
  public createInjury(injuryModel?: InjuryModel, options?: any) {
    return InjuriesApiFp(this.configuration)
      .createInjury(injuryModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApi
   */
  public deleteInjury(id: number, options?: any) {
    return InjuriesApiFp(this.configuration)
      .deleteInjury(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {InjuryGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApi
   */
  public filterInjuries(filter?: InjuryGridFilter, options?: any) {
    return InjuriesApiFp(this.configuration)
      .filterInjuries(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApi
   */
  public getInjury(id: number, options?: any) {
    return InjuriesApiFp(this.configuration)
      .getInjury(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApi
   */
  public getInjuryCollectionLookupData(organisationId?: number, options?: any) {
    return InjuriesApiFp(this.configuration)
      .getInjuryCollectionLookupData(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} playerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApi
   */
  public getInjuryFormLookupData(playerId: number, options?: any) {
    return InjuriesApiFp(this.configuration)
      .getInjuryFormLookupData(playerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {InjuryModel} [injuryModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuriesApi
   */
  public updateInjury(injuryModel?: InjuryModel, options?: any) {
    return InjuriesApiFp(this.configuration)
      .updateInjury(injuryModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * InjuryQualityControlApi - axios parameter creator
 * @export
 */
export const InjuryQualityControlApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {InjuryQualityControlModel} [injuryQualityControlModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInjuryQualityControl: async (
      injuryQualityControlModel?: InjuryQualityControlModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/injuryQualityControl`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        injuryQualityControlModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} injuryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInjuryQualityControl: async (injuryId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'injuryId' is not null or undefined
      assertParamExists('getInjuryQualityControl', 'injuryId', injuryId);
      const localVarPath = `/api/v1/injuryQualityControl/{injuryId}`.replace(
        `{${'injuryId'}}`,
        encodeURIComponent(String(injuryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {InjuryQualityControlModel} [injuryQualityControlModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInjuryQualityControl: async (
      injuryQualityControlModel?: InjuryQualityControlModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/injuryQualityControl`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        injuryQualityControlModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InjuryQualityControlApi - functional programming interface
 * @export
 */
export const InjuryQualityControlApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = InjuryQualityControlApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {InjuryQualityControlModel} [injuryQualityControlModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInjuryQualityControl(
      injuryQualityControlModel?: InjuryQualityControlModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InjuryQualityControlModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createInjuryQualityControl(
        injuryQualityControlModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} injuryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInjuryQualityControl(
      injuryId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InjuryQualityControlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInjuryQualityControl(injuryId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {InjuryQualityControlModel} [injuryQualityControlModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInjuryQualityControl(
      injuryQualityControlModel?: InjuryQualityControlModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InjuryQualityControlModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInjuryQualityControl(
        injuryQualityControlModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InjuryQualityControlApi - factory interface
 * @export
 */
export const InjuryQualityControlApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InjuryQualityControlApiFp(configuration);
  return {
    /**
     *
     * @param {InjuryQualityControlModel} [injuryQualityControlModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInjuryQualityControl(
      injuryQualityControlModel?: InjuryQualityControlModel,
      options?: any,
    ): AxiosPromise<InjuryQualityControlModel> {
      return localVarFp
        .createInjuryQualityControl(injuryQualityControlModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} injuryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInjuryQualityControl(injuryId: number, options?: any): AxiosPromise<InjuryQualityControlResponse> {
      return localVarFp.getInjuryQualityControl(injuryId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {InjuryQualityControlModel} [injuryQualityControlModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInjuryQualityControl(
      injuryQualityControlModel?: InjuryQualityControlModel,
      options?: any,
    ): AxiosPromise<InjuryQualityControlModel> {
      return localVarFp
        .updateInjuryQualityControl(injuryQualityControlModel, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * InjuryQualityControlApi - interface
 * @export
 * @interface InjuryQualityControlApi
 */
export interface InjuryQualityControlApiInterface {
  /**
   *
   * @param {InjuryQualityControlModel} [injuryQualityControlModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuryQualityControlApiInterface
   */
  createInjuryQualityControl(
    injuryQualityControlModel?: InjuryQualityControlModel,
    options?: any,
  ): AxiosPromise<InjuryQualityControlModel>;

  /**
   *
   * @param {number} injuryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuryQualityControlApiInterface
   */
  getInjuryQualityControl(injuryId: number, options?: any): AxiosPromise<InjuryQualityControlResponse>;

  /**
   *
   * @param {InjuryQualityControlModel} [injuryQualityControlModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuryQualityControlApiInterface
   */
  updateInjuryQualityControl(
    injuryQualityControlModel?: InjuryQualityControlModel,
    options?: any,
  ): AxiosPromise<InjuryQualityControlModel>;
}

/**
 * InjuryQualityControlApi - object-oriented interface
 * @export
 * @class InjuryQualityControlApi
 * @extends {BaseAPI}
 */
export class InjuryQualityControlApi extends BaseAPI implements InjuryQualityControlApiInterface {
  /**
   *
   * @param {InjuryQualityControlModel} [injuryQualityControlModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuryQualityControlApi
   */
  public createInjuryQualityControl(injuryQualityControlModel?: InjuryQualityControlModel, options?: any) {
    return InjuryQualityControlApiFp(this.configuration)
      .createInjuryQualityControl(injuryQualityControlModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} injuryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuryQualityControlApi
   */
  public getInjuryQualityControl(injuryId: number, options?: any) {
    return InjuryQualityControlApiFp(this.configuration)
      .getInjuryQualityControl(injuryId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {InjuryQualityControlModel} [injuryQualityControlModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InjuryQualityControlApi
   */
  public updateInjuryQualityControl(injuryQualityControlModel?: InjuryQualityControlModel, options?: any) {
    return InjuryQualityControlApiFp(this.configuration)
      .updateInjuryQualityControl(injuryQualityControlModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * OrganisationsApi - axios parameter creator
 * @export
 */
export const OrganisationsApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {OrganisationModel} [organisationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrganisation: async (organisationModel?: OrganisationModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(organisationModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {OrganisationCollectionFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterOrganisations: async (filter?: OrganisationCollectionFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisations/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllReporterOrganisations: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisations/reporter/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInviteKey: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getInviteKey', 'id', id);
      const localVarPath = `/api/v1/organisations/{id}/inviteKey`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManageOrganisationFormLookupData: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisations/formLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMedicalAdminExportableOrganisationsLookupData: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisations/medicalAdmin/exportable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganisation: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getOrganisation', 'id', id);
      const localVarPath = `/api/v1/organisations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganisationStudies: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getOrganisationStudies', 'id', id);
      const localVarPath = `/api/v1/organisations/{id}/studies`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {OrganisationModel} [organisationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganisation: async (organisationModel?: OrganisationModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/organisations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(organisationModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrganisationsApi - functional programming interface
 * @export
 */
export const OrganisationsApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = OrganisationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {OrganisationModel} [organisationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOrganisation(
      organisationModel?: OrganisationModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganisation(organisationModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {OrganisationCollectionFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterOrganisations(
      filter?: OrganisationCollectionFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationCollectionModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterOrganisations(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllReporterOrganisations(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationCollectionModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllReporterOrganisations(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInviteKey(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInviteKey(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getManageOrganisationFormLookupData(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getManageOrganisationFormLookupData(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMedicalAdminExportableOrganisationsLookupData(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalAdminExportableOrganisationsLookupData(
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganisation(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisation(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganisationStudies(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudyCollectionModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisationStudies(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {OrganisationModel} [organisationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateOrganisation(
      organisationModel?: OrganisationModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganisation(organisationModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OrganisationsApi - factory interface
 * @export
 */
export const OrganisationsApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrganisationsApiFp(configuration);
  return {
    /**
     *
     * @param {OrganisationModel} [organisationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrganisation(organisationModel?: OrganisationModel, options?: any): AxiosPromise<OrganisationModel> {
      return localVarFp.createOrganisation(organisationModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {OrganisationCollectionFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterOrganisations(
      filter?: OrganisationCollectionFilter,
      options?: any,
    ): AxiosPromise<OrganisationCollectionModelGridCollection> {
      return localVarFp.filterOrganisations(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllReporterOrganisations(options?: any): AxiosPromise<Array<OrganisationCollectionModel>> {
      return localVarFp.getAllReporterOrganisations(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInviteKey(id: number, options?: any): AxiosPromise<string> {
      return localVarFp.getInviteKey(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManageOrganisationFormLookupData(options?: any): AxiosPromise<OrganisationLookupModel> {
      return localVarFp.getManageOrganisationFormLookupData(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMedicalAdminExportableOrganisationsLookupData(options?: any): AxiosPromise<Array<OrganisationModel>> {
      return localVarFp
        .getMedicalAdminExportableOrganisationsLookupData(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganisation(id: number, options?: any): AxiosPromise<OrganisationModel> {
      return localVarFp.getOrganisation(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganisationStudies(id: number, options?: any): AxiosPromise<Array<StudyCollectionModel>> {
      return localVarFp.getOrganisationStudies(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {OrganisationModel} [organisationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganisation(organisationModel?: OrganisationModel, options?: any): AxiosPromise<OrganisationModel> {
      return localVarFp.updateOrganisation(organisationModel, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * OrganisationsApi - interface
 * @export
 * @interface OrganisationsApi
 */
export interface OrganisationsApiInterface {
  /**
   *
   * @param {OrganisationModel} [organisationModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApiInterface
   */
  createOrganisation(organisationModel?: OrganisationModel, options?: any): AxiosPromise<OrganisationModel>;

  /**
   *
   * @param {OrganisationCollectionFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApiInterface
   */
  filterOrganisations(
    filter?: OrganisationCollectionFilter,
    options?: any,
  ): AxiosPromise<OrganisationCollectionModelGridCollection>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApiInterface
   */
  getAllReporterOrganisations(options?: any): AxiosPromise<Array<OrganisationCollectionModel>>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApiInterface
   */
  getInviteKey(id: number, options?: any): AxiosPromise<string>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApiInterface
   */
  getManageOrganisationFormLookupData(options?: any): AxiosPromise<OrganisationLookupModel>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApiInterface
   */
  getMedicalAdminExportableOrganisationsLookupData(options?: any): AxiosPromise<Array<OrganisationModel>>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApiInterface
   */
  getOrganisation(id: number, options?: any): AxiosPromise<OrganisationModel>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApiInterface
   */
  getOrganisationStudies(id: number, options?: any): AxiosPromise<Array<StudyCollectionModel>>;

  /**
   *
   * @param {OrganisationModel} [organisationModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApiInterface
   */
  updateOrganisation(organisationModel?: OrganisationModel, options?: any): AxiosPromise<OrganisationModel>;
}

/**
 * OrganisationsApi - object-oriented interface
 * @export
 * @class OrganisationsApi
 * @extends {BaseAPI}
 */
export class OrganisationsApi extends BaseAPI implements OrganisationsApiInterface {
  /**
   *
   * @param {OrganisationModel} [organisationModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApi
   */
  public createOrganisation(organisationModel?: OrganisationModel, options?: any) {
    return OrganisationsApiFp(this.configuration)
      .createOrganisation(organisationModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {OrganisationCollectionFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApi
   */
  public filterOrganisations(filter?: OrganisationCollectionFilter, options?: any) {
    return OrganisationsApiFp(this.configuration)
      .filterOrganisations(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApi
   */
  public getAllReporterOrganisations(options?: any) {
    return OrganisationsApiFp(this.configuration)
      .getAllReporterOrganisations(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApi
   */
  public getInviteKey(id: number, options?: any) {
    return OrganisationsApiFp(this.configuration)
      .getInviteKey(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApi
   */
  public getManageOrganisationFormLookupData(options?: any) {
    return OrganisationsApiFp(this.configuration)
      .getManageOrganisationFormLookupData(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApi
   */
  public getMedicalAdminExportableOrganisationsLookupData(options?: any) {
    return OrganisationsApiFp(this.configuration)
      .getMedicalAdminExportableOrganisationsLookupData(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApi
   */
  public getOrganisation(id: number, options?: any) {
    return OrganisationsApiFp(this.configuration)
      .getOrganisation(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApi
   */
  public getOrganisationStudies(id: number, options?: any) {
    return OrganisationsApiFp(this.configuration)
      .getOrganisationStudies(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {OrganisationModel} [organisationModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationsApi
   */
  public updateOrganisation(organisationModel?: OrganisationModel, options?: any) {
    return OrganisationsApiFp(this.configuration)
      .updateOrganisation(organisationModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PlayersApi - axios parameter creator
 * @export
 */
export const PlayersApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} playerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFavourite: async (playerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists('addFavourite', 'playerId', playerId);
      const localVarPath = `/api/v1/players/{playerId}/favourite/add`.replace(
        `{${'playerId'}}`,
        encodeURIComponent(String(playerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    anyFavourites: async (organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/players/anyFavourites`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a new user / player account.
     * @param {PlayerModel} [playerModel] AccountUser model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPlayer: async (playerModel?: PlayerModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/players`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(playerModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PlayerGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterPlayers: async (filter?: PlayerGridFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/players/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManagePlayerFormLookupData: async (organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/players/formLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets a user / player account.
     * @param {number} id User / player id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayer: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getPlayer', 'id', id);
      const localVarPath = `/api/v1/players/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerGridLookupData: async (organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/players/gridLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerImportLookupData: async (organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/players/playerImportLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<PlayerImportModel>} [playerImportModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importPlayers: async (playerImportModel?: Array<PlayerImportModel>, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/players/importPlayers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(playerImportModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MergeAndDeduplicateModel} [mergeAndDeduplicateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mergeAndDeduplicate: async (
      mergeAndDeduplicateModel?: MergeAndDeduplicateModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/players/mergeAndDeduplicate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        mergeAndDeduplicateModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} playerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeFavourite: async (playerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists('removeFavourite', 'playerId', playerId);
      const localVarPath = `/api/v1/players/{playerId}/favourite/remove`.replace(
        `{${'playerId'}}`,
        encodeURIComponent(String(playerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates a new user / player account.
     * @param {PlayerModel} [playerModel] AccountUser model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePlayer: async (playerModel?: PlayerModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/players`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(playerModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {string} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadAndParsePlayersXls: async (
      organisationId: number,
      studyId: number,
      file?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('uploadAndParsePlayersXls', 'organisationId', organisationId);
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('uploadAndParsePlayersXls', 'studyId', studyId);
      const localVarPath = `/api/v1/players/uploadAndParseXls/organisation/{organisationId}/study/{studyId}`
        .replace(`{${'organisationId'}}`, encodeURIComponent(String(organisationId)))
        .replace(`{${'studyId'}}`, encodeURIComponent(String(studyId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlayersApi - functional programming interface
 * @export
 */
export const PlayersApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = PlayersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} playerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addFavourite(
      playerId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addFavourite(playerId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async anyFavourites(
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.anyFavourites(organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Creates a new user / player account.
     * @param {PlayerModel} [playerModel] AccountUser model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPlayer(
      playerModel?: PlayerModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPlayer(playerModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PlayerGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterPlayers(
      filter?: PlayerGridFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayerCollectionModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterPlayers(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getManagePlayerFormLookupData(
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayerLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getManagePlayerFormLookupData(organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets a user / player account.
     * @param {number} id User / player id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayer(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayerModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayer(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayerGridLookupData(
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayerGridLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayerGridLookupData(organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayerImportLookupData(
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayerImportLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayerImportLookupData(organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Array<PlayerImportModel>} [playerImportModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importPlayers(
      playerImportModel?: Array<PlayerImportModel>,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlayerImportModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importPlayers(playerImportModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {MergeAndDeduplicateModel} [mergeAndDeduplicateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mergeAndDeduplicate(
      mergeAndDeduplicateModel?: MergeAndDeduplicateModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mergeAndDeduplicate(mergeAndDeduplicateModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} playerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeFavourite(
      playerId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeFavourite(playerId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Updates a new user / player account.
     * @param {PlayerModel} [playerModel] AccountUser model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePlayer(
      playerModel?: PlayerModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlayer(playerModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {string} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadAndParsePlayersXls(
      organisationId: number,
      studyId: number,
      file?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlayerImportModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAndParsePlayersXls(
        organisationId,
        studyId,
        file,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PlayersApi - factory interface
 * @export
 */
export const PlayersApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PlayersApiFp(configuration);
  return {
    /**
     *
     * @param {number} playerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFavourite(playerId: number, options?: any): AxiosPromise<void> {
      return localVarFp.addFavourite(playerId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    anyFavourites(organisationId?: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.anyFavourites(organisationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a new user / player account.
     * @param {PlayerModel} [playerModel] AccountUser model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPlayer(playerModel?: PlayerModel, options?: any): AxiosPromise<void> {
      return localVarFp.createPlayer(playerModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {PlayerGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterPlayers(filter?: PlayerGridFilter, options?: any): AxiosPromise<PlayerCollectionModelGridCollection> {
      return localVarFp.filterPlayers(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManagePlayerFormLookupData(organisationId?: number, options?: any): AxiosPromise<PlayerLookupModel> {
      return localVarFp
        .getManagePlayerFormLookupData(organisationId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets a user / player account.
     * @param {number} id User / player id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayer(id: number, options?: any): AxiosPromise<PlayerModel> {
      return localVarFp.getPlayer(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerGridLookupData(organisationId?: number, options?: any): AxiosPromise<PlayerGridLookupModel> {
      return localVarFp.getPlayerGridLookupData(organisationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerImportLookupData(organisationId?: number, options?: any): AxiosPromise<PlayerImportLookupModel> {
      return localVarFp.getPlayerImportLookupData(organisationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Array<PlayerImportModel>} [playerImportModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importPlayers(playerImportModel?: Array<PlayerImportModel>, options?: any): AxiosPromise<Array<PlayerImportModel>> {
      return localVarFp.importPlayers(playerImportModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {MergeAndDeduplicateModel} [mergeAndDeduplicateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mergeAndDeduplicate(mergeAndDeduplicateModel?: MergeAndDeduplicateModel, options?: any): AxiosPromise<void> {
      return localVarFp
        .mergeAndDeduplicate(mergeAndDeduplicateModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} playerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeFavourite(playerId: number, options?: any): AxiosPromise<void> {
      return localVarFp.removeFavourite(playerId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Updates a new user / player account.
     * @param {PlayerModel} [playerModel] AccountUser model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePlayer(playerModel?: PlayerModel, options?: any): AxiosPromise<void> {
      return localVarFp.updatePlayer(playerModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} organisationId
     * @param {number} studyId
     * @param {string} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadAndParsePlayersXls(
      organisationId: number,
      studyId: number,
      file?: string,
      options?: any,
    ): AxiosPromise<Array<PlayerImportModel>> {
      return localVarFp
        .uploadAndParsePlayersXls(organisationId, studyId, file, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * PlayersApi - interface
 * @export
 * @interface PlayersApi
 */
export interface PlayersApiInterface {
  /**
   *
   * @param {number} playerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  addFavourite(playerId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  anyFavourites(organisationId?: number, options?: any): AxiosPromise<boolean>;

  /**
   *
   * @summary Creates a new user / player account.
   * @param {PlayerModel} [playerModel] AccountUser model.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  createPlayer(playerModel?: PlayerModel, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {PlayerGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  filterPlayers(filter?: PlayerGridFilter, options?: any): AxiosPromise<PlayerCollectionModelGridCollection>;

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  getManagePlayerFormLookupData(organisationId?: number, options?: any): AxiosPromise<PlayerLookupModel>;

  /**
   *
   * @summary Gets a user / player account.
   * @param {number} id User / player id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  getPlayer(id: number, options?: any): AxiosPromise<PlayerModel>;

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  getPlayerGridLookupData(organisationId?: number, options?: any): AxiosPromise<PlayerGridLookupModel>;

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  getPlayerImportLookupData(organisationId?: number, options?: any): AxiosPromise<PlayerImportLookupModel>;

  /**
   *
   * @param {Array<PlayerImportModel>} [playerImportModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  importPlayers(playerImportModel?: Array<PlayerImportModel>, options?: any): AxiosPromise<Array<PlayerImportModel>>;

  /**
   *
   * @param {MergeAndDeduplicateModel} [mergeAndDeduplicateModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  mergeAndDeduplicate(mergeAndDeduplicateModel?: MergeAndDeduplicateModel, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} playerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  removeFavourite(playerId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Updates a new user / player account.
   * @param {PlayerModel} [playerModel] AccountUser model.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  updatePlayer(playerModel?: PlayerModel, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} organisationId
   * @param {number} studyId
   * @param {string} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApiInterface
   */
  uploadAndParsePlayersXls(
    organisationId: number,
    studyId: number,
    file?: string,
    options?: any,
  ): AxiosPromise<Array<PlayerImportModel>>;
}

/**
 * PlayersApi - object-oriented interface
 * @export
 * @class PlayersApi
 * @extends {BaseAPI}
 */
export class PlayersApi extends BaseAPI implements PlayersApiInterface {
  /**
   *
   * @param {number} playerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public addFavourite(playerId: number, options?: any) {
    return PlayersApiFp(this.configuration)
      .addFavourite(playerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public anyFavourites(organisationId?: number, options?: any) {
    return PlayersApiFp(this.configuration)
      .anyFavourites(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a new user / player account.
   * @param {PlayerModel} [playerModel] AccountUser model.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public createPlayer(playerModel?: PlayerModel, options?: any) {
    return PlayersApiFp(this.configuration)
      .createPlayer(playerModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PlayerGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public filterPlayers(filter?: PlayerGridFilter, options?: any) {
    return PlayersApiFp(this.configuration)
      .filterPlayers(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getManagePlayerFormLookupData(organisationId?: number, options?: any) {
    return PlayersApiFp(this.configuration)
      .getManagePlayerFormLookupData(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets a user / player account.
   * @param {number} id User / player id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getPlayer(id: number, options?: any) {
    return PlayersApiFp(this.configuration)
      .getPlayer(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getPlayerGridLookupData(organisationId?: number, options?: any) {
    return PlayersApiFp(this.configuration)
      .getPlayerGridLookupData(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getPlayerImportLookupData(organisationId?: number, options?: any) {
    return PlayersApiFp(this.configuration)
      .getPlayerImportLookupData(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<PlayerImportModel>} [playerImportModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public importPlayers(playerImportModel?: Array<PlayerImportModel>, options?: any) {
    return PlayersApiFp(this.configuration)
      .importPlayers(playerImportModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MergeAndDeduplicateModel} [mergeAndDeduplicateModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public mergeAndDeduplicate(mergeAndDeduplicateModel?: MergeAndDeduplicateModel, options?: any) {
    return PlayersApiFp(this.configuration)
      .mergeAndDeduplicate(mergeAndDeduplicateModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} playerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public removeFavourite(playerId: number, options?: any) {
    return PlayersApiFp(this.configuration)
      .removeFavourite(playerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates a new user / player account.
   * @param {PlayerModel} [playerModel] AccountUser model.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public updatePlayer(playerModel?: PlayerModel, options?: any) {
    return PlayersApiFp(this.configuration)
      .updatePlayer(playerModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} organisationId
   * @param {number} studyId
   * @param {string} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public uploadAndParsePlayersXls(organisationId: number, studyId: number, file?: string, options?: any) {
    return PlayersApiFp(this.configuration)
      .uploadAndParsePlayersXls(organisationId, studyId, file, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ReporterAccessApi - axios parameter creator
 * @export
 */
export const ReporterAccessApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveOrganisationReporterAccessRequest: async (
      reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reporterAccess/reporterOrganisationAccessRequest/approve`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reporterAccessRequestOrganisationPermissionsModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrganisationReporterAccessRequest: async (
      reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reporterAccess/reporterOrganisationAccessRequest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reporterAccessRequestOrganisationPermissionsModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReporterAccessRequestModel} [reporterAccessRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReporterAccount: async (
      reporterAccessRequestModel?: ReporterAccessRequestModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reporterAccess/createReporterAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reporterAccessRequestModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReporterAccessRequestGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterReporterRequests: async (
      filter?: ReporterAccessRequestGridFilter,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reporterAccess/filterReporterRequests`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forwardToMedicalAdmins: async (requestId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'requestId' is not null or undefined
      assertParamExists('forwardToMedicalAdmins', 'requestId', requestId);
      const localVarPath = `/api/v1/reporterAccess/reporterAccessRequest/{requestId}/forwardToMedicalAdmins`.replace(
        `{${'requestId'}}`,
        encodeURIComponent(String(requestId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {number} [reporterUserId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganisationReporterAccessRequest: async (
      organisationId?: number,
      reporterUserId?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reporterAccess/reporterOrganisationAccessRequest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (reporterUserId !== undefined) {
        localVarQueryParameter['reporterUserId'] = reporterUserId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReporterAccessRequest: async (requestId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'requestId' is not null or undefined
      assertParamExists('getReporterAccessRequest', 'requestId', requestId);
      const localVarPath = `/api/v1/reporterAccess/reporterAccessRequest/{requestId}`.replace(
        `{${'requestId'}}`,
        encodeURIComponent(String(requestId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [inviteKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReporterAccessRequestLookupData: async (inviteKey?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reporterAccess/reporterAccessRequest/lookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (inviteKey !== undefined) {
        localVarQueryParameter['inviteKey'] = inviteKey;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReporterAccessRequestInviteModel} [reporterAccessRequestInviteModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteReporter: async (
      reporterAccessRequestInviteModel?: ReporterAccessRequestInviteModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reporterAccess/inviteReporter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reporterAccessRequestInviteModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReporterOrganisationAccessRequestRejectionModel} [reporterOrganisationAccessRequestRejectionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectReporterOrganisationAccessRequest: async (
      reporterOrganisationAccessRequestRejectionModel?: ReporterOrganisationAccessRequestRejectionModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reporterAccess/reporterOrganisationAccessRequest/reject`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reporterOrganisationAccessRequestRejectionModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReporterAccessRequestRejectionModel} [reporterAccessRequestRejectionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectReporterRegistrationRequest: async (
      reporterAccessRequestRejectionModel?: ReporterAccessRequestRejectionModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reporterAccess/reporterAccessRequest/reject`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reporterAccessRequestRejectionModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReporterAccessRequestModel} [reporterAccessRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestReporterAccess: async (
      reporterAccessRequestModel?: ReporterAccessRequestModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reporterAccess/requestReporterAccess`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reporterAccessRequestModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganisationMedicalAdminStatus: async (
      reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reporterAccess/reporterOrganisationAccessRequest/medicalStatus`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reporterAccessRequestOrganisationPermissionsModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganisationReporterAccessRequest: async (
      reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reporterAccess/reporterOrganisationAccessRequest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reporterAccessRequestOrganisationPermissionsModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReporterAccessApi - functional programming interface
 * @export
 */
export const ReporterAccessApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ReporterAccessApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveOrganisationReporterAccessRequest(
      reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveOrganisationReporterAccessRequest(
        reporterAccessRequestOrganisationPermissionsModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOrganisationReporterAccessRequest(
      reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganisationReporterAccessRequest(
        reporterAccessRequestOrganisationPermissionsModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReporterAccessRequestModel} [reporterAccessRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createReporterAccount(
      reporterAccessRequestModel?: ReporterAccessRequestModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createReporterAccount(
        reporterAccessRequestModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReporterAccessRequestGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterReporterRequests(
      filter?: ReporterAccessRequestGridFilter,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReporterAccessRequestCollectionModelGridCollection>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterReporterRequests(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forwardToMedicalAdmins(
      requestId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.forwardToMedicalAdmins(requestId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {number} [reporterUserId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganisationReporterAccessRequest(
      organisationId?: number,
      reporterUserId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReporterOrganisationAccessRequestModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisationReporterAccessRequest(
        organisationId,
        reporterUserId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReporterAccessRequest(
      requestId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReporterAccessRequestModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReporterAccessRequest(requestId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [inviteKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReporterAccessRequestLookupData(
      inviteKey?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReporterAccessRequestLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReporterAccessRequestLookupData(inviteKey, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReporterAccessRequestInviteModel} [reporterAccessRequestInviteModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inviteReporter(
      reporterAccessRequestInviteModel?: ReporterAccessRequestInviteModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inviteReporter(
        reporterAccessRequestInviteModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReporterOrganisationAccessRequestRejectionModel} [reporterOrganisationAccessRequestRejectionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rejectReporterOrganisationAccessRequest(
      reporterOrganisationAccessRequestRejectionModel?: ReporterOrganisationAccessRequestRejectionModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rejectReporterOrganisationAccessRequest(
        reporterOrganisationAccessRequestRejectionModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReporterAccessRequestRejectionModel} [reporterAccessRequestRejectionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rejectReporterRegistrationRequest(
      reporterAccessRequestRejectionModel?: ReporterAccessRequestRejectionModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rejectReporterRegistrationRequest(
        reporterAccessRequestRejectionModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReporterAccessRequestModel} [reporterAccessRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestReporterAccess(
      reporterAccessRequestModel?: ReporterAccessRequestModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.requestReporterAccess(
        reporterAccessRequestModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateOrganisationMedicalAdminStatus(
      reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganisationMedicalAdminStatus(
        reporterAccessRequestOrganisationPermissionsModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateOrganisationReporterAccessRequest(
      reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganisationReporterAccessRequest(
        reporterAccessRequestOrganisationPermissionsModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReporterAccessApi - factory interface
 * @export
 */
export const ReporterAccessApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ReporterAccessApiFp(configuration);
  return {
    /**
     *
     * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveOrganisationReporterAccessRequest(
      reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .approveOrganisationReporterAccessRequest(reporterAccessRequestOrganisationPermissionsModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrganisationReporterAccessRequest(
      reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createOrganisationReporterAccessRequest(reporterAccessRequestOrganisationPermissionsModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ReporterAccessRequestModel} [reporterAccessRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReporterAccount(
      reporterAccessRequestModel?: ReporterAccessRequestModel,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .createReporterAccount(reporterAccessRequestModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ReporterAccessRequestGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterReporterRequests(
      filter?: ReporterAccessRequestGridFilter,
      options?: any,
    ): AxiosPromise<ReporterAccessRequestCollectionModelGridCollection> {
      return localVarFp.filterReporterRequests(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forwardToMedicalAdmins(requestId: number, options?: any): AxiosPromise<void> {
      return localVarFp.forwardToMedicalAdmins(requestId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {number} [reporterUserId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganisationReporterAccessRequest(
      organisationId?: number,
      reporterUserId?: number,
      options?: any,
    ): AxiosPromise<ReporterOrganisationAccessRequestModel> {
      return localVarFp
        .getOrganisationReporterAccessRequest(organisationId, reporterUserId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReporterAccessRequest(requestId: number, options?: any): AxiosPromise<ReporterAccessRequestModel> {
      return localVarFp.getReporterAccessRequest(requestId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [inviteKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReporterAccessRequestLookupData(
      inviteKey?: string,
      options?: any,
    ): AxiosPromise<ReporterAccessRequestLookupModel> {
      return localVarFp
        .getReporterAccessRequestLookupData(inviteKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ReporterAccessRequestInviteModel} [reporterAccessRequestInviteModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteReporter(
      reporterAccessRequestInviteModel?: ReporterAccessRequestInviteModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .inviteReporter(reporterAccessRequestInviteModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ReporterOrganisationAccessRequestRejectionModel} [reporterOrganisationAccessRequestRejectionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectReporterOrganisationAccessRequest(
      reporterOrganisationAccessRequestRejectionModel?: ReporterOrganisationAccessRequestRejectionModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .rejectReporterOrganisationAccessRequest(reporterOrganisationAccessRequestRejectionModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ReporterAccessRequestRejectionModel} [reporterAccessRequestRejectionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectReporterRegistrationRequest(
      reporterAccessRequestRejectionModel?: ReporterAccessRequestRejectionModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .rejectReporterRegistrationRequest(reporterAccessRequestRejectionModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ReporterAccessRequestModel} [reporterAccessRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestReporterAccess(reporterAccessRequestModel?: ReporterAccessRequestModel, options?: any): AxiosPromise<void> {
      return localVarFp
        .requestReporterAccess(reporterAccessRequestModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganisationMedicalAdminStatus(
      reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateOrganisationMedicalAdminStatus(reporterAccessRequestOrganisationPermissionsModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganisationReporterAccessRequest(
      reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateOrganisationReporterAccessRequest(reporterAccessRequestOrganisationPermissionsModel, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ReporterAccessApi - interface
 * @export
 * @interface ReporterAccessApi
 */
export interface ReporterAccessApiInterface {
  /**
   *
   * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  approveOrganisationReporterAccessRequest(
    reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  createOrganisationReporterAccessRequest(
    reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @param {ReporterAccessRequestModel} [reporterAccessRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  createReporterAccount(reporterAccessRequestModel?: ReporterAccessRequestModel, options?: any): AxiosPromise<number>;

  /**
   *
   * @param {ReporterAccessRequestGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  filterReporterRequests(
    filter?: ReporterAccessRequestGridFilter,
    options?: any,
  ): AxiosPromise<ReporterAccessRequestCollectionModelGridCollection>;

  /**
   *
   * @param {number} requestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  forwardToMedicalAdmins(requestId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} [organisationId]
   * @param {number} [reporterUserId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  getOrganisationReporterAccessRequest(
    organisationId?: number,
    reporterUserId?: number,
    options?: any,
  ): AxiosPromise<ReporterOrganisationAccessRequestModel>;

  /**
   *
   * @param {number} requestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  getReporterAccessRequest(requestId: number, options?: any): AxiosPromise<ReporterAccessRequestModel>;

  /**
   *
   * @param {string} [inviteKey]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  getReporterAccessRequestLookupData(inviteKey?: string, options?: any): AxiosPromise<ReporterAccessRequestLookupModel>;

  /**
   *
   * @param {ReporterAccessRequestInviteModel} [reporterAccessRequestInviteModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  inviteReporter(
    reporterAccessRequestInviteModel?: ReporterAccessRequestInviteModel,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @param {ReporterOrganisationAccessRequestRejectionModel} [reporterOrganisationAccessRequestRejectionModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  rejectReporterOrganisationAccessRequest(
    reporterOrganisationAccessRequestRejectionModel?: ReporterOrganisationAccessRequestRejectionModel,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @param {ReporterAccessRequestRejectionModel} [reporterAccessRequestRejectionModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  rejectReporterRegistrationRequest(
    reporterAccessRequestRejectionModel?: ReporterAccessRequestRejectionModel,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @param {ReporterAccessRequestModel} [reporterAccessRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  requestReporterAccess(reporterAccessRequestModel?: ReporterAccessRequestModel, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  updateOrganisationMedicalAdminStatus(
    reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApiInterface
   */
  updateOrganisationReporterAccessRequest(
    reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
    options?: any,
  ): AxiosPromise<void>;
}

/**
 * ReporterAccessApi - object-oriented interface
 * @export
 * @class ReporterAccessApi
 * @extends {BaseAPI}
 */
export class ReporterAccessApi extends BaseAPI implements ReporterAccessApiInterface {
  /**
   *
   * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public approveOrganisationReporterAccessRequest(
    reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
    options?: any,
  ) {
    return ReporterAccessApiFp(this.configuration)
      .approveOrganisationReporterAccessRequest(reporterAccessRequestOrganisationPermissionsModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public createOrganisationReporterAccessRequest(
    reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
    options?: any,
  ) {
    return ReporterAccessApiFp(this.configuration)
      .createOrganisationReporterAccessRequest(reporterAccessRequestOrganisationPermissionsModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReporterAccessRequestModel} [reporterAccessRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public createReporterAccount(reporterAccessRequestModel?: ReporterAccessRequestModel, options?: any) {
    return ReporterAccessApiFp(this.configuration)
      .createReporterAccount(reporterAccessRequestModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReporterAccessRequestGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public filterReporterRequests(filter?: ReporterAccessRequestGridFilter, options?: any) {
    return ReporterAccessApiFp(this.configuration)
      .filterReporterRequests(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} requestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public forwardToMedicalAdmins(requestId: number, options?: any) {
    return ReporterAccessApiFp(this.configuration)
      .forwardToMedicalAdmins(requestId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [organisationId]
   * @param {number} [reporterUserId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public getOrganisationReporterAccessRequest(organisationId?: number, reporterUserId?: number, options?: any) {
    return ReporterAccessApiFp(this.configuration)
      .getOrganisationReporterAccessRequest(organisationId, reporterUserId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} requestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public getReporterAccessRequest(requestId: number, options?: any) {
    return ReporterAccessApiFp(this.configuration)
      .getReporterAccessRequest(requestId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [inviteKey]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public getReporterAccessRequestLookupData(inviteKey?: string, options?: any) {
    return ReporterAccessApiFp(this.configuration)
      .getReporterAccessRequestLookupData(inviteKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReporterAccessRequestInviteModel} [reporterAccessRequestInviteModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public inviteReporter(reporterAccessRequestInviteModel?: ReporterAccessRequestInviteModel, options?: any) {
    return ReporterAccessApiFp(this.configuration)
      .inviteReporter(reporterAccessRequestInviteModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReporterOrganisationAccessRequestRejectionModel} [reporterOrganisationAccessRequestRejectionModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public rejectReporterOrganisationAccessRequest(
    reporterOrganisationAccessRequestRejectionModel?: ReporterOrganisationAccessRequestRejectionModel,
    options?: any,
  ) {
    return ReporterAccessApiFp(this.configuration)
      .rejectReporterOrganisationAccessRequest(reporterOrganisationAccessRequestRejectionModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReporterAccessRequestRejectionModel} [reporterAccessRequestRejectionModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public rejectReporterRegistrationRequest(
    reporterAccessRequestRejectionModel?: ReporterAccessRequestRejectionModel,
    options?: any,
  ) {
    return ReporterAccessApiFp(this.configuration)
      .rejectReporterRegistrationRequest(reporterAccessRequestRejectionModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReporterAccessRequestModel} [reporterAccessRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public requestReporterAccess(reporterAccessRequestModel?: ReporterAccessRequestModel, options?: any) {
    return ReporterAccessApiFp(this.configuration)
      .requestReporterAccess(reporterAccessRequestModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public updateOrganisationMedicalAdminStatus(
    reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
    options?: any,
  ) {
    return ReporterAccessApiFp(this.configuration)
      .updateOrganisationMedicalAdminStatus(reporterAccessRequestOrganisationPermissionsModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReporterAccessRequestOrganisationPermissionsModel} [reporterAccessRequestOrganisationPermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReporterAccessApi
   */
  public updateOrganisationReporterAccessRequest(
    reporterAccessRequestOrganisationPermissionsModel?: ReporterAccessRequestOrganisationPermissionsModel,
    options?: any,
  ) {
    return ReporterAccessApiFp(this.configuration)
      .updateOrganisationReporterAccessRequest(reporterAccessRequestOrganisationPermissionsModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ResourcesApi - axios parameter creator
 * @export
 */
export const ResourcesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocument: async (file: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('getDocument', 'file', file);
      const localVarPath = `/api/v1/resources/document/{file}`.replace(`{${'file'}}`, encodeURIComponent(String(file)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ResourcesApi - functional programming interface
 * @export
 */
export const ResourcesApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ResourcesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocument(
      file: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDownloadModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(file, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ResourcesApi - factory interface
 * @export
 */
export const ResourcesApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ResourcesApiFp(configuration);
  return {
    /**
     *
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocument(file: string, options?: any): AxiosPromise<FileDownloadModel> {
      return localVarFp.getDocument(file, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ResourcesApi - interface
 * @export
 * @interface ResourcesApi
 */
export interface ResourcesApiInterface {
  /**
   *
   * @param {string} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourcesApiInterface
   */
  getDocument(file: string, options?: any): AxiosPromise<FileDownloadModel>;
}

/**
 * ResourcesApi - object-oriented interface
 * @export
 * @class ResourcesApi
 * @extends {BaseAPI}
 */
export class ResourcesApi extends BaseAPI implements ResourcesApiInterface {
  /**
   *
   * @param {string} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourcesApi
   */
  public getDocument(file: string, options?: any) {
    return ResourcesApiFp(this.configuration)
      .getDocument(file, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRoles: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/roles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllRoles(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRoleModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRoles(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = RolesApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRoles(options?: any): AxiosPromise<Array<UserRoleModel>> {
      return localVarFp.getAllRoles(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * RolesApi - interface
 * @export
 * @interface RolesApi
 */
export interface RolesApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RolesApiInterface
   */
  getAllRoles(options?: any): AxiosPromise<Array<UserRoleModel>>;
}

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI implements RolesApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RolesApi
   */
  public getAllRoles(options?: any) {
    return RolesApiFp(this.configuration)
      .getAllRoles(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * StudiesApi - axios parameter creator
 * @export
 */
export const StudiesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} studyId
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOrganisationToStudy: async (
      studyId: number,
      organisationId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('addOrganisationToStudy', 'studyId', studyId);
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('addOrganisationToStudy', 'organisationId', organisationId);
      const localVarPath = `/api/v1/studies/{studyId}/organisations/{organisationId}`
        .replace(`{${'studyId'}}`, encodeURIComponent(String(studyId)))
        .replace(`{${'organisationId'}}`, encodeURIComponent(String(organisationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StudyModel} [studyModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStudy: async (studyModel?: StudyModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/studies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(studyModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StudyCollectionFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterStudies: async (filter?: StudyCollectionFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/studies/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnalystExportableStudiesLookupData: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/studies/analyst/exportable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} studyId
     * @param {string} locale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuidanceDoc: async (studyId: number, locale: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('getGuidanceDoc', 'studyId', studyId);
      // verify required parameter 'locale' is not null or undefined
      assertParamExists('getGuidanceDoc', 'locale', locale);
      const localVarPath = `/api/v1/studies/{studyId}/guidance/{locale}`
        .replace(`{${'studyId'}}`, encodeURIComponent(String(studyId)))
        .replace(`{${'locale'}}`, encodeURIComponent(String(locale)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManageStudyFormLookupData: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/studies/formLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReporterExportableStudiesLookupData: async (organisationId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('getReporterExportableStudiesLookupData', 'organisationId', organisationId);
      const localVarPath = `/api/v1/studies/reporter/exportable/organisation/{organisationId}`.replace(
        `{${'organisationId'}}`,
        encodeURIComponent(String(organisationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudy: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getStudy', 'id', id);
      const localVarPath = `/api/v1/studies/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudyOrganisations: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getStudyOrganisations', 'id', id);
      const localVarPath = `/api/v1/studies/{id}/organisations`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyReportersOfExpiringStudyAccess: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/studies/notifyReportersOfExpiringStudyAccess`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} studyId
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOrganisationFromStudy: async (
      studyId: number,
      organisationId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'studyId' is not null or undefined
      assertParamExists('removeOrganisationFromStudy', 'studyId', studyId);
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('removeOrganisationFromStudy', 'organisationId', organisationId);
      const localVarPath = `/api/v1/studies/{studyId}/organisations/{organisationId}`
        .replace(`{${'studyId'}}`, encodeURIComponent(String(studyId)))
        .replace(`{${'organisationId'}}`, encodeURIComponent(String(organisationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [studyId]
     * @param {string} [sidebarLabel] File name that will show up in the sidebar. Needs to be limited in length.
     * @param {string} [locale] File locale (i.e. en-IE, en-US, fr-FR, etc.).
     * @param {string} [fileName] File name.
     * @param {string} [base64File] Base64 encoded file payload.
     * @param {string} [fileUrl] Azure file url.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuidanceDoc: async (
      studyId?: number,
      sidebarLabel?: string,
      locale?: string,
      fileName?: string,
      base64File?: string,
      fileUrl?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/studies/guidance`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (studyId !== undefined) {
        localVarFormParams.append('StudyId', studyId as any);
      }

      if (sidebarLabel !== undefined) {
        localVarFormParams.append('SidebarLabel', sidebarLabel as any);
      }

      if (locale !== undefined) {
        localVarFormParams.append('Locale', locale as any);
      }

      if (fileName !== undefined) {
        localVarFormParams.append('FileName', fileName as any);
      }

      if (base64File !== undefined) {
        localVarFormParams.append('Base64File', base64File as any);
      }

      if (fileUrl !== undefined) {
        localVarFormParams.append('FileUrl', fileUrl as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StudyModel} [studyModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStudy: async (studyModel?: StudyModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/studies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(studyModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StudiesApi - functional programming interface
 * @export
 */
export const StudiesApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = StudiesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} studyId
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addOrganisationToStudy(
      studyId: number,
      organisationId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addOrganisationToStudy(
        studyId,
        organisationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StudyModel} [studyModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createStudy(
      studyModel?: StudyModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createStudy(studyModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StudyCollectionFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterStudies(
      filter?: StudyCollectionFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyCollectionModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterStudies(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnalystExportableStudiesLookupData(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudyModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalystExportableStudiesLookupData(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} studyId
     * @param {string} locale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGuidanceDoc(
      studyId: number,
      locale: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyGuidanceFileModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGuidanceDoc(studyId, locale, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getManageStudyFormLookupData(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getManageStudyFormLookupData(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReporterExportableStudiesLookupData(
      organisationId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudyModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReporterExportableStudiesLookupData(
        organisationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStudy(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStudy(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStudyOrganisations(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationCollectionModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyOrganisations(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notifyReportersOfExpiringStudyAccess(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notifyReportersOfExpiringStudyAccess(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} studyId
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeOrganisationFromStudy(
      studyId: number,
      organisationId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeOrganisationFromStudy(
        studyId,
        organisationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [studyId]
     * @param {string} [sidebarLabel] File name that will show up in the sidebar. Needs to be limited in length.
     * @param {string} [locale] File locale (i.e. en-IE, en-US, fr-FR, etc.).
     * @param {string} [fileName] File name.
     * @param {string} [base64File] Base64 encoded file payload.
     * @param {string} [fileUrl] Azure file url.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGuidanceDoc(
      studyId?: number,
      sidebarLabel?: string,
      locale?: string,
      fileName?: string,
      base64File?: string,
      fileUrl?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGuidanceDoc(
        studyId,
        sidebarLabel,
        locale,
        fileName,
        base64File,
        fileUrl,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StudyModel} [studyModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStudy(
      studyModel?: StudyModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStudy(studyModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * StudiesApi - factory interface
 * @export
 */
export const StudiesApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = StudiesApiFp(configuration);
  return {
    /**
     *
     * @param {number} studyId
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOrganisationToStudy(studyId: number, organisationId: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .addOrganisationToStudy(studyId, organisationId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {StudyModel} [studyModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStudy(studyModel?: StudyModel, options?: any): AxiosPromise<StudyModel> {
      return localVarFp.createStudy(studyModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {StudyCollectionFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterStudies(filter?: StudyCollectionFilter, options?: any): AxiosPromise<StudyCollectionModelGridCollection> {
      return localVarFp.filterStudies(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnalystExportableStudiesLookupData(options?: any): AxiosPromise<Array<StudyModel>> {
      return localVarFp.getAnalystExportableStudiesLookupData(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} studyId
     * @param {string} locale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuidanceDoc(studyId: number, locale: string, options?: any): AxiosPromise<StudyGuidanceFileModel> {
      return localVarFp.getGuidanceDoc(studyId, locale, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManageStudyFormLookupData(options?: any): AxiosPromise<StudyLookupModel> {
      return localVarFp.getManageStudyFormLookupData(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReporterExportableStudiesLookupData(organisationId: number, options?: any): AxiosPromise<Array<StudyModel>> {
      return localVarFp
        .getReporterExportableStudiesLookupData(organisationId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudy(id: number, options?: any): AxiosPromise<StudyModel> {
      return localVarFp.getStudy(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudyOrganisations(id: number, options?: any): AxiosPromise<Array<OrganisationCollectionModel>> {
      return localVarFp.getStudyOrganisations(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyReportersOfExpiringStudyAccess(options?: any): AxiosPromise<void> {
      return localVarFp.notifyReportersOfExpiringStudyAccess(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} studyId
     * @param {number} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOrganisationFromStudy(studyId: number, organisationId: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .removeOrganisationFromStudy(studyId, organisationId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [studyId]
     * @param {string} [sidebarLabel] File name that will show up in the sidebar. Needs to be limited in length.
     * @param {string} [locale] File locale (i.e. en-IE, en-US, fr-FR, etc.).
     * @param {string} [fileName] File name.
     * @param {string} [base64File] Base64 encoded file payload.
     * @param {string} [fileUrl] Azure file url.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuidanceDoc(
      studyId?: number,
      sidebarLabel?: string,
      locale?: string,
      fileName?: string,
      base64File?: string,
      fileUrl?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateGuidanceDoc(studyId, sidebarLabel, locale, fileName, base64File, fileUrl, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {StudyModel} [studyModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStudy(studyModel?: StudyModel, options?: any): AxiosPromise<StudyModel> {
      return localVarFp.updateStudy(studyModel, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * StudiesApi - interface
 * @export
 * @interface StudiesApi
 */
export interface StudiesApiInterface {
  /**
   *
   * @param {number} studyId
   * @param {number} organisationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  addOrganisationToStudy(studyId: number, organisationId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {StudyModel} [studyModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  createStudy(studyModel?: StudyModel, options?: any): AxiosPromise<StudyModel>;

  /**
   *
   * @param {StudyCollectionFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  filterStudies(filter?: StudyCollectionFilter, options?: any): AxiosPromise<StudyCollectionModelGridCollection>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  getAnalystExportableStudiesLookupData(options?: any): AxiosPromise<Array<StudyModel>>;

  /**
   *
   * @param {number} studyId
   * @param {string} locale
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  getGuidanceDoc(studyId: number, locale: string, options?: any): AxiosPromise<StudyGuidanceFileModel>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  getManageStudyFormLookupData(options?: any): AxiosPromise<StudyLookupModel>;

  /**
   *
   * @param {number} organisationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  getReporterExportableStudiesLookupData(organisationId: number, options?: any): AxiosPromise<Array<StudyModel>>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  getStudy(id: number, options?: any): AxiosPromise<StudyModel>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  getStudyOrganisations(id: number, options?: any): AxiosPromise<Array<OrganisationCollectionModel>>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  notifyReportersOfExpiringStudyAccess(options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} studyId
   * @param {number} organisationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  removeOrganisationFromStudy(studyId: number, organisationId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} [studyId]
   * @param {string} [sidebarLabel] File name that will show up in the sidebar. Needs to be limited in length.
   * @param {string} [locale] File locale (i.e. en-IE, en-US, fr-FR, etc.).
   * @param {string} [fileName] File name.
   * @param {string} [base64File] Base64 encoded file payload.
   * @param {string} [fileUrl] Azure file url.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  updateGuidanceDoc(
    studyId?: number,
    sidebarLabel?: string,
    locale?: string,
    fileName?: string,
    base64File?: string,
    fileUrl?: string,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @param {StudyModel} [studyModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApiInterface
   */
  updateStudy(studyModel?: StudyModel, options?: any): AxiosPromise<StudyModel>;
}

/**
 * StudiesApi - object-oriented interface
 * @export
 * @class StudiesApi
 * @extends {BaseAPI}
 */
export class StudiesApi extends BaseAPI implements StudiesApiInterface {
  /**
   *
   * @param {number} studyId
   * @param {number} organisationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public addOrganisationToStudy(studyId: number, organisationId: number, options?: any) {
    return StudiesApiFp(this.configuration)
      .addOrganisationToStudy(studyId, organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StudyModel} [studyModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public createStudy(studyModel?: StudyModel, options?: any) {
    return StudiesApiFp(this.configuration)
      .createStudy(studyModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StudyCollectionFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public filterStudies(filter?: StudyCollectionFilter, options?: any) {
    return StudiesApiFp(this.configuration)
      .filterStudies(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public getAnalystExportableStudiesLookupData(options?: any) {
    return StudiesApiFp(this.configuration)
      .getAnalystExportableStudiesLookupData(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} studyId
   * @param {string} locale
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public getGuidanceDoc(studyId: number, locale: string, options?: any) {
    return StudiesApiFp(this.configuration)
      .getGuidanceDoc(studyId, locale, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public getManageStudyFormLookupData(options?: any) {
    return StudiesApiFp(this.configuration)
      .getManageStudyFormLookupData(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} organisationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public getReporterExportableStudiesLookupData(organisationId: number, options?: any) {
    return StudiesApiFp(this.configuration)
      .getReporterExportableStudiesLookupData(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public getStudy(id: number, options?: any) {
    return StudiesApiFp(this.configuration)
      .getStudy(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public getStudyOrganisations(id: number, options?: any) {
    return StudiesApiFp(this.configuration)
      .getStudyOrganisations(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public notifyReportersOfExpiringStudyAccess(options?: any) {
    return StudiesApiFp(this.configuration)
      .notifyReportersOfExpiringStudyAccess(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} studyId
   * @param {number} organisationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public removeOrganisationFromStudy(studyId: number, organisationId: number, options?: any) {
    return StudiesApiFp(this.configuration)
      .removeOrganisationFromStudy(studyId, organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [studyId]
   * @param {string} [sidebarLabel] File name that will show up in the sidebar. Needs to be limited in length.
   * @param {string} [locale] File locale (i.e. en-IE, en-US, fr-FR, etc.).
   * @param {string} [fileName] File name.
   * @param {string} [base64File] Base64 encoded file payload.
   * @param {string} [fileUrl] Azure file url.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public updateGuidanceDoc(
    studyId?: number,
    sidebarLabel?: string,
    locale?: string,
    fileName?: string,
    base64File?: string,
    fileUrl?: string,
    options?: any,
  ) {
    return StudiesApiFp(this.configuration)
      .updateGuidanceDoc(studyId, sidebarLabel, locale, fileName, base64File, fileUrl, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StudyModel} [studyModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudiesApi
   */
  public updateStudy(studyModel?: StudyModel, options?: any) {
    return StudiesApiFp(this.configuration)
      .updateStudy(studyModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} userId
     * @param {number} roleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addUserToRole: async (userId: number, roleId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('addUserToRole', 'userId', userId);
      // verify required parameter 'roleId' is not null or undefined
      assertParamExists('addUserToRole', 'roleId', roleId);
      const localVarPath = `/api/v1/users/{userId}/addToRole/{roleId}`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'roleId'}}`, encodeURIComponent(String(roleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BasicUserAccountDetailsModel} [basicUserAccountDetailsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccount: async (
      basicUserAccountDetailsModel?: BasicUserAccountDetailsModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        basicUserAccountDetailsModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterUsers: async (filter?: UserGridFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search] Search clause (optional).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterUsersForArchival: async (search?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/filterForArchival`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnalystRolePermissions: async (userId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getAnalystRolePermissions', 'userId', userId);
      const localVarPath = `/api/v1/users/{userId}/analystRolePermissions`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBasicAccountDetails: async (userId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getBasicAccountDetails', 'userId', userId);
      const localVarPath = `/api/v1/users/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManageUserFormLookupData: async (organisationId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/formLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMedicalAdminRolePermissions: async (userId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getMedicalAdminRolePermissions', 'userId', userId);
      const localVarPath = `/api/v1/users/{userId}/medicalAdminRolePermissions`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReporterProfileLookupData: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/reporterProfileLookupData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReporterRolePermissions: async (userId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getReporterRolePermissions', 'userId', userId);
      const localVarPath = `/api/v1/users/{userId}/reporterRolePermissions`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudyAdminRolePermissions: async (userId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getStudyAdminRolePermissions', 'userId', userId);
      const localVarPath = `/api/v1/users/{userId}/studyAdminRolePermissions`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserAuditLog: async (userId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getUserAuditLog', 'userId', userId);
      const localVarPath = `/api/v1/users/{userId}/eventLog`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {number} roleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeUserFromRole: async (userId: number, roleId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('removeUserFromRole', 'userId', userId);
      // verify required parameter 'roleId' is not null or undefined
      assertParamExists('removeUserFromRole', 'roleId', roleId);
      const localVarPath = `/api/v1/users/{userId}/removeFromRole/{roleId}`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'roleId'}}`, encodeURIComponent(String(roleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetMfa: async (userId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('resetMfa', 'userId', userId);
      const localVarPath = `/api/v1/users/{userId}/resetMfa`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AnalystRolePermissionsModel} [analystRolePermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAnalystRolePermissions: async (
      analystRolePermissionsModel?: AnalystRolePermissionsModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/analystRolePermissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        analystRolePermissionsModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MedicalAdminRolePermissionsModel} [medicalAdminRolePermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveMedicalAdminRolePermissions: async (
      medicalAdminRolePermissionsModel?: MedicalAdminRolePermissionsModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/medicalAdminRolePermissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        medicalAdminRolePermissionsModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReporterRolePermissionsModel} [reporterRolePermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveReporterRolePermissions: async (
      reporterRolePermissionsModel?: ReporterRolePermissionsModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/reporterRolePermissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reporterRolePermissionsModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StudyAdminRolePermissionsModel} [studyAdminRolePermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveStudyAdminRolePermissions: async (
      studyAdminRolePermissionsModel?: StudyAdminRolePermissionsModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/studyAdminRolePermissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        studyAdminRolePermissionsModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BasicUserAccountDetailsModel} [basicUserAccountDetailsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount: async (
      basicUserAccountDetailsModel?: BasicUserAccountDetailsModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        basicUserAccountDetailsModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} userId
     * @param {number} roleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addUserToRole(
      userId: number,
      roleId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addUserToRole(userId, roleId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {BasicUserAccountDetailsModel} [basicUserAccountDetailsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAccount(
      basicUserAccountDetailsModel?: BasicUserAccountDetailsModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAccount(basicUserAccountDetailsModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UserGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterUsers(
      filter?: UserGridFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCollectionModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterUsers(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [search] Search clause (optional).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterUsersForArchival(
      search?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserCollectionModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterUsersForArchival(search, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnalystRolePermissions(
      userId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalystRolePermissionsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalystRolePermissions(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBasicAccountDetails(
      userId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicUserAccountDetailsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBasicAccountDetails(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getManageUserFormLookupData(
      organisationId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getManageUserFormLookupData(organisationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMedicalAdminRolePermissions(
      userId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalAdminRolePermissionsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalAdminRolePermissions(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReporterProfileLookupData(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReporterProfileLookupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReporterProfileLookupData(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReporterRolePermissions(
      userId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReporterRolePermissionsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReporterRolePermissions(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStudyAdminRolePermissions(
      userId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyAdminRolePermissionsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyAdminRolePermissions(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserAuditLog(
      userId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLogEventModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAuditLog(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {number} roleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeUserFromRole(
      userId: number,
      roleId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromRole(userId, roleId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetMfa(
      userId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetMfa(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AnalystRolePermissionsModel} [analystRolePermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveAnalystRolePermissions(
      analystRolePermissionsModel?: AnalystRolePermissionsModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveAnalystRolePermissions(
        analystRolePermissionsModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {MedicalAdminRolePermissionsModel} [medicalAdminRolePermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveMedicalAdminRolePermissions(
      medicalAdminRolePermissionsModel?: MedicalAdminRolePermissionsModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveMedicalAdminRolePermissions(
        medicalAdminRolePermissionsModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReporterRolePermissionsModel} [reporterRolePermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveReporterRolePermissions(
      reporterRolePermissionsModel?: ReporterRolePermissionsModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveReporterRolePermissions(
        reporterRolePermissionsModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StudyAdminRolePermissionsModel} [studyAdminRolePermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveStudyAdminRolePermissions(
      studyAdminRolePermissionsModel?: StudyAdminRolePermissionsModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveStudyAdminRolePermissions(
        studyAdminRolePermissionsModel,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {BasicUserAccountDetailsModel} [basicUserAccountDetailsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccount(
      basicUserAccountDetailsModel?: BasicUserAccountDetailsModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(basicUserAccountDetailsModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @param {number} userId
     * @param {number} roleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addUserToRole(userId: number, roleId: number, options?: any): AxiosPromise<void> {
      return localVarFp.addUserToRole(userId, roleId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {BasicUserAccountDetailsModel} [basicUserAccountDetailsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccount(basicUserAccountDetailsModel?: BasicUserAccountDetailsModel, options?: any): AxiosPromise<void> {
      return localVarFp.createAccount(basicUserAccountDetailsModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {UserGridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterUsers(filter?: UserGridFilter, options?: any): AxiosPromise<UserCollectionModelGridCollection> {
      return localVarFp.filterUsers(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search] Search clause (optional).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterUsersForArchival(search?: string, options?: any): AxiosPromise<Array<UserCollectionModel>> {
      return localVarFp.filterUsersForArchival(search, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnalystRolePermissions(userId: number, options?: any): AxiosPromise<AnalystRolePermissionsModel> {
      return localVarFp.getAnalystRolePermissions(userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBasicAccountDetails(userId: number, options?: any): AxiosPromise<BasicUserAccountDetailsModel> {
      return localVarFp.getBasicAccountDetails(userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [organisationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManageUserFormLookupData(organisationId?: number, options?: any): AxiosPromise<UserAccountLookupModel> {
      return localVarFp.getManageUserFormLookupData(organisationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMedicalAdminRolePermissions(userId: number, options?: any): AxiosPromise<MedicalAdminRolePermissionsModel> {
      return localVarFp.getMedicalAdminRolePermissions(userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReporterProfileLookupData(options?: any): AxiosPromise<ReporterProfileLookupModel> {
      return localVarFp.getReporterProfileLookupData(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReporterRolePermissions(userId: number, options?: any): AxiosPromise<ReporterRolePermissionsModel> {
      return localVarFp.getReporterRolePermissions(userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudyAdminRolePermissions(userId: number, options?: any): AxiosPromise<StudyAdminRolePermissionsModel> {
      return localVarFp.getStudyAdminRolePermissions(userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserAuditLog(userId: number, options?: any): AxiosPromise<Array<AuditLogEventModel>> {
      return localVarFp.getUserAuditLog(userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {number} roleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeUserFromRole(userId: number, roleId: number, options?: any): AxiosPromise<void> {
      return localVarFp.removeUserFromRole(userId, roleId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetMfa(userId: number, options?: any): AxiosPromise<void> {
      return localVarFp.resetMfa(userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {AnalystRolePermissionsModel} [analystRolePermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAnalystRolePermissions(
      analystRolePermissionsModel?: AnalystRolePermissionsModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .saveAnalystRolePermissions(analystRolePermissionsModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {MedicalAdminRolePermissionsModel} [medicalAdminRolePermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveMedicalAdminRolePermissions(
      medicalAdminRolePermissionsModel?: MedicalAdminRolePermissionsModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .saveMedicalAdminRolePermissions(medicalAdminRolePermissionsModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ReporterRolePermissionsModel} [reporterRolePermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveReporterRolePermissions(
      reporterRolePermissionsModel?: ReporterRolePermissionsModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .saveReporterRolePermissions(reporterRolePermissionsModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {StudyAdminRolePermissionsModel} [studyAdminRolePermissionsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveStudyAdminRolePermissions(
      studyAdminRolePermissionsModel?: StudyAdminRolePermissionsModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .saveStudyAdminRolePermissions(studyAdminRolePermissionsModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {BasicUserAccountDetailsModel} [basicUserAccountDetailsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount(basicUserAccountDetailsModel?: BasicUserAccountDetailsModel, options?: any): AxiosPromise<void> {
      return localVarFp.updateAccount(basicUserAccountDetailsModel, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
  /**
   *
   * @param {number} userId
   * @param {number} roleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  addUserToRole(userId: number, roleId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {BasicUserAccountDetailsModel} [basicUserAccountDetailsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  createAccount(basicUserAccountDetailsModel?: BasicUserAccountDetailsModel, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {UserGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  filterUsers(filter?: UserGridFilter, options?: any): AxiosPromise<UserCollectionModelGridCollection>;

  /**
   *
   * @param {string} [search] Search clause (optional).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  filterUsersForArchival(search?: string, options?: any): AxiosPromise<Array<UserCollectionModel>>;

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getAnalystRolePermissions(userId: number, options?: any): AxiosPromise<AnalystRolePermissionsModel>;

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getBasicAccountDetails(userId: number, options?: any): AxiosPromise<BasicUserAccountDetailsModel>;

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getManageUserFormLookupData(organisationId?: number, options?: any): AxiosPromise<UserAccountLookupModel>;

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getMedicalAdminRolePermissions(userId: number, options?: any): AxiosPromise<MedicalAdminRolePermissionsModel>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getReporterProfileLookupData(options?: any): AxiosPromise<ReporterProfileLookupModel>;

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getReporterRolePermissions(userId: number, options?: any): AxiosPromise<ReporterRolePermissionsModel>;

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getStudyAdminRolePermissions(userId: number, options?: any): AxiosPromise<StudyAdminRolePermissionsModel>;

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getUserAuditLog(userId: number, options?: any): AxiosPromise<Array<AuditLogEventModel>>;

  /**
   *
   * @param {number} userId
   * @param {number} roleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  removeUserFromRole(userId: number, roleId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  resetMfa(userId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {AnalystRolePermissionsModel} [analystRolePermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  saveAnalystRolePermissions(
    analystRolePermissionsModel?: AnalystRolePermissionsModel,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @param {MedicalAdminRolePermissionsModel} [medicalAdminRolePermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  saveMedicalAdminRolePermissions(
    medicalAdminRolePermissionsModel?: MedicalAdminRolePermissionsModel,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @param {ReporterRolePermissionsModel} [reporterRolePermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  saveReporterRolePermissions(
    reporterRolePermissionsModel?: ReporterRolePermissionsModel,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @param {StudyAdminRolePermissionsModel} [studyAdminRolePermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  saveStudyAdminRolePermissions(
    studyAdminRolePermissionsModel?: StudyAdminRolePermissionsModel,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @param {BasicUserAccountDetailsModel} [basicUserAccountDetailsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  updateAccount(basicUserAccountDetailsModel?: BasicUserAccountDetailsModel, options?: any): AxiosPromise<void>;
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
  /**
   *
   * @param {number} userId
   * @param {number} roleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public addUserToRole(userId: number, roleId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .addUserToRole(userId, roleId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BasicUserAccountDetailsModel} [basicUserAccountDetailsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createAccount(basicUserAccountDetailsModel?: BasicUserAccountDetailsModel, options?: any) {
    return UsersApiFp(this.configuration)
      .createAccount(basicUserAccountDetailsModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserGridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public filterUsers(filter?: UserGridFilter, options?: any) {
    return UsersApiFp(this.configuration)
      .filterUsers(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search] Search clause (optional).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public filterUsersForArchival(search?: string, options?: any) {
    return UsersApiFp(this.configuration)
      .filterUsersForArchival(search, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getAnalystRolePermissions(userId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .getAnalystRolePermissions(userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getBasicAccountDetails(userId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .getBasicAccountDetails(userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [organisationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getManageUserFormLookupData(organisationId?: number, options?: any) {
    return UsersApiFp(this.configuration)
      .getManageUserFormLookupData(organisationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getMedicalAdminRolePermissions(userId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .getMedicalAdminRolePermissions(userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getReporterProfileLookupData(options?: any) {
    return UsersApiFp(this.configuration)
      .getReporterProfileLookupData(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getReporterRolePermissions(userId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .getReporterRolePermissions(userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getStudyAdminRolePermissions(userId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .getStudyAdminRolePermissions(userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserAuditLog(userId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .getUserAuditLog(userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {number} roleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public removeUserFromRole(userId: number, roleId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .removeUserFromRole(userId, roleId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public resetMfa(userId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .resetMfa(userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AnalystRolePermissionsModel} [analystRolePermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public saveAnalystRolePermissions(analystRolePermissionsModel?: AnalystRolePermissionsModel, options?: any) {
    return UsersApiFp(this.configuration)
      .saveAnalystRolePermissions(analystRolePermissionsModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalAdminRolePermissionsModel} [medicalAdminRolePermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public saveMedicalAdminRolePermissions(
    medicalAdminRolePermissionsModel?: MedicalAdminRolePermissionsModel,
    options?: any,
  ) {
    return UsersApiFp(this.configuration)
      .saveMedicalAdminRolePermissions(medicalAdminRolePermissionsModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReporterRolePermissionsModel} [reporterRolePermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public saveReporterRolePermissions(reporterRolePermissionsModel?: ReporterRolePermissionsModel, options?: any) {
    return UsersApiFp(this.configuration)
      .saveReporterRolePermissions(reporterRolePermissionsModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StudyAdminRolePermissionsModel} [studyAdminRolePermissionsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public saveStudyAdminRolePermissions(studyAdminRolePermissionsModel?: StudyAdminRolePermissionsModel, options?: any) {
    return UsersApiFp(this.configuration)
      .saveStudyAdminRolePermissions(studyAdminRolePermissionsModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BasicUserAccountDetailsModel} [basicUserAccountDetailsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateAccount(basicUserAccountDetailsModel?: BasicUserAccountDetailsModel, options?: any) {
    return UsersApiFp(this.configuration)
      .updateAccount(basicUserAccountDetailsModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}
