














import { Component, Vue, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import { Validatable } from '@/core/models';

@Component
export default class ValidationSummaryCpt extends Vue {
  @Prop() model: Validatable;
  @Prop({ default: false }) displayAll: boolean;

  get anonymousErrors() {
    if (!this.model.modelState || _.isEmpty(this.model.modelState)) {
      return [];
    }

    return this.displayAll ? _.flatMap(this.model.modelState) : this.model.modelState[''];
  }
}
