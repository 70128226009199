









































import { Component, Prop } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { ReportLookupService } from '@/core/services';
import { GridModel } from './grid.models';
import GridSelectFilterCpt from './GridSelectFilterCpt.vue';

@Component({
  components: {
    GridSelectFilterCpt,
  },
})
export default class StudyOrgFilterCpt extends AppVue {
  @Prop({ default: {} }) lookups: any;
  @Prop({ default: {} }) grid: GridModel<any>;
  @Prop({ default: 0 }) studyTypeId: number;

  // For Non-Reporters

  get studies() {
    return ReportLookupService.filterStudies(this.lookups, this.studyTypeId);
  }

  get studyOrganisations() {
    return ReportLookupService.filterStudyOrgs(this.lookups, this.grid.filter.studyId, this.studyTypeId);
  }

  onStudyChangeInternal() {
    ReportLookupService.resolveOrgId(
      this.studyOrganisations,
      this.grid.filter as any,
      this.isAnalyst || this.isDevEnv,
      this.studyTypeId,
    );
  }

  // For Reporters

  get organisations() {
    return ReportLookupService.filterOrgs(this.lookups, this.studyTypeId);
  }

  get organisationStudies() {
    return ReportLookupService.filterOrgStudies(this.lookups, this.grid.filter.organisationId, this.studyTypeId);
  }

  async onOrganisationChangeInternal() {
    ReportLookupService.resolveStudyId(
      this.organisationStudies,
      this.grid.filter as any,
      this.isAnalyst || this.isDevEnv,
      this.studyTypeId,
    );
  }
}
