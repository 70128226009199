/* eslint-disable @typescript-eslint/no-inferrable-types */

import { AxiosPromise } from 'axios';

export interface GridParams<T> {
  /**
   * Filter method to be called on filter change to fetch new data.
   */
  filterMethod: (filter: GridFilter) => AxiosPromise<GridCollection<T>>;

  /**
   * Route name to transition to on filter change (optional).
   * If this is not specified, there will be no automatic url query params updates.
   */
  routeName?: string;

  /**
   * Initial grid filter (optional).
   * If nothing is passed, default queryParams will be used on the initial page load.
   */
  defaultFilterOverrides?: GridFilter;
}

/**
 * Grid filter model. This class is supposed to be used on its own for the most basic
 * grid filtering needs. Otherwise, if more grid filtering params are required, it can
 * be inherited from and extended with extra filtering fields.
 */
export class GridFilter {
  /**
   * Page to fetch.
   */
  page: number = 1;

  /**
   * Amount of items to fetch.
   */
  pageSize: number = 20;

  /**
   * Search clause.
   */
  search: string = '';

  /**
   * Sort by KEY (entity field name).
   */
  sortBy: string = '';

  /**
   * Sort direction (optional).
   * Allowed: "asc" / "desc".
   */
  sortOrder: 'asc' | 'desc' | '' = '';

  /**
   * Include soft deleted entities?
   */
  includeDeleted?: boolean = undefined as any;

  /**
   * Collection owner user Id.
   */
  ownerId?: number = undefined as any;

  /**
   * For any unknown properties that more specific filters might have.
   */
  [key: string]: any;

  constructor(data?: Partial<GridFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Grid collection model.
 */
export class GridCollection<T> {
  items?: T[] | null;
  currentPage?: number;
  pageSize?: number;
  pageCount?: number;
  totalItemCount?: number;
}

/**
 * Grid model.
 */
export interface GridModel<T> {
  filter: GridFilter;
  collection: GridCollection<T>;
  isLoading: boolean;
  search: (searchClause?: string) => void;
  clearSearch: (filter: GridFilter) => void;
  sort: (sortBy: string, sortOrder: 'asc' | 'desc' | '') => void;
  update: () => void;
  goToPage: (page: number) => void;
  prev: () => void;
  next: () => void;
  first: () => void;
  last: () => void;
  isFirstPage: () => boolean;
  isLastPage: () => boolean;
}

/* eslint-enable @typescript-eslint/no-inferrable-types */
