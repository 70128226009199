import _ from 'lodash';

import { OsicsLookupModel } from '@/core/webapi';

export const OsicsService = {
  getOsicsChildren(osics: OsicsLookupModel[], regionId: number, parentId?: number | null, specificId?: number) {
    return this.getOsicsChild(osics, regionId, parentId, specificId).children || [];
  },
  getOsicsChild(
    osics: OsicsLookupModel[],
    regionId: number,
    parentId?: number | null,
    specificId?: number | null,
    detailId?: number | null,
  ) {
    // Creates an array and removes all null/undefined from the array
    const childIds = _.compact([regionId, parentId, specificId, detailId]);

    return this.findOsicsChildRecursive(osics, childIds);
  },
  findOsicsChildRecursive(osics: OsicsLookupModel[], childIds: number[]): OsicsLookupModel {
    if (!osics || osics.length === 0 || _.isEmpty(childIds)) {
      return { children: [] as OsicsLookupModel[] } as OsicsLookupModel;
    }

    const child = this.findOsicsChild(osics, _.first(childIds) as number);

    return childIds.length === 1 ? child : this.findOsicsChildRecursive(child.children!, _.tail(childIds));
  },
  findOsicsChild(osics: OsicsLookupModel[], childId: number) {
    return _.find(osics, { id: childId }) || ({ children: [] as OsicsLookupModel[] } as OsicsLookupModel);
  },
  getOsicsText(
    osics: OsicsLookupModel[],
    regionId?: number | null,
    parentId?: number | null,
    specificId?: number | null,
    detailId?: number | null,
  ) {
    return !regionId ? '' : this.getOsicsChild(osics, regionId, parentId, specificId, detailId).text || '';
  },
  getOsicsCode(
    osics: OsicsLookupModel[],
    regionId?: number | null,
    parentId?: number | null,
    specificId?: number | null,
    detailId?: number | null,
  ) {
    return this.getOsicsCodeRecursive(osics, [regionId, parentId, specificId, detailId]);
  },
  getOsicsCodeRecursive(osics: OsicsLookupModel[], childIds: Array<number | null | undefined>): string {
    if (!osics) {
      return 'X ';
    }

    const child = this.findOsicsChild(osics, _.first(childIds) as number);
    const code = `${child.code || 'X'} `;

    return childIds.length === 1 ? code : code + this.getOsicsCodeRecursive(child.children!, _.tail(childIds));
  },
  getOsicsRegionPrefix(osics: OsicsLookupModel) {
    return this.getOsicsPrefix(osics);
  },
  getOsicsParentPrefix(osics: OsicsLookupModel, existingPrefix: string) {
    return this.getOsicsPrefix(osics, existingPrefix, 1);
  },
  getOsicsSpecificPrefix(osics: OsicsLookupModel, existingPrefix: string) {
    return this.getOsicsPrefix(osics, existingPrefix, 2);
  },
  getOsicsDetailPrefix(osics: OsicsLookupModel, existingPrefix: string) {
    return this.getOsicsPrefix(osics, existingPrefix, 3);
  },
  getOsicsPrefix(osics: OsicsLookupModel, existingPrefix?: string, level?: number) {
    const newPrefix = `${osics.code}: ${osics.text}`;

    if (!existingPrefix) {
      return newPrefix;
    }

    const existing = existingPrefix.replace(/ /g, '').substr(0, level);

    return `${existing}${newPrefix}`;
  },
};
