export const adminOrganisationsRoutes = [
  {
    path: '/admin/organisations',
    name: 'organisations',
    meta: { layout: 'default', title: 'route.organisations' },
    component: () => import(/* webpackChunkName: "admin" */ './OrganisationsGridView.vue'),
  },
  {
    path: '/admin/organisations/new',
    name: 'newOrganisation',
    meta: { layout: 'default', title: 'route.newOrganisation' },
    component: () => import(/* webpackChunkName: "admin" */ './OrganisationFormView.vue'),
  },
  {
    path: '/admin/organisations/:organisationId/edit',
    name: 'editOrganisation',
    meta: { layout: 'default', title: 'route.editOrganisation' },
    component: () => import(/* webpackChunkName: "admin" */ './OrganisationFormView.vue'),
  },
];
