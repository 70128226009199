







import moment from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DetailsDateTableRowCpt extends Vue {
  @Prop() label: string;
  @Prop() value: string;
  @Prop({ default: 'YYYY-MM-DD' }) format: string;

  get dateValue() {
    if (!this.value) {
      return '-';
    }

    return moment(this.value).format(this.format);
  }
}
