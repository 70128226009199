
import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { LOCALES } from '@/i18n';

@Component
export default class LocaleChangerBase extends AppVue {
  get langs() {
    return LOCALES;
  }

  get selectedLang() {
    const locale = this.langs.find(p => p.value === this.$i18n.locale);
    return locale ? locale.caption : (this.$t('lbl.selectLanguage') as string);
  }

  created() {
    if (localStorage && localStorage.defaultLanguage) {
      this.$i18n.locale = localStorage.defaultLanguage;
    } else {
      this.changeLang(this.$i18n.locale);
    }
  }

  changeLang(locale: string) {
    this.$i18n.locale = locale;
    if (localStorage) {
      localStorage.defaultLanguage = locale;
    }
  }
}
