










import { Component, Watch } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { IdleWarningCpt } from '@/core/components';
import { RedirectService } from '@/core/services';
import { VueUserContextModel } from '@/core/webapi';
import { store } from '@/core/store';

@Component({
  components: {
    IdleWarningCpt,
  },
})
export default class App extends AppVue {
  private isUserContextRefreshingInProgress = false;

  get layout() {
    return (this.$route?.meta?.layout ?? 'empty') + '-layout';
  }

  @Watch('$store.state.userContext')
  onUserContextChanged(val: VueUserContextModel, oldVal: VueUserContextModel) {
    this.isUserContextRefreshingInProgress = false;

    if (!this.isAuthenticated) {
      return;
    }

    const redirectTo = this.getOnContextUserChangedRedirectTo(val, oldVal);
    if (redirectTo) {
      this.$router.push(redirectTo);
    }
  }

  async created() {
    // eslint-disable-next-line no-console
    console.log(`${process.env.VUE_APP_ENVIRONMENT} - ${process.env.VUE_APP_VERSION}`);

    this.$eventHub.$on('USER_SOON_TO_BE_LOGGED_OUT', async () => {
      if (!this.isUserContextRefreshingInProgress) {
        this.isUserContextRefreshingInProgress = true;
        // Since WebAPI requests cause $msal to refesh the token before each request, this
        // will then force b2c token refresh before the userContext is refetched / refreshed.
        // Which is what we want when the user comes back from idling..
        await store.dispatch('setUserContext');
      }
    });
  }

  getOnContextUserChangedRedirectTo(val: VueUserContextModel, oldVal: VueUserContextModel) {
    if (this.$route.path.startsWith('/authenticate')) {
      // If not authenticated, force authentication
      if (!this.isAuthenticated) {
        return '/login';
      }

      // If authenticated and userContext is loaded, redirect to default route
      return !!this.userContext?.userId
        ? RedirectService.getDefaultAuthenticatedRoute(this.userContext, this.isAuthenticated)
        : null;
    }

    if (!this.didUserPermissionsChange(val, oldVal)) {
      return null;
    }

    const redirectTo = RedirectService.getDefaultAuthenticatedRoute(this.userContext, this.isAuthenticated);
    return this.$route.path !== redirectTo ? redirectTo : null;
  }

  didUserPermissionsChange(val: VueUserContextModel, oldVal: VueUserContextModel) {
    return (
      val?.userId !== oldVal?.userId ||
      val?.currentOrganisationId !== oldVal?.currentOrganisationId ||
      val?.contextRoleId !== oldVal?.contextRoleId
    );
  }
}
