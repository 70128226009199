




import Vue, { PropType } from 'vue';

import { GridModel } from './grid.models';

export default Vue.extend({
  name: 'GridTotalCountCpt',
  props: {
    grid: Object as PropType<GridModel<any>>,
  },
  data: () => ({
    showCount: false,
  }),
  watch: {
    grid: {
      handler(grid: GridModel<any>) {
        this.showCount = !!grid?.collection && !isNaN(Number(grid.collection.totalItemCount));
        (this as unknown as Vue).$forceUpdate();
      },
      deep: true,
      immediate: true,
    },
  },
});
